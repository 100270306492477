const contactoptions = [
  {
    key: "company/type/all",
    label: "sidebar.contacts",
    leftIcon: "ion-card",
    scope: "read:contacts"
  }
]

export default contactoptions;

import styled from "styled-components";

const AutocompleteSearchResultWrapper = styled.div`
	&:not(:empty){
		border: 1px solid lightgrey;
		position: absolute;
		z-index: 10;
		width:100%;
	}

	.suggestion-item {
		padding: 5px 10px;
		cursor: pointer;
		border-bottom: 1px solid lightgrey;

		&:hover {
			background-color: #fafafa;
		}
	}
	
`;

export default AutocompleteSearchResultWrapper;


import styled from "styled-components";

const FormWrapper = styled.div`
 .ant-calendar-picker {
  width: 100%;
  display: block;  
  }
`;

const ButtonWrappper = styled.div`
float:right;
  button:not(:first-child) {
    margin-left: 15px;
   }
   .save-and-create-new span{
   	color:#128239;
   }
`;

export {FormWrapper, ButtonWrappper};


const actions = {
  FETCH_BOOKING_SUMMARY_REQUEST: "FETCH_BOOKING_SUMMARY_REQUEST",
  FETCH_DRIVER_VEHICLE_SUMMARY_REQUEST: "FETCH_DRIVER_VEHICLE_SUMMARY_REQUEST",
  FETCH_PASSENGER_SUMMARY_REQUEST: "FETCH_PASSENGER_SUMMARY_REQUEST",
  FETCH_BOOKING_STATISTIC_REQUEST: "FETCH_BOOKING_STATISTIC_REQUEST",
  FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA',
  FETCH_MAINTENANCE_DATA:'FETCH_MAINTENANCE_DATA',
  FETCH_ALERTS_DATA:'FETCH_ALERTS_DATA',
  FETCH_DASHBOARD_EVENTS:'FETCH_DASHBOARD_EVENTS',
  
  fetchBookingSummary(resolve, reject) {
    return {
      type: actions.FETCH_BOOKING_SUMMARY_REQUEST,
      resolve,
      reject
    }
  },

  fetchDriverVehicleSummary(url, resolve, reject) {
    return {
      type: actions.FETCH_DRIVER_VEHICLE_SUMMARY_REQUEST,
      url,
      resolve,
      reject
    }
  },

  fetchPassangerSummary(resolve, reject) {
    return {
      type: actions.FETCH_PASSENGER_SUMMARY_REQUEST,
      resolve,
      reject
    }
  },
  fetchBookingStatistic(resolve, reject) {
    return {
      type: actions.FETCH_BOOKING_STATISTIC_REQUEST,
      resolve,
      reject
    }
  },

  fetchDashboardData(args, resolve, reject) {
    return {
      type: actions.FETCH_DASHBOARD_DATA,
      args,
      resolve,
      reject
    }
  },

  fetchMaintenanceData( resolve, reject) {
    return {
      type: actions.FETCH_MAINTENANCE_DATA,
      resolve,
      reject
    }
  },

  fetchAlertsData( resolve, reject) {
    return {
      type: actions.FETCH_ALERTS_DATA,
      resolve,
      reject
    }
  },

  fetchDashboardEvents(startDate, endDate, resolve, reject) {
    return {
      type: actions.FETCH_DASHBOARD_EVENTS,
      startDate,
      endDate,
      resolve,
      reject
    }
  },

}
export default actions;

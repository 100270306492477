import React, { Component } from 'react';
import { connect } from 'react-redux';
import userActions from 'Driver/redux/actions';
import { Spin, Alert } from 'antd';
import Select, { SelectOption } from 'Common/components/select';
import { Field, FormSection } from 'redux-form';

import SelectFieldWrapper from 'Common/styles/selectfield.style';

const { fetchDriverList } = userActions;

class DriverDropdown extends Component {

  componentWillMount() {
    if (!this.props.drivers.length && !this.props.loading) {
      this.props.fetchDriverList();
    }
  }

  getDropdownList = () => {
    // const { drivers, requiredParent, requiredParentValue } = this.props;
    const { drivers } = this.props;
    return drivers;
    // const activeDrivers = drivers.filter((driver)=>(
    //   driver.user.status==="active"
    // ))
    // if(requiredParent && requiredParentValue){
    //    return activeDrivers.filter(driver =>
    //     (typeof requiredParentValue.id.key === "number") ? driver.contact.id === requiredParentValue.id.key : driver)
    // }
    // else{
    //   return activeDrivers;
    // }
  }

  render() {

    const { defaultValue, requiredParent, requiredParentValue, input, meta: { touched, error }, type, loading, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    // const { onChange } = input
    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={this.props.loading}>
          <Select
            // onChange={onChange}
            {...input}
            {...custom}
            className="inputField ant-select-lg"
            value={input.value || defaultValue || ""}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

          >
            <SelectOption value="">Select Driver</SelectOption>
            {this.getDropdownList().map((dropdown, index) => (
              <SelectOption
                key={index}
                value={dropdown.id}>
                {dropdown.name}
              </SelectOption>
            ))}
          </Select>
          {hasError &&
            <Alert
              type="error"
              message={error}
              banner />
          }
        </Spin>
      </SelectFieldWrapper>
    )
  }
}
const DriverDropdownConnect = connect(
  state => ({
    drivers: state.Driver.drivers,
    loading: state.Driver.loading
  }),
  { fetchDriverList }
)(DriverDropdown);

export default DriverDropdownConnect;

export const DriverDropdownSelectWithFormSection = ({ name, type, ...custom }) => (
  <FormSection name={name}>
    <Field name="id" type={type} component={DriverDropdownConnect} {...custom} />
  </FormSection>
);

import actions from "./actions";

const initState = {
  driver: null,
  error: null,
  drivers: [],
  loading: false
};

export default function driverReducer(state = initState, action) {
  switch (action.type) {
    case action.FETCH_DRIVER_SIGNUP_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.FETCH_DRIVER_SIGNUP_LIST_SUCCESS:
      return {
        ...state,
        drivers: action.response,
        loading: false
      };
    case action.FETCH_DRIVER_SIGNUP_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}

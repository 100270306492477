import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from 'Public/redux/actions';
import appActions from 'App/redux/actions';
import Sidebar from 'App/components/Sidebar';
// import Topbar from 'App/components/Topbar';
import AppRouter from 'App/settings/AppRouter';
import { siteConfig } from 'App/settings';
import { AppLocale } from 'DashApp';
import themes from 'App/settings/themes';
import AppHolder from 'App/styles/commonstyle.style';
import 'App/styles/global.css';
import { Icon } from 'antd';
import { trim } from 'lodash-es';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableBanner: this.getBannerCookie()
    };
  }


 setBannerCookie=()=>{
        document.cookie="disableBanner=true";
        this.setState({
          disableBanner:true
        });
    }

    getBannerCookie=()=>{
      let x = document.cookie;
      let splitCookie=x.split(";");
      let cookieValColl={};
       splitCookie.forEach((item, index, arr)=>{
          let currentValue=trim(item);
          let splitCurrentValue=currentValue.split("=");       
          cookieValColl[splitCurrentValue[0]]= splitCurrentValue[1];
      });
      return (cookieValColl.disableBanner)?cookieValColl.disableBanner:false;
    }

  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme, height } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    const {disableBanner} = this.state;
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                {!disableBanner && (
                    <div style={{backgroundColor:"#3d1456",padding:15,textAlign:"center", color:"#ffffff",fontSize:15}}>
                    We're here for you! if you require support, contact <a href="mailto:support@managevehicle.com" style={{textDecoration:"underline",color:"#fff"}}>support@managevehicle.com</a> or 
                    call on <a href="tel:+61 444 555 933" style={{textDecoration:"underline",color:"#fff"}}>+61 444 555 933</a>
                    <Icon type="close" style={{float:"right",cursor:"pointer",fontSize:16}} onClick={this.setBannerCookie}/>
                    </div>
                )}
                
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: '0',
                        flexShrink: '0',
                        background: '#ffffff',
                        position: 'relative'
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed'
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    locale: "en",
    selectedTheme: "themedefault",
    height: state.App.height
  }),
  { logout, toggleAll }
)(App);

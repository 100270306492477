import React, { Component } from "react";
import { connect } from "react-redux";
import {
  reduxForm,
  SubmissionError,
  formValueSelector,
  change,
  Field,
} from "redux-form";
import { Row, Col, message, Alert, Spin, Form } from "antd";
import moment from "moment";
import TextAreaField from "Common/components/textAreaField";
import Button from "Common/components/button";
import IntlMessages from "Common/components/intlMessages";
import { ButtonWrappper } from "Common/components/Form/styles/formWrapper.style";
import FormWrapper from "Common/styles/FormWrapper/formwrapper.style";
import BasicInformation from "Booking/components/BasicInformation";
import Operations from "Booking/components/Operations";
import validate from "Booking/settings/validate";
import { BookingCreateWrapper } from "Booking/styles/booking.style";
import BookingActions from "Booking/redux/actions";
import allocationsActions from "Allocation/redux/actions";
import AssetsActions from "Assets/redux/actions";
import ModalWrapper from "Common/components/modalWrapper";
const { createBooking, updateBooking } = BookingActions;
const { createAllocation, updateAllocation } = allocationsActions;
const { createAssets, updateAssets, deleteAssets } = AssetsActions;

const FORM_NAME = "createBooking";
const FormItem = Form.Item;
const selector = formValueSelector(FORM_NAME);

export const localizeDate = (fromUtc) =>
  (fromUtc && moment.utc(fromUtc).local()) || null;

export const normalizeDropdownOptionRun = (value) => ({
  id: { key: value.id, label: value.runNumber },
});

export const normalizeDropdownOption = (value) => ({
  id: { key: value.id, label: value.name },
});

class BookingCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isUpdateBooking: true,
      // isToggled: false,
      editComment: false,
    };
  }

  componentWillMount = () => {
    if (!this.props.editingMode) {
      this.props.initialize({
        images: [],
        status: "draft",
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.entity && nextProps.entity != this.props.entity) {
      let entity = nextProps.entity;
      delete entity["id"];
      delete entity["allocations"];

      this.props.initialize({
        ...entity,
        clientContact:
          entity.clientContact && normalizeDropdownOption(entity.clientContact),
        operatorContact:
          entity.operatorContact &&
          normalizeDropdownOption(entity.operatorContact),
        assetType: entity.assets[0]?.assetType,
        assetRego: entity.assets[0]?.assetId,
        loadType: entity.assets[0]?.loadType,
        temperature: entity.assets[0]?.temperature,
        departureDate: localizeDate(entity.departureDate),
        arrivalDate: localizeDate(entity.arrivalDate),
      });
    }
    if (nextProps.entity && nextProps.entity != this.props.entity) {
      let status = nextProps.entity.status;
      if (["commenced", "scheduled"].includes(status)) {
        this.setState({ isUpdateBooking: false });
        message.error("Scheduled,Commenced or Completed booking can't be edit");
      }
    }
  };

  normalizeDataBeforeSubmit = (data) => {
    const sendData = {
      ...data,
    };
    return sendData;
  };

  onSaveClick = (data) => {
    return this.handleCreate(data, "save");
  };

  onSaveandCreateClick = (data) => {
    return this.handleCreate(data, "publish");
  };

  onSaveandClone = (data) => {
    return this.handleCreate(data, "saveandclone");
  };

  handleCreate = async (data, context) => {
    try {
      const self = this;
      const { driver, vehicle } = data;

      if (context === "publish" && (!driver || !vehicle)) {
        message.error("Both vehicle and driver are required to publish job.");
        return;
      }
      if (context === "publish") {
        data.status = "published";
      }
      if (context === "save") {
        data.status = "draft";
      }
      this.setState({ loading: true });

      const response = await new Promise((resolve, reject) => {
        this.props.createBooking(
          self.normalizeDataBeforeSubmit(data),
          context,
          resolve,
          reject
        );
      });

      this.setState({ loading: false });
      message.success("Job created successfully");
      this.props.history.push("/jobs/" + response.data.id);
      return response;
    } catch (error) {
      this.setState({ loading: false });
      throw new SubmissionError(error);
    }
  };

  updateJob = (data) => {
    data.status === "completed"
      ? this.setState({ editComment: true })
      : this.handleUpdate(data);
  };

  handleEditCommentModal = () => {
    this.setState({ editComment: false });
  };

  handleUpdate = async (data) => {
    try {
      const { driver, vehicle, status } = data;
      if (status === "published" && (!driver || !vehicle)) {
        message.error(
          "Please select driver and vehicle to publish this job to driver."
        );
        return;
      }

      // Check if only one of driver or vehicle is present
      if ((driver && !vehicle) || (!driver && vehicle)) {
        message.error("Both vehicle and driver are required for allocation.");
        return;
      }
      this.setState({ loading: true });
      const response = await this.updateJobData(data);
      this.setState({ loading: false });
      message.success("Job updated successfully");
      this.props.history.push(`/jobs/${response.data.id}`);

      return response;
    } catch (error) {
      this.setState({ loading: false });
      throw new SubmissionError(error);
    }
  };

  // Helper function to update job data
  updateJobData = async (data) => {
    return new Promise((resolve, reject) => {
      this.props.updateBooking(
        this.props.entity_id,
        this.normalizeDataBeforeSubmit(data),
        resolve,
        reject
      );
    });
  };

  setClientContactDefaultValue = (value) => {
    this.props.dispatch(
      change(FORM_NAME, "clientContact", { id: { key: value } })
    );
  };

  setOperatorContactDefaultValue = (value) => {
    this.props.dispatch(
      change(FORM_NAME, "operatorContact", { id: { key: value } })
    );
  };

  handleImageChange(data) {
    this.props.dispatch(change(FORM_NAME, "images", data));
  }

  render() {
    const { handleSubmit, editingMode, error } = this.props;
    const { isUpdateBooking, loading, editComment } = this.state;

    return (
      <Spin spinning={loading}>
        <BookingCreateWrapper>
          <FormWrapper>
            <BasicInformation
              onChange={(key, value) =>
                this.props.dispatch(change(FORM_NAME, key, value))
              }
              {...this.props}
              workSheetImage={this.props.images}
              handleImageChange={this.handleImageChange.bind(this)}
              setClientContactDefaultValue={this.setClientContactDefaultValue}
              entity={this.props.entity}
              editingMode={editingMode}
            />

            <Operations
              setOperatorContactDefaultValue={
                this.setOperatorContactDefaultValue
              }
              isUpdate={isUpdateBooking}
              entity={this.props.entity}
            />

            <Row gutter={48}>
              <Col className="gutter-row" span={10}>
                {error && (
                  <Alert
                    className="alert"
                    type="error"
                    message={error}
                    banner
                  />
                )}
              </Col>
            </Row>
            {editComment && (
              <ModalWrapper
                visible
                onCancel={this.handleEditCommentModal}
                title={
                  "You just edited a completed job, do you like to add any comments?"
                }
                children={
                  <>
                    <FormItem>
                      <label>
                        <IntlMessages id="forms.label.jobComment" />
                      </label>
                      <Field
                        name="jobEditComment"
                        component={TextAreaField}
                        style={{ marginTop: 10 }}
                      />
                    </FormItem>
                    <FormItem>
                      <Button
                        type="primary"
                        onClick={handleSubmit(this.handleUpdate.bind(this))}
                        loading={this.state.loading}
                      >
                        <IntlMessages id="forms.button.update" />
                      </Button>
                    </FormItem>
                  </>
                }
              ></ModalWrapper>
            )}

            <Row gutter={48}>
              <Col lg={24} md={24}>
                <ButtonWrappper>
                  {editingMode ? (
                    isUpdateBooking && (
                      <Button
                        type="primary"
                        onClick={handleSubmit(this.updateJob.bind(this))}
                        loading={this.state.loading}
                      >
                        <IntlMessages id="forms.button.update" />
                      </Button>
                    )
                  ) : (
                    <div>
                      <Button
                        style={{ color: "#ED8A00", borderColor: "#ED8A00" }}
                        loading={this.state.loading}
                        onClick={handleSubmit(this.onSaveClick.bind(this))}
                      >
                        <IntlMessages id="forms.button.saveDraft" />
                      </Button>
                      <Button
                        className="active primary"
                        loading={this.state.loading}
                        onClick={handleSubmit(
                          this.onSaveandCreateClick.bind(this)
                        )}
                      >
                        <IntlMessages id="forms.button.publish" />
                      </Button>
                    </div>
                  )}
                </ButtonWrappper>
              </Col>
            </Row>
          </FormWrapper>
        </BookingCreateWrapper>
      </Spin>
    );
  }
}
const BookingCreate = reduxForm({
  form: FORM_NAME,
  validate,
})(BookingCreateForm);

export default connect(
  (state) => ({
    clientContact: selector(state, "clientContact"),
    departureDate: selector(state, "departureDate"),
    arrivalDate: selector(state, "arrivalDate"),
    arrivalLocation: selector(state, "arrivalLocation"),
    departureLocation: selector(state, "departureLocation"),
    images: selector(state, "images"),
  }),
  {
    createBooking,
    updateBooking,
    createAllocation,
    updateAllocation,
    createAssets,
    updateAssets,
    deleteAssets,
  }
)(BookingCreate);

import React from "react";
import { Route } from "react-router-dom";
import Auth0 from "Common/helpers/auth0-custom";
import AccessDenied from 'App/settings/AccessDenied';

const auth0 = new Auth0();

export default ({component: Component, scope, ...rest}) => {
 return( <Route {...rest} render= { props => {
   return (
     (!scope || auth0.userHasScope(scope)) ? (
       <Component {...props}/>
     ) : (
       <AccessDenied />
     )
   )
 }}/>
)};

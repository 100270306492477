import actions from 'Public/redux/actions';
import Auth0 from 'Common/helpers/auth0-custom';
const auth0 = new Auth0();

export const excludedUser = ["driver", "passenger"];

export const isUserExcluded = (role, excludedUser) => {
    return excludedUser.includes(role.name);
}

const initState = {
  profile: null,
  error: null,
  roles: [],
  roles_read: [],
  roles_write: []
 };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return { ...state, profile: action.profile };

    case actions.LOGIN_ERROR:
      return {...state,error: action.error};

    case actions.FETCH_ROLE_SUCCESS:
      return {
        ...state,
        roles_read: action.roles.filter(role=>{(auth0.userHasScope("read:users:"+role.name) && !isUserExcluded(role,excludedUser))}),
        roles_write: action.roles.filter(role=>auth0.userHasScope("write:users:"+role.name) && !isUserExcluded(role,excludedUser)),
        roles: action.roles
      };

    default:
      return state;
  }
}

import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'NCR/redux/actions';
import {push} from 'react-router-redux';
import  Api  from 'Common/api/Api2';


const api = new Api();

export function* retrieveNcr() {
    yield takeEvery(actions.RETRIEVE_NCR_REQUEST, function*({id, resolve, reject}) {
        try{
            const response = yield call(api.get,'ncrs/' + id, {});
            yield call(resolve,response);
        } catch(error) {
            yield call(reject,error);
        }
    });
}

export function* deleteNcr() {
    yield takeEvery(actions.DELETE_NCR_REQUEST, function*({id, resolve, reject}) {
        try{
            const response = yield call(api.delete,'ncrs/'+ id, {})
            if(resolve){
                yield call(resolve,response);
                yield put(push('/ncr'));
            }
        } catch(error) {
            yield call(reject,error);
        }
    });
}


export function* createNcr() {
    yield takeEvery(actions.CREATE_NCR_REQUEST, function*({data,context,resolve,reject}) {
        try{
            const response = yield call(api.post,'ncrs',data);
            yield call(resolve,response);
        }
        catch(error) {
            yield call(reject, error);
        }
    });
}

export function* updateNcr(){
    yield takeEvery(actions.UPDATE_NCR_REQUEST,function*({data, id, resolve,reject}){
        try{
            const response = yield call(api.update,'ncrs/'+id, data);
            yield call(resolve,response);
        }catch(error){
            yield call(reject,error);
        }
    });
}

export function* createCar() {
    yield takeEvery(actions.CREATE_CAR_REQUEST, function*({data,context,resolve,reject}) {
        try{
            const response = yield call(api.post,'ncrs/correctiveActionReport',data);
            yield call(resolve,response);
        }
        catch(error) {
            yield call(reject, error);
        }
    });
}

export function* updateCar(){
    yield takeEvery(actions.UPDATE_CAR_REQUEST,function*({data, id, resolve,reject}){
        try{
            const response = yield call(api.update,'ncrs/correctiveActionReport/'+id, data);
            yield call(resolve,response);
        }catch(error){
            yield call(reject,error);
        }
    });
}

export function* createPar() {
    yield takeEvery(actions.CREATE_PAR_REQUEST, function*({data,id,resolve,reject}) {
        try{
            const response = yield call(api.post,"ncrs/"+id+"/actionToPrevent",data);
            yield call(resolve,response);
        }
        catch(error) {
            yield call(reject, error);
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(retrieveNcr),
        fork(deleteNcr),
        fork(createNcr),
        fork(updateNcr),
        fork(createCar),
        fork(updateCar),
        fork(createPar),
    ]);
}
import styled from "styled-components";

const UploadImageWrapper = styled.div`
  background-color: #e8f3ec;
  border: 1px dashed #128239;
  color: #128239;
  padding: 60px 20px 0;
  vertical-align: middle;
  text-align: center;
  label {
    float: left;
    width: 100%;
    text-align: left;
  }
  .ant-upload.ant-upload-select-picture-card {
    .ant-upload {
      background-color: #e8f3ec;
    }
  }

`;

export default UploadImageWrapper;

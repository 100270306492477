const actions = {
  CREATE_DRIVER_SIGNUP_REQUEST: "CREATE_DRIVER_SIGNUP_REQUEST",
  RETRIEVE_DRIVER_SIGNUP_REQUEST: "RETRIEVE_DRIVER_SIGNUP_REQUEST",
  UPDATE_DRIVER_SIGNUP_REQUEST: "UPDATE_DRIVER_SIGNUP_REQUEST",
  DELETE_DRIVER_SIGNUP_REQUEST: "DELETE_DRIVER_SIGNUP_REQUEST",

  FETCH_DRIVER_SIGNUP_LIST_REQUEST: "FETCH_DRIVER_SIGNUP_LIST_REQUEST",
  FETCH_DRIVER_SIGNUP_LIST_SUCCESS: "FETCH_DRIVER_SIGNUP_LIST_SUCCESS",
  FETCH_DRIVER_SIGNUP_LIST_FAIL: "FETCH_DRIVER_SIGNUP_LIST_FAIL",
  FETCH_ORGANIZATION_REQUEST: "FETCH_ORGANIZATION_REQUEST",

  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAIL: "UPLOAD_IMAGE_FAIL",

  DELETE_IMAGE_REQUEST: "DELETE_IMAGE_REQUEST",
  DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
  DELETE_IMAGE_FAIL: "DELETE_IMAGE_FAIL",
  FETCH_DRIVER_SIGNUP_WORK: "FETCH_DRIVER_SIGNUP_WORK",

  createDriver: (data, context, resolve, reject) => ({
    type: actions.CREATE_DRIVER_SIGNUP_REQUEST,
    data,
    context,
    resolve,
    reject
  }),
  retrieveDriver: (id, resolve, reject) => ({
    type: actions.RETRIEVE_DRIVER_SIGNUP_REQUEST,
    id,
    resolve,
    reject
  }),
  fetchDriverList: (resolve, reject) => ({
    type: actions.FETCH_DRIVER_SIGNUP_LIST_REQUEST,
    resolve,
    reject
  }),
  updateDriver: (id, data, resolve, reject) => ({
    type: actions.UPDATE_DRIVER_SIGNUP_REQUEST,
    id,
    data,
    resolve,
    reject
  }),
  deleteDriver: (id, resolve, reject) => ({
    type: actions.DELETE_DRIVER_SIGNUP_REQUEST,
    id,
    resolve,
    reject
  }),
  fetchOrganisation: (resolve, reject) => ({
    type: actions.FETCH_ORGANIZATION_REQUEST,
    resolve,
    reject
  }),
  uploadImage(formData, resolve, reject) {
    return {
      type: actions.UPLOAD_IMAGE_REQUEST,
      formData,
      resolve,
      reject
    };
  },

  deleteImage(id, resolve, reject) {
    return {
      type: actions.DELETE_IMAGE_REQUEST,
      id,
      resolve,
      reject
    };
  },

  driverWorkHour: (id, startDateTime, period, resolve, reject) => ({
    type: actions.FETCH_DRIVER_SIGNUP_WORK,
    id,
    startDateTime,
    period,
    resolve,
    reject
  })
};
export default actions;

import React from "react";
import { connect } from "react-redux";
import { Upload, Modal, message, Spin } from "antd";
import actions from "Driver/redux/actions";
import image from "Driver/styles/images/upload-image.png";

const { uploadImage, deleteImage } = actions;

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      loading: false,
      fileExtension: "",
      fileName: "",
    };
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
      fileExtension: /[^.]+$/.exec(file.url || file.thumbUrl)[0],
      fileName: file.name,
    });
  };

  uploadImage = ({ file, onSuccess }) => {
    const { entityType, field } = this.props;
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("file", file);
    formData.append("entityType", entityType);
    formData.append("field", field);
    return new Promise((resolve, reject) => {
      this.props.uploadImage(formData, resolve, reject);
    })
      .then((response) => {
        const { index, field } = this.props;
        this.setState({ loading: false });
        onSuccess(response, file);
        message.success("Image upload successful");
        this.props.onChange(this.props.data.concat(response.data), index, field);
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Upload image failed");
      }, this);
  };

  onRemove = (file) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteImage(file.uid, resolve, reject);
    })
      .then((response) => {
        const { index, field } = this.props;
        this.setState({ loading: false });
        message.success("Delete image successful.");
        const imageToBeDeleteIndex = this.props.data.filter((image) => image.id !== file.uid);
        this.props.onChange(imageToBeDeleteIndex, index, field);
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Delete image failed.");
      }, this);
  };

  imageFileUploadLimit = () => {
    const { imageUploadLimit } = this.props;
    if (imageUploadLimit) {
      return imageUploadLimit;
    }
    return 5;
  };

  render() {
    const { previewVisible, previewImage, fileExtension, fileName } = this.state;
    const { displayName } = this.props;
    const fileList =
      (this.props.data &&
        this.props.data.map((image) => ({
          uid: image.id,
          name: image.fileName,
          status: "done",
          url: process.env.REACT_APP_MEDIA_BASE_URL + image.diskName,
        }))) ||
      [];
    const uploadButton = (
      <div>
        <img src={image} style={{ backgroundColor: "#bfdecb", padding: "10px", borderRadius: "10%",height:"50px", width:"50px"}} />
        <span className="ant-upload-text">{displayName ? displayName : "Upload Image"}</span>
      </div>
    );

    return (
      <div className="clearfix">
        <Spin spinning={this.state.loading}>
          <Upload
            accept=".doc,.docx,.pdf,image/*"
            customRequest={this.uploadImage}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            onRemove={this.onRemove}
            style={{ backgroundColor: "#e8f3ec" }}
          >
            {fileList.length >= this.imageFileUploadLimit() ? null : uploadButton}
          </Upload>
        </Spin>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          {fileExtension == "png" || fileExtension == "jpeg" || fileExtension == "jpg" ? (
            <img style={{ width: "100%" }} src={previewImage} />
          ) : (
            <a href={previewImage} target="_blank">
              {fileName}
            </a>
          )}
        </Modal>
      </div>
    );
  }
}
export default connect(null, { uploadImage, deleteImage })(ImageUploader);

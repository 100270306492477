import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import actions from 'Route/redux/actions';
import Api from 'Common/api/Api';

const api = new Api();

export function* createRoute() {
  yield takeEvery(actions.CREATE_ROUTE_REQUEST, function*({data,context,resolve,reject}) {
    try{
      const response = yield call(api.post,'routes',data);
      yield call(resolve,response);
      if(context === 'save'){
      yield(put(push('/routes/'+response.data.id)));
      }
     }
     catch(error) {
      yield call(reject, error);
    }
  });
}
export function* fetchRouteList() {
  yield takeEvery(actions.FETCH_ROUTE_LIST_REQUEST, function*({ resolve, reject}) {
    try{
      const response = yield call(api.get,'routes?pageSize=-1', {});
      if(resolve){
        yield call(resolve,response);
      } else {
        yield put({
          type: actions.FETCH_ROUTE_LIST_SUCCESS,
          response: response.data.results
        });
      }
    } catch(error) {
      if(reject){
        yield call(reject,error);
      } else {
        yield put({
          type: actions.FETCH_ROUTE_LIST_FAIL,
          error
        });
      }
    }
  });
}

export function* updateRoute() {
  yield takeEvery(actions.UPDATE_ROUTE_REQUEST, function*({data,id,resolve,reject}) {
    try{
      const response = yield call(api.update,'routes/' + id, data);
      yield call(resolve,response);
      yield put(push('/routes/' + response.data.id));
    } catch(error) {
      yield call(reject, error);
    }
  });
}

export function* deleteRouteDetail() {
  yield takeEvery(actions.DELETE_ROUTE_DETAIL_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.delete,'routes/'+ id, {})
      if(resolve){
        yield call(resolve,response);
        yield put(push('/routes'));
      }
    } catch(error) {
      if(reject){
        yield call(reject,error);
      }
    }
  });
}

export function* fetchRouteDetail(){
  yield takeEvery(actions.FETCH_ROUTE_DETAIL_REQUEST, function*({id, resolve, reject}){
    try{
      const response = yield call(api.get, 'routes/'+ id, {})
    if(resolve){
      yield call(resolve, response);
    }
    }catch(error){
      if(reject){
        yield call(reject, error);
      }
    }
  });
}

export default function* rootSaga(){
  yield all([
    fork(createRoute),
    fork(fetchRouteList),
    fork(deleteRouteDetail),
    fork(fetchRouteDetail),
    fork(updateRoute)
  ]);
}

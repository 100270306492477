const actions = {
    FETCH_FUEL_LIST_REQUEST:'FETCH_FUEL_LIST_REQUEST',
    FETCH_FUEL_LIST_SUCCESS:"FETCH_FUEL_LIST_SUCCESS",
    FETCH_FUEL_LIST_FAIL:"FETCH_FUEL_LIST_FAIL",


    fetchFueltList:(resolve, reject) => ({
        type:actions.FETCH_FUEL_LIST_REQUEST,
        resolve,
        reject
    }),

};
export default actions;

import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'Run/redux/actions';
import { push } from 'react-router-redux';
import  Api  from 'Common/api/Api';

const api = new Api();

export function* createRun() {
  yield takeEvery(actions.CREATE_RUNS_REQUEST, function*({data,context,resolve,reject}) {
    try{
      const response = yield call(api.post,'runs',data);
      yield call(resolve,response);
      if(context === 'save'){
        yield(put(push('/runs/'+response.data.id)));
      }
     } 
     catch(error) {
      yield call(reject, error);     
    }
  });
}

export function* retrieveRun() {
  yield takeEvery(actions.RETRIEVE_RUNS_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'runs/' + id, {});
        yield call(resolve,response);
    } 
    catch(error) {
      yield call(reject,error);
    }
  });
}

export function* updateRun() {
  yield takeEvery(actions.UPDATE_RUNS_REQUEST, function*({data,id,resolve,reject}) {
    try{
      const response = yield call(api.update,'runs/' + id, data);
      yield call(resolve,response);
      yield put(push('/runs/' + response.data.id));
    } 
    catch(error) {
      yield call(reject, error);
    }
  });
 }

export function* deleteRun(){
  yield takeEvery(actions.DELETE_RUNS_REQUEST, function*({id,resolve,reject}){
    try{
      const response = yield call(api.delete,'runs/'+ id, {})
      yield call(resolve,response);
      yield put(push('/runs'));
    }catch(error){
      yield call(reject,error);
    }
  });
}
export function* listRun() {
  yield takeEvery(actions.LIST_RUNS_REQUEST, function*() {
    try{
      const response = yield call(api.get,'runs?pageSize=-1', {});
      yield put({
        type: actions.LIST_RUNS_SUCCESS,
        response: response.data.results
      });
      return response;
    } catch(error) {
      yield put({
        type: actions.LIST_RUNS_FAILURE,
        error: error
      });
      return error;
    }
  });
}

export function* listRunLocation() {
  yield takeEvery(actions.LIST_RUNS_LOCATION_REQUEST, function*() {
    try{
      const response = yield call(api.get,'locations?pageSize=-1', {});
      yield put({
        type: actions.LIST_RUNS_LOCATION_SUCCESS,
        response: response.data.results
      });
      return response;
    } catch(error) {
      yield put({
        type: actions.LIST_RUNS_LOCATION_FAILED,
        error: error
      });
      return error;
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createRun),
    fork(retrieveRun),
    fork(updateRun),
    fork(deleteRun),
    fork(listRun),
    fork(listRunLocation)
  ]);
}

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  FETCH_ROLE_REQUEST: 'FETCH_ROLE_REQUEST',
  VALIDATE_EMAIL:'VALIDATE_EMAIL',
  SET_FORGOT_PASSWORD:'SET_FORGOT_PASSWORD',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: ({username,password,resolve,reject}) => ({
    type: actions.LOGIN_REQUEST,
    username,
    password,
    resolve,
    reject
  }),
  fetchRole: () => ({
    type: actions.FETCH_ROLE_REQUEST
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  validateEmail: (email, resolve, reject) => ({
    type:actions.VALIDATE_EMAIL,
    email,
    resolve,
    reject,
  }),
  setForgotPassword:(data, resolve, reject) =>({
    type:actions.SET_FORGOT_PASSWORD,
    data,
    resolve,
    reject
  })
};
export default actions;

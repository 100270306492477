import React from 'react';
import {Tag} from 'antd';

const ucfirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const tagColors = {
  usertype : {
    default : "#A0A0A0"
  },
}

const ItemTag = ({type, value}) => (
  <Tag style={{minWidth:'100px',textAlign:'center'}} color={tagColors[type][value] || tagColors[type].default}>{ucfirst(value)}</Tag>
);

export default ItemTag;
import { all, takeEvery, fork, call, put } from "redux-saga/effects";

import actions from "Fatigue/redux/actions";
import Api from "Common/api/Api2";

const api = new Api();

// export function* retrieveDriverFatigue() {
//   yield takeEvery(actions.RETRIEVE_DRIVER_FATIGUE_REQUEST, function* ({ id, resolve, reject }) {
//     try {
//       const response = yield call(api.get, "fatigue/" + id, {});
//       yield call(resolve, response);
//     } catch (error) {
//       yield call(reject, error);
//     }
//   });
// }

// export function* retrieveDriverFatigueV1() {
//   yield takeEvery(actions.RETRIEVE_DRIVER_FATIGUE_REQUESTV1, function* ({ id, startDateTime, resolve, reject }) {
//     let url = "v1/fatigue/daily?filters[date][start]=" + startDateTime + "&filters[days]=30";
//     if (id) {
//       url = "v1/fatigue/daily?filters[date][start]=" + startDateTime + "&filters[days]=30" + "&filters[driver][id]=" + id;
//     }
//     try {
//       const response = yield call(api.get, url, {});
//       yield call(resolve, response);
//     } catch (error) {
//       yield call(reject, error);
//     }
//   });
// }

// export function* retrieveJobActivities() {
//   yield takeEvery(actions.RETRIEVE_JOB_ACTIVITIES, function* ({ driverId, startDateTime, endDateTime, resolve, reject }) {
//     try {
//       const response = yield call(
//         api.get,
//         `jobactivities?filters[driver][id]=${driverId}&period[start]=${startDateTime}&period[end]=${endDateTime}&pageSize=200`,
//         {}
//       );
//       yield call(resolve, response);
//     } catch (error) {
//       yield call(reject, error);
//     }
//   });
// }

// export function* retrieveDriverDetail() {
//   yield takeEvery(actions.RETRIEVE_DRIVER_DETAIL, function* ({ driverId, resolve, reject }) {
//     try {
//       const response = yield call(api.get, `drivers/${driverId}`, {});
//       yield call(resolve, response);
//     } catch (error) {
//       yield call(reject, error);
//     }
//   });
// }

export function* fetchFatigueSaga() {
  yield takeEvery(actions.FETCH_FATIGUE_REQUEST, function* ({ resolve, reject, driverId }) {
    try {
      const response = yield call(api.get, `ewd/fatigue${driverId ? "?driverId=" + driverId : ""}`, {});
      if (resolve) yield put(actions.fetchFatigue(response?.data));
    } catch (err) {
      if (reject) yield put(actions.fetchFatigue([]));
    }
  });
}

export default function* rootSaga() {
  yield all([
    // fork(retrieveDriverFatigue),
    // fork(retrieveDriverFatigueV1),
    // fork(retrieveJobActivities),
    // fork(retrieveDriverDetail),
    fork(fetchFatigueSaga),
  ]);
}

export const FETCH_DRIVERS_REQUEST = "FETCH_DRIVERS_REQUEST";
export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const FETCH_ANNOTATION_REQUEST = "FETCH_ANNOTATION_REQUEST";
export const FETCH_ANNOTATION = "FETCH_ANNOTATION";
export const FETCH_EWD_REQUEST = "FETCH_EWD_REQUEST";
export const FETCH_EWD = "FETCH_EWD";
export const FETCH_EWD_PDF = "FETCH_EWD_PDF";

export const fetchDriversRequest = (resolve, reject) => ({
  type: FETCH_DRIVERS_REQUEST,
  resolve,
  reject,
});
export const fetchDrivers = (data) => ({
  type: FETCH_DRIVERS,
  payload: data,
});

export const fetchAnnotationRequest = (resolve, reject, driverId) => ({
  type: FETCH_ANNOTATION_REQUEST,
  resolve,
  reject,
  driverId,
});
export const fetchAnnotation = (data) => ({
  type: FETCH_ANNOTATION,
  payload: data,
});

export const fetchEWDRequest = (resolve, reject, driverId, startDate, endDate) => ({
  type: FETCH_EWD_REQUEST,
  resolve,
  reject,
  driverId,
  startDate,
  endDate,
});
export const fetchEWD = (data) => ({
  type: FETCH_EWD,
  payload: data,
});

export const fetchEwdPDF = (resolve, reject, driverId, startDate, endDate) => ({
  type: FETCH_EWD_PDF,
  resolve,
  reject,
  driverId,
  startDate,
  endDate,
});

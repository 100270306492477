import actions from "./actions";

const initialState = {
  loading: false,
  fatigue: [],
};

const ewdReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_FATIGUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_FATIGUE:
      return {
        ...state,
        loading: false,
        fatigue: action.payload,
      };
    default:
      return state;
  }
};

export default ewdReducer;

import asyncComponent from "Common/helpers/AsyncFunc";
import ListContact from "../containers/ListContact";

const contactroutes = [
  {
    path:'company/create',
    component: asyncComponent (() => import ('Contact/containers/CreateContact')),
    scope: 'write:contacts'
  },
  {
    path: 'company',
    component: asyncComponent(() => import ('Contact/containers/ListContact')),
    scope:'read:contacts'
  },
  {
    path:"company/:id",
    component:asyncComponent(() => import("Contact/containers/RetrieveContact")),
    scope:'read:contacts'
  },
  {
  path:"company/:id/edit",
  component:asyncComponent(() => import ("Contact/containers/EditContact")),
  scope:'write:contacts'
  }, {
  path: "company/type/:id",
    component: ListContact,
    scope: "read:contacts"
  }
]

export default contactroutes;

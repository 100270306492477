import React, { Component } from 'react'
import { Button } from 'antd';

import { AdvancedFilterWrapper } from './styles/index.style';
import FilterForm from 'Common/components/Table/FilterForm';
import searchInner from './images/searchinner.png';

class AdvancedFilter extends Component {
  constructor(props){
    super(props);
    this.state = {
      isEnabledFilter: false,
      filterCount: null
    }
  }
  handleAdvancedFilter = value =>{
    this.props.onSubmit(value);    
  }

  handleClosed = (data) => {
    this.setState({isEnabledFilter: false,filterCount: data});
  }

  onClickButton = () => {
    this.setState({isEnabledFilter: true})
  }


  render() {
    const {context,advancedFilterForm} = this.props;
    const AdvancedFilterForm = this.props.advancedFilterForm;
    const { isEnabledFilter , filterCount} = this.state;
    return (
      <div>
      { isEnabledFilter ? (
        <AdvancedFilterWrapper>
         {
           context === "allocations" ? (
            <AdvancedFilterForm
              handleAdvancedFilter={this.handleAdvancedFilter}
              handleClosed={this.handleClosed}
              advancedFilterData={this.props.advancedFilterData}
            />
           ): 
            <FilterForm
              handleAdvancedFilter={this.handleAdvancedFilter}
              handleClosed={this.handleClosed}
              advancedFilterData={this.props.advancedFilterData}
              advancedFilterForm = {advancedFilterForm}
            />
         }
        </AdvancedFilterWrapper>
      ) : (
        <Button className="advFilterButton" onClick={this.onClickButton}>{<img src={searchInner}/>}
{filterCount}</Button>
      )}
      </div>
    )
  }
}


export default AdvancedFilter;
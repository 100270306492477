import LayoutContentStyle from 'Common/styles/layoutContent.style';
import React from 'react';
import {Spin} from 'antd';
const LayoutContentWithSpinner = ({loading, children}) => (
    <LayoutContentStyle>
      <Spin spinning={loading || false} delay={500}>
        {children}
      </Spin>
    </LayoutContentStyle>
);
export default LayoutContentWithSpinner;
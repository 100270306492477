import { FETCH_ANNOTATION, FETCH_DRIVERS, FETCH_EWD_REQUEST, FETCH_EWD } from "./actions";

const initialState = {
  loading: false,
  drivers: [],
  annotations: [],
  ewd: null,
};

const ewdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRIVERS:
      return {
        ...state,
        drivers: action.payload,
      };
    case FETCH_ANNOTATION:
      return {
        ...state,
        annotations: action.payload.result,
      };
    case FETCH_EWD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EWD:
      return {
        ...state,
        loading: false,
        ewd: action.payload,
      };
    default:
      return state;
  }
};

export default ewdReducer;

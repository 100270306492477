import asyncComponent from "Common/helpers/AsyncFunc";
import ListVehicle from "Vehicle/containers/ListVehicle";

const vehicleroutes = [
    // {
    //   path: "fleet",
    //   component: asyncComponent(() => import("Vehicle/containers/ListVehicle")),
    //   scope: "read:vehicles"
    // },
    {
      path: "fleet/create",
      component: asyncComponent(() => import("Vehicle/containers/CreateVehicle")),
      scope: "write:vehicles"
    },
    {
      path: "fleet/:id",
      component: asyncComponent(() => import("Vehicle/containers/RetrieveVehicle")),
      scope: "read:vehicles"
    },
    {
      path: "fleet/:id/edit",
      component: asyncComponent(() => import("Vehicle/containers/EditVehicle")),
      scope: "write:vehicles"
    },
     {
      path: "fleet/status/:status",
      component: ListVehicle,
      scope: "read:vehicles"
    },
  ];

export default vehicleroutes;

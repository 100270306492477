import { all, takeEvery, fork, call } from "redux-saga/effects";
import actions from "./actions";
import Api from "Common/api/Api2";
const api = new Api();

export function* createNewsTraining() {
  yield takeEvery(actions.CREATE_NEWS_TRAINING_REQUEST, function* ({ data, resolve, reject }) {
    try {
      const response = yield call(api.post, "noticeBoard/news", data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* fetchNewsTrainingList() {
  yield takeEvery(actions.FETCH_NEWS_TRAINING_LIST_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "noticeBoard/news", {});
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* updateNewsTraining() {
  yield takeEvery(actions.UPDATE_NEWS_TRAINING_REQUEST, function* ({ data, resolve, reject }) {
    try {
      const response = yield call(api.update, `noticeBoard/news/${data?.id}`, data);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(createNewsTraining), fork(fetchNewsTrainingList), fork(updateNewsTraining)]);
}

import styled from "styled-components";

const PageTopFilter = styled.div`
  font-family: "Manrope";
  font-weight:600;
  .ant-tabs-tab{
    margin:0 15px 0 0 !important;
    font-size:17px;
  }
  .ant-tabs-tab-active {
    font-weight:600 !important;
    color: #128239 !important;
  }
  .ant-tabs-ink-bar{
  background-color:#128239 !important;
  }
  .ant-tabs-nav .ant-tabs-tab:hover{
   color: #128239 !important;
  }
  ul{
    margin: 20px 0px;
    padding:0px 0px 10px 0px;
    border-bottom: 1px solid #d9d9d9;
 li{
      display:inline;
      padding-right:40px;
      font-weight:bold;
  }
}
  `;
export default PageTopFilter;
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Spin, Alert} from 'antd';
import Select,{SelectOption} from 'Common/components/select';
import { Field, FormSection} from 'redux-form';

import SelectFieldWrapper from 'Common/styles/selectfield.style';

import actions from "Vehicle/redux/actions";
const { fetchContractorList } = actions;

class ContractorDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contractorList: [],
    };
  }

  componentDidMount=()=>{
    this.getContractor();
  }

  
  getContractor = () => {
      this.setState({
        loading:true
      });
      return new Promise((resolve, reject) => {
        this.props.fetchContractorList( resolve, reject)
      }).then((response) => {
        this.setState({
          loading:false,
          contractorList:response.data
        });
      }, (error) => {
       console.log('error');
       this.setState({
        loading:false
      });
      }, this);
    }

    
  render(){
    // const {defaultValue, input, meta: {  error }, type, loading, showDescription, ...custom } = this.props;
    const {defaultValue, input, addOther, ...custom } = this.props;
    const {contractorList,loading} = this.state;
    const error=false;
    const hasError =  false;
    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={loading}>
          <Select
           {...input}
           {...custom}
           className="inputField ant-select-lg"
          //  value={input.value || defaultValue || ""}
           showSearch
           filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <SelectOption value="">Select</SelectOption>
            {contractorList.map((dropdown,index)=>(
              <SelectOption key={index} value={dropdown.id}>{dropdown.name}</SelectOption>
            ))}
            {addOther == true && (
              <SelectOption key="other" value="other">Other</SelectOption>
            )}
          </Select>
          { hasError &&
            <Alert
            type="error"
            message={error}
            banner />
          }
        </Spin>
      </SelectFieldWrapper>
   );
  }
}

const ContractorDropdownConnect =  connect(
  state => ({
    loading: state.Dropdown.loading,
    data: state.Dropdown.data
  }),
  { fetchContractorList }
)(ContractorDropdown);

export default ContractorDropdownConnect;

export const  ContractorDropdownWFS = ({name, type, ...custom}) => (
  <FormSection name={name}>
    <Field name="id" component={ContractorDropdownConnect} {...custom}/>
  </FormSection>
);


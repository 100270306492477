import actions from "./actions";


const initState = {
  events: [],
  view: "month"
};

export default function calendarReducer(state = initState, action) {
  switch (action.type) {
    case actions.CALENDAR_VIEW:
      return {
        ...state,
        view: action.view
      };
      
    case actions.CREATE_CALENDAR_EVENT_SUCCESS:        
      return  {                                          
        ...state,
        events:[...state.events,action.response]
      }
    
    case actions.LIST_CALENDAR_EVENT_SUCCESS: 
      return {
        ...state,
        events: action.response
      };
    case actions.DELETE_CALENDAR_EVENT_SUCCESS: 
      const eventsList =  state.events.filter(event =>  event.id !== action.event_id)
      return {
        ...state,
        events: eventsList    
      }

    case actions.UPDATE_CALENDAR_EVENT_SUCCESS:        
      const updateEvents = state.events.map(event => {
        if (event.id === action.event_id) {
          return event  = action.updateData 
        }
        return event;
      });
    return { ...state, events: updateEvents };
    default:
      return state;
  }
}

import React, { Component } from 'react'
import { Tag } from 'antd';
import moment, { isMoment } from 'moment';
import localizeDate from 'Common/localizeDate';

import { AppliedFilterWrapper } from './styles/index.style';
import IntlMessages from 'Common/components/intlMessages';
import { normalizeAdvancedFilterData } from "Common/components/Table/index";

const normalizeObjectData = (dataInObject) => {
  let dataInArray = [];
  for (var key in dataInObject) {
    if(dataInObject[key]){
       // if(moment.isMoment(dataInObject[key])){
       //   dataInObject[key] = localizeDate(dataInObject[key]);
       // }
      dataInArray.push({
        key,
        value: dataInObject[key]
      });
    }
  }
  return dataInArray;
}

class AppliedFilterTags extends Component {
  getAppliedFilters = () => {
    let appliedFilters = normalizeObjectData(this.props.advancedFilterData);
    let filterTags =  appliedFilters.filter(filter=> {
      const filterValue = normalizeAdvancedFilterData(filter.value);
      return filterValue && filterValue.key;
    }).map((filter, index) => {
      const filterValue  = normalizeAdvancedFilterData(filter.value);
      //check whether the value is date and  convent to local date
       filterValue.label = moment(filterValue.label,"YYYY-MM-DD HH:mm", true).isValid()
        ? isMoment(localizeDate(filterValue.label)) && localizeDate(filterValue.label).format('DD/MM/YYYY HH:mm')
        : filterValue.label ;
      return (
        <Tag key={filter.key} closable={true} afterClose={() => this.props.handleFilterRemove(filter.key)}>
          <IntlMessages id={"forms.label."+filter.key}/>{' : '}{filterValue.label}
        </Tag>
      )
    });
    return filterTags;
  }

  render() {
    const appliedFilters = this.getAppliedFilters();
    return (
      <AppliedFilterWrapper>
        {appliedFilters}
      </AppliedFilterWrapper>
    )
  }
}

export default AppliedFilterTags;

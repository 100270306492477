
import React, { Component } from 'react'

import scriptLoader from 'react-async-load-script'
import { HereMapWrapper } from 'Common/components/HereMap/styles/heremap.style';

/* global H:false */
// /* global ui:false */
class HereMap extends Component {
  constructor(props){
    super(props);
    this.state = {
      defaultLayers: {},
      fleetStyleLayer: {},
      map: {},
      ui: {},
      H: {},
      zoom: props.zoom,
      geolocation: props.geolocation 
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if(nextProps && nextProps.isTrafficEnable !== this.props.isTrafficEnable){
      this.liveTrafficInfo(nextProps.isTrafficEnable);
    }
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.isScriptLoadSucceed !== this.props.isScriptLoadSucceed && 
      this.props.isScriptLoadSucceed
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const {mapId}=this.props
    const platform = new H.service.Platform({
      app_id: process.env.REACT_APP_HERE_MAP_APP_ID,
      apiKey: "ffZhWtOIL66tPB0zmTpRZzTXjHW4WfQrbztqx-vvfd8",
      useCIT: true,
      useHTTPS: true
    });
    const pixelRatio = window.devicePixelRatio || 1;
    const defaultLayers = platform.createDefaultLayers({
      tileSize: pixelRatio === 1 ? 256 : 512,
      ppi: pixelRatio === 1 ? undefined : 320,
     });
    //defaultLayers.normal.map.setMin(11);

    const map = new H.Map(
      document.getElementById(mapId),
      defaultLayers.vector.normal.map,
      {center: {lat: -37.814, lng: 144.96332}},
      {pixelRatio: pixelRatio}
    );
    // const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    const ui = H.ui.UI.createDefault(map, defaultLayers);
    var mapTileService = platform.getMapTileService({ 'type': 'base' });
    var fleetStyleLayer = mapTileService.createTileLayer(
      'maptile', 
      'normal.day.grey', 
      256, 
      'png8', 
      { 'style': 'mini' });
    map.setBaseLayer(fleetStyleLayer);
    this.setState({ 
      fleetStyleLayer: fleetStyleLayer,
      defaultLayers: defaultLayers,
      map: map,
      H: H,
      ui: ui
    }); 
  }
  liveTrafficInfo = (isTrafficEnable) => {
    const { map,defaultLayers,fleetStyleLayer } = this.state;
    if(isTrafficEnable){
      map.setBaseLayer(defaultLayers.normal.traffic);
      map.addLayer(defaultLayers.incidents) 
    }
    else {
     map.setBaseLayer(fleetStyleLayer);
     map.removeLayer(defaultLayers.incidents);
    }
  }


  render() {
    const { H, map, zoom, ui } = this.state;
    const { children, height, mapId } = this.props;
    console.log(mapId)
    return (
      <div>
        <HereMapWrapper height={height} id={mapId}>
         {(Object.keys(H).length != 0) && children &&
         React.Children.map(children, child => (
            React.cloneElement(child, { map,H,zoom,ui})
          )) 
        }
        </HereMapWrapper>
      </div>
    )
  }
}

export default scriptLoader([
  'https://js.api.here.com/v3/3.1/mapsjs-core.js',
  'https://js.api.here.com/v3/3.1/mapsjs-service.js',
  'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
  'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js'
]) (HereMap);
import React, { Component } from 'react'
import { Col, Row, Button, Icon} from 'antd';
import { reduxForm } from 'redux-form';

import IntlMessages from 'Common/components/intlMessages';
import { FilterHeader,FormWrapper } from './styles/index.style';

const FORM_NAME = "filterform";

class FilterForm extends Component {

  handleFormElements = data => {
    const filterCount = Object.keys(data).length;
    this.props.handleClosed(filterCount);
    this.props.handleAdvancedFilter(data);
  }

  handleClosedRequest = () => {
    this.props.handleClosed();
  }

  componentDidMount(){
    this.props.initialize(this.props.advancedFilterData);
  }

  render() {
    const {handleSubmit} = this.props;
    const AdvancedFilterForm = this.props.advancedFilterForm;
    return (
      <FormWrapper className="ant-col-lg-6 ant-col-md-10 ant-col-sm-10 ant-col-xs-17" style={{position:'fixed', padding: '10px 20px'}}>
        <Row gutter={30} style={{marginLeft:'-15px'}}>  
          <FilterHeader>
            <h2><Icon type="filter" /> Filters </h2>
            <Icon onClick={this.handleClosedRequest} className="float-right close" style={{marginRight:'5px'}} type="close-circle-o" />
          </FilterHeader>
          <Col className="gutter-row" span={24}>
            <AdvancedFilterForm />
            <div className="form-group">
              <Button
                className="applyButton"
                type="primary"
                onClick={handleSubmit(this.handleFormElements.bind(this))}
                >
                <IntlMessages id="forms.button.filter"/>
              </Button>
            </div>
          </Col>
        </Row>
        </FormWrapper>
    )
  }
}
const filterForm = reduxForm({
    form: FORM_NAME
  })(FilterForm)
    
export default filterForm;
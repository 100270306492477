const actions = {
    CREATE_FAULT_REQUEST: 'CREATE_FAULT_REQUEST',
    FETCH_FAULT_LIST_REQUEST: 'FETCH_FAULT_LIST_REQUEST',
    FETCH_FAULT_LIST_SUCCESS: "FETCH_FAULT_LIST_SUCCESS",
    FETCH_FAULT_LIST_FAIL: "FETCH_FAULT_LIST_FAIL",

    RETRIEVE_FAULT_REQUEST: "RETRIEVE_FAULT_REQUEST",
    UPDATE_FAULT_REQUEST: "UPDATE_FAULT_REQUEST",

    DELETE_FAULT_REQUEST: "DELETE_FAULT_REQUEST",

    createFault: (data, context, resolve, reject) => ({
        type: actions.CREATE_FAULT_REQUEST,
        data,
        context,
        resolve,
        reject
    }),
    fetchFaultList: (resolve, reject) => ({
        type: actions.FETCH_FAULT_LIST_REQUEST,
        resolve,
        reject
    }),
    updateFault: (data, id, resolve, reject) => ({
        type: actions.UPDATE_FAULT_REQUEST,
        data,
        id,
        resolve,
        reject
    }),
    retrieveFault: (id, resolve, reject) => {
        return {
            type: actions.RETRIEVE_FAULT_REQUEST,
            id,
            resolve,
            reject
        }
    },
    deleteFault: (id, resolve, reject) => ({
        type: actions.DELETE_FAULT_REQUEST,
        id,
        resolve,
        reject
    }),
};
export default actions;

import asyncComponent from "Common/helpers/AsyncFunc";

const toolboxroutes = [
    {
        path: 'toolbox',
        component: asyncComponent(() => import('Toolbox/containers/ListToolbox')),
        scope: 'read:users'
    },
    {
        path: 'toolbox/:id',
        component: asyncComponent(() => import('Toolbox/containers/RetrieveToolbox')),
        scope: 'read:users'
    },
]

export default toolboxroutes;

import styled from "styled-components";

const SectionField = styled.div`
  // border:1px solid #ddd;
  // padding:20px;
  // overflow:hidden;
  h2{
    font-size:20px;
    color: #353E51;
    // border-bottom: 2px solid #04A9F4;
    margin-bottom: 15px;
    display:block;
  }
  h4{
    color: #767676;
    font-weight: 600;
    border-bottom: 2px solid #04A9F4;
    display:inline-block;
    margin-bottom: 15px;
    font-size: 15px;
  }
  h3{
    color:#889;
    font-size:18px;
  }
  .ant-form-item-control{
    line-height:30px !important;
  }
  

`;

export  default SectionField;

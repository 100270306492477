import actions from 'Allocation/redux/actions';

const initState = {
    error: null,
    allocations: [],
    vehicle_calendar_events: [],
    driver_calendar_events: [],
    bookingAllocations: [],
    loading : {
        vehicle : false,
        driver : false
    }
};


export default function allocationReducer(state = initState, action) {

    switch (action.type) {
        case actions.LIST_VEHICLES_CALENDAR_EVENTS_SUCCESS:
            return { ...state, vehicle_calendar_events: action.response, loading :  {...state.loading, vehicle: false} };

        case actions.LIST_DRIVERS_CALENDAR_EVENTS_SUCCESS:
            return { ...state, driver_calendar_events: action.response, loading : {...state.loading, driver: false} };
            
        case actions.LIST_VEHICLES_CALENDAR_EVENTS_REQUEST : 
            return { ...state, loading : {...state.loading, vehicle: true}}   
            
        case actions.LIST_DRIVERS_CALENDAR_EVENTS_REQUEST : 
            return { ...state, loading : {...state.loading, driver: true}}  

        case actions.SET_ALLOCATIONS:
            return {...state, bookingAllocations: action.allocations}

        case actions.UNSET_ALLOCATIONS:
            return {...state, bookingAllocations: []}

        default:
            return state;


    }
}

import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'Assets/redux/actions';
//import {push} from 'react-router-redux';
import  Api  from 'Common/api/Api';

const api = new Api();

export function* createAssets() {
    yield takeEvery(actions.CREATE_ASSETS_REQUEST, function*({data,context,resolve,reject}) {
        try{
            const response = yield call(api.post,'assets',data);
            yield call(resolve,response);
            if(context === 'save'){
                yield(put('/assets/'+response.data.id));
            }
        }
        catch(error) {
            yield call(reject, error);
        }
    });
}

export function* fetchAssetsList(){
    yield takeEvery(actions.FETCH_ASSETS_LIST_REQUEST, function*({resolve, reject}){
        try{
            const response = yield call(api.get, "assets", {});
            if(resolve){
                yield call(resolve, response);
            } else {
                yield put({
                    type:actions.FETCH_ASSETS_LIST_SUCCESS,
                    response:response.data.results
                });
            }
        } catch (error){
            if(reject){
                yield call(reject, error);
            } else {
                yield put({
                    type:actions.FETCH_ASSETS_LIST_FAIL,
                    response:error
                });
            }
        }
    });
}

export function* retrieveAssets() {
  yield takeEvery(actions.RETRIEVE_ASSETS_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'assets/' + id, {});
        yield call(resolve,response);
    } catch(error) {
        yield call(reject,error);
    }
  });
}

export function* updateAssets(){
    yield takeEvery(actions.UPDATE_ASSETS_REQUEST,function*({data, id, resolve,reject}){
        try{
            const response = yield call(api.update,'assets/'+id, data);
            yield call(resolve,response);
            yield put('/assets/' + response.data.id);
        }catch(error){
            yield call(reject,error);
        }
    });
}

export function* deleteAssets() {
  yield takeEvery(actions.DELETE_ASSETS_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.delete,'assets/'+ id, {})
      if(resolve){
        yield call(resolve,response);
        yield put('/assets');
      }
    } catch(error) {
        yield call(reject,error);
    }
  });
}
export default function* rootSaga() {
    yield all([
        fork(createAssets),
        fork(fetchAssetsList),
        fork(retrieveAssets),
        fork(updateAssets),
        fork(deleteAssets),
    ]);
}

const actions = {
  CREATE_ROUTE_REQUEST:"CREATE_ROUTE_REQUEST",

  UPDATE_ROUTE_REQUEST:"UPDATE_ROUTE_REQUEST",

  FETCH_ROUTE_LIST_REQUEST:"FETCH_ROUTE_LIST_REQUEST",
  FETCH_ROUTE_LIST_SUCCESS:"FETCH_ROUTE_LIST_SUCCESS",
  FETCH_ROUTE_LIST_FAIL:"FETCH_ROUTE_LIST_FAIL",

  FETCH_ROUTE_DETAIL_REQUEST:"FETCH_ROUTE_DETAIL_REQUEST",

  DELETE_ROUTE_DETAIL_REQUEST:"DELETE_ROUTE_DETAIL_REQUEST",


  createRoute(data, context, resolve, reject){
    return {
      type:actions.CREATE_ROUTE_REQUEST,
      data,
      context,
      resolve,
      reject
    }
  },

  updateRoute(id, data, resolve, reject){
    return {
      type:actions.UPDATE_ROUTE_REQUEST,
      id,
      data,
      resolve,
      reject
    }
  },

  fetchRouteList(resolve, reject){
    return {
      type:actions.FETCH_ROUTE_LIST_REQUEST,
      resolve,
      reject
    }
  },

  fetchRouteDetail(id, resolve, reject){
    return {
      type:actions.FETCH_ROUTE_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }
  },

  deleteRouteDetail(id, resolve, reject){
    return {
      type:actions.DELETE_ROUTE_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }
  }

}
export default actions;

const contactOrganization = ['client', 'operator'];
const contactIndividual= ['client individual'];

export const contactIsOrganization = type => contactOrganization.includes(type.toLowerCase());
export const contactIsIndividual = type => contactIndividual.includes(type.toLowerCase());

export const ContactName = ({contact}) => {
  if(!contact || !contact.contactType || !contact.contactType.title){
     return "";
  } else if(contactIsOrganization(contact.contactType.title)){
    return contact.firstName;
  } else {
    return contact.firstName+" "+ contact.lastName;
  }
}

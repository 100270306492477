import { takeEvery, call, put, all, fork } from "redux-saga/effects";
import actions from "Fault/redux/actions";
import { push } from "react-router-redux";
import Api from "Common/api/Api2";

const api = new Api();

export function* createFault() {
  yield takeEvery(actions.CREATE_FAULT_REQUEST, function* ({ data, context, resolve, reject }) {
    try {
      const response = yield call(api.post, "fault", data);
      yield call(resolve, response);
      if (context === "save") {
        yield put(push("/faults/" + response.data.id));
      }
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* fetchFaultList() {
  yield takeEvery(actions.FETCH_FAULT_LIST_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "fault", {});
      if (resolve) {
        yield call(resolve, response);
      } else {
        yield put({
          type: actions.FETCH_FAULT_LIST_SUCCESS,
          response: response.data.results,
        });
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      } else {
        yield put({
          type: actions.FETCH_FAULT_LIST_FAIL,
          response: error,
        });
      }
    }
  });
}

export function* retrieveFault() {
  yield takeEvery(actions.RETRIEVE_FAULT_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.get, "fault/" + id, {});
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* updateFault() {
  yield takeEvery(actions.UPDATE_FAULT_REQUEST, function* ({ data, id, resolve, reject }) {
    try {
      const response = yield call(api.update, "fault/" + id, data);
      yield call(resolve, response);
      yield put(push("/faults/" + id));
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export function* deleteFault() {
  yield takeEvery(actions.DELETE_FAULT_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.delete, "fault/" + id, {});
      if (resolve) {
        yield call(resolve, response);
        yield put(push("/faults/type/all"));
      }
    } catch (error) {
      yield call(reject, error);
    }
  });
}
export default function* rootSaga() {
  yield all([fork(createFault), fork(fetchFaultList), fork(retrieveFault), fork(updateFault), fork(deleteFault)]);
}

import asyncComponent from "Common/helpers/AsyncFunc";
import ListFault from "Fault/containers/ListFault";
const faultroutes = [
    {
        path:'faults/create',
        component: asyncComponent(() => import ('Fault/containers/CreateFault')),
        scope: 'write:users'
    },
    {
        path: 'faults',
        component: asyncComponent(() => import ('Fault/containers/ListFault')),
        scope: 'read:users'
    },
    {
        path:"faults/:id",
        exact:true,
        component: asyncComponent(() => import ('Fault/containers/RetrieveFault')),
        scope:'read:users'
    },
     {
      path: "faults/:id/edit",
      exact:true,
      component: asyncComponent(() => import("Fault/containers/EditFault")),
      scope: "write:users"
    },
    {
        path: "faults/type/:id",
        component:ListFault,
        scope: "read:users"
    }
]

export default faultroutes;

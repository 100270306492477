const actions = {
  CREATE_NOTICE_REQUEST: "CREATE_NOTICE_REQUEST",
  FETCH_NOTICE_LIST_REQUEST: "FETCH_NOTICE_LIST_REQUEST",
  UPDATE_NOTICE_REQUEST: "UPDATE_NOTICE_REQUEST",

  createNotice: (data, resolve, reject) => ({
    type: actions.CREATE_NOTICE_REQUEST,
    data,
    resolve,
    reject,
  }),

  fetchNotice: (resolve, reject) => ({
    type: actions.FETCH_NOTICE_LIST_REQUEST,
    resolve,
    reject,
  }),

  updateNotice: (data, resolve, reject) => ({
    type: actions.UPDATE_NOTICE_REQUEST,
    data,
    resolve,
    reject,
  }),
};
export default actions;

import asyncComponent from "Common/helpers/AsyncFunc";

const feedbackroutes = [
    {
        path:'feedbacks/create',
        component: asyncComponent(() => import ('Feedback/containers/CreateFeedback')),
        scope: 'write:feedbacks'
    },
    {
        path: "feedbacks/:id/edit",
        component: asyncComponent(() => import("Feedback/containers/EditFeedback")),
        scope: 'write:feedbacks'
      },
      {
        path: "feedbacks",
        component: asyncComponent(() => import("Feedback/containers/ListFeedback")),
        scope: 'read:feedbacks'
      },
      {
          path:"feedbacks/:id",
          component:asyncComponent(() => import("Feedback/containers/RetrieveFeedback")),
          scope:'read:feedbacks'
      }
]

export default feedbackroutes;

import React from 'react';
import IntlMessages from 'Common/components/intlMessages';
import { DriverDropdownSelectWithFormSection as DriverDropdownSelect } from 'Driver/components/form/DriverDropdown';
import { Field } from "redux-form";
import DatePicker from "Common/components/DatePickers";


const DocumentAdvancedFilter = () => (
    <div>
        <div className="form-group">
            <label><IntlMessages id="forms.label.date" /> </label>
            <Field name="createdOn" component={DatePicker} />
        </div>

        <div className="form-group">
            <label><IntlMessages id="forms.label.driver" /> </label>
            <DriverDropdownSelect name="driver" />
        </div>
    </div>
);

export default DocumentAdvancedFilter;

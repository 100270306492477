import { all, takeEvery, put, fork, call} from 'redux-saga/effects';
import actions from 'Location/redux/actions';
import { push } from 'react-router-redux';
import  Api  from 'Common/api/Api';

const api = new Api();

export function* createLocationRequest() {
  yield takeEvery(actions.CREATE_LOCATION_REQUEST, function*({data,context,resolve,reject}) {
    try{
      const response = yield call(api.post,'locations',data);
      yield call(resolve,response);
      if(context === "save"){
        yield(put(push('/locations/'+response.data.id)));
      }
    } catch(error) {
      yield call(reject, error);
    }
  });
}

export function* fetchLocationListsRequest() {
  yield takeEvery(actions.FETCH_LOCATION_LIST_REQUEST, function*({ data, resolve, reject}) {
    try{
      let URL = 'locations?sort[name]=asc';
      URL = data.search ? URL+ '&&search='+data.search : URL;
      URL = data.filter ? URL+ '&&filters[type]='+data.filter : URL;
      URL=data.pageSize? URL+ '&pageSize='+data.pageSize:URL+'&pageSize=50';
      URL=data.page? URL+ '&page='+data.page:URL+'&page=1';
      const response = yield call(api.get,URL, {});
      if(resolve){
        yield call(resolve,response);
      } else {
        yield put({
          type: actions.FETCH_LOCATION_LIST_SUCCESS,
          response: response.data
        });
      }
    } catch(error) {
      if(reject){
        yield call(reject,error);
      } else {
        yield put({
          type: actions.FETCH_LOCATION_LIST_FAIL,
          error
        });
      }
    }
  });
}

export function* deleteLocationRequest() {
  yield takeEvery(actions.DELETE_LOCATION_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.delete,'locations/'+ id, {})
      if(resolve){
        yield call(resolve,response);
        yield put(push('/locations'));
      } 
    } catch(error) {
      if(reject){
        yield call(reject,error);
      } 
    }  
  });
}
export function* retrieveLocationRequest() {
  yield takeEvery(actions.FETCH_LOCATION_DETAIL_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'locations/' + id, {});
      if(resolve){
        yield call(resolve,response);
      }
    } catch(error) {
      if(reject){
        yield call(reject,error);
      } 
    }
  });
}


export function* fetchLocationEditRequest() {
  yield takeEvery(actions.EDIT_LOCATION_DETAIL_REQUEST, function*({data,id,resolve,reject}) {
    try{
      const response = yield call(api.update,'locations/' + id, data);
      yield call(resolve,response);
      yield put(push('/locations/' + id));
    } catch(error) {
      yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createLocationRequest),
    fork(fetchLocationListsRequest),
    fork(deleteLocationRequest),
    fork(retrieveLocationRequest),
    fork(fetchLocationEditRequest)
  ]);
}

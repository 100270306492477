import React from "react";
import { Icon, Popconfirm, Tooltip } from "antd";
import Auth0 from "Common/helpers/auth0-custom/index";
import Protected from "Common/components/authorization/Protected";
import StopPropagationWrapper from "Common/components/StopPropagationWrapper";

const auth0 = new Auth0();

const getSplitString = (context) => {
  const splitContext = context.split(":");
  if (splitContext.length > 1) {
    return splitContext[1];
  } else {
    return splitContext[0].substring(0, splitContext[0].length - 1);
  }
};

const ActionItems = ({
  onEditClicked,
  onConfirm,
  id,
  context,
  onSuspend = false
}) => (
  <span className="userlist-actionwrapper">
    <Tooltip title="Edit">
      <Icon
        type="edit"
        style={{ fontSize: 16 }}
        onClick={onEditClicked.bind(this, id)}
      />
    </Tooltip>
    {onConfirm && (
      <Popconfirm
        placement="topRight"
        title={`Are you sure you want to delete this ${getSplitString(
          context
        )}?`}
        onConfirm={onConfirm.bind(this, id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Delete">
          <Icon type="delete" style={{ fontSize: 16 }} />
        </Tooltip>
      </Popconfirm>
    )}
    {onSuspend && (
      <Popconfirm
        placement="topRight"
        title={`Are you sure you want to suspend this ${getSplitString(
          context
        )}?`}
        onConfirm={onSuspend.bind(this, id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Suspend">
          <Icon type="warning" style={{ fontSize: 16 }} />
        </Tooltip>
      </Popconfirm>
    )}
  </span>
);

const authorizeColumn = (
  onEditClicked,
  onConfirm,
  context,
  onSuspend = false
) => ({
  title: "Action",
  key: "action",
  render: (text, row, index) => {
    if (context === "users") {
      return (
        <Protected scope={"write:users:" + row.role}>
          <StopPropagationWrapper>
            <ActionItems
              onEditClicked={onEditClicked}
              onConfirm={onConfirm}
              id={row.id}
              context={context}
              onSuspend={row.role !== "admin" ? () => onSuspend(row) : false}
            />
          </StopPropagationWrapper>
        </Protected>
      );
    } else {
      return (
        <StopPropagationWrapper>
          <ActionItems
            onEditClicked={onEditClicked}
            onConfirm={onConfirm}
            id={row.id}
            context={context}
          />
        </StopPropagationWrapper>
      );
    }
  }
});

export default (
  getDefaultColumns,
  onEditClicked,
  onConfirm,
  context,
  onSuspend = false
) => {
  if (auth0.userHasScope("write:" + context)) {
    const column = getDefaultColumns().concat(
      authorizeColumn(onEditClicked, onConfirm, context, onSuspend)
    );
    return column;
  }
  return getDefaultColumns();
};

import useroptions from "User/settings/useroptions";
import driveroptions from "Driver/settings/driveroptions";
import bookingoptions from "Booking/settings/bookingoptions";
import vehicleoptions from "Vehicle/settings/vehicleoptions";
import contactoptions from "Contact/settings/contactoptions";
import incidentoptions from "Incident/settings/incidentoptions";
// import faultoptions from "Fault/settings/faultoptions";
import fatigueoptions from "Fatigue/settings/fatigueoptions";
import documentoptions from "Document/settings/documentoptions";
import ncroptions from "NCR/settings/ncroptions";
import fueloptions from "Fuel/settings/fueloptions";
import supplieroptions from "Supplier/settings/supplieroptions";
import maintenanceoptions from "Maintenance/settings/maintenanceoptions";
import reportoptions from "Report/settings/reportoptions";
import assetsoptions from "Assets/settings/assetsoptions";
import assettypeoptions from "AssetType/settings/assettypeoptions";
// import workdiaryoptions from "WorkDiary/settings/workdiaryoptions";
import driverSignupoptions from "DriverSignup/settings/driverSignupoptions";
import toolboxoptions from "Toolbox/settings/toolboxoptions";
import notificationoptions from "Notification/settings/notificationOptions";
import shiftOptions from "Shifts/settings/shiftOptions";
import newsOptions from "NewsTraining/settings/newsOptions";
import noticeOptions from "NoticeBoard/settings/noticeOptions";
import ewdOptions from "EWD/settings/ewdOptions";
import Protected from "./components/authorization/Protected";

const options = [
  {
    key: "dashboard",
    label: "sidebar.dashboard",
    leftIcon: "ri-dashboard-3-line",
    scope: "",
  },
  ...notificationoptions,
  ...shiftOptions,
  ...bookingoptions,

  // Fleet Section
  {
    key: "fleet",
    label: "sidebar.fleet",
    leftIcon: "ri-car-line",
    children: [...driveroptions, ...vehicleoptions],
  },

  // Compliance Section
  {
    key: "compliance",
    label: "sidebar.compliance",
    leftIcon: "ri-check-double-line",
    children: [...toolboxoptions, ...ncroptions, ...ewdOptions, , ...incidentoptions, ...fatigueoptions, ...assetsoptions],
  },

  // Maintenance Section
  {
    key: "maintenance",
    label: "sidebar.maintenance",
    leftIcon: "ri-tools-line",
    children: [...maintenanceoptions],
  },

  // Documents Section
  {
    key: "documents",
    label: "sidebar.documents",
    leftIcon: "ri-file-copy-line",
    children: [...documentoptions],
  },

  // Knowledge Section
  {
    key: "knowledgestation",
    label: "sidebar.knowledgestation",
    leftIcon: "ri-admin-line",
    children: [...noticeOptions, ...newsOptions, ...supplieroptions, ...reportoptions, ...fueloptions, ...useroptions],
  },

  // Admin Section (Protected)
  Protected({
    scope: "read:contacts",
    children: {
      key: "adminstation",
      label: "sidebar.adminstation",
      leftIcon: "ri-book-line",
      children: [...contactoptions, ...driverSignupoptions, ...assettypeoptions],
    },
  }) || {},
];

export default options;

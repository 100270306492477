import { all, takeEvery, put, fork, call } from "redux-saga/effects";
// import { push } from "react-router-redux";

import actions from "Toolbox/redux/actions";
import Api from "Common/api/Api2";

const api = new Api();

export function* fetchToolboxList() {
  yield takeEvery(
    actions.FETCH_TOOLBOX_LIST_REQUEST,
    function* ({ resolve, reject }) {
      try {
        const response = yield call(api.get, "toolbox", {});
        if (resolve) {
          yield call(resolve, response);
        } else {
          yield put({
            type: actions.FETCH_TOOLBOX_LIST_SUCCESS,
            response: response.data.results,
          });
        }
      } catch (error) {
        if (reject) {
          yield call(reject, error);
        } else {
          yield put({
            type: actions.FETCH_TOOLBOX_LIST_FAIL,
            error,
          });
        }
      }
    }
  );
}

export function* createToolbox() {
  yield takeEvery(
    actions.CREATE_TOOLBOX_REQUEST,
    function* ({ data, resolve, reject }) {
      try {
        const response = yield call(api.post, "toolbox", data);
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}

export function* deleteToolbox() {
  yield takeEvery(
    actions.DELETE_TOOLBOX_REQUEST,
    function* ({ id, resolve, reject }) {
      try {
        const response = yield call(api.deleteForm, "toolbox/" + id, {});
        if (resolve) {
          yield call(resolve, response);
        } else {
          yield put({
            type: actions.DELETE_TOOLBOX_SUCCESS,
            response,
          });
        }
      } catch (error) {
        if (reject) {
          yield call(reject, error);
        } else {
          yield put({
            type: actions.DELETE_TOOLBOX_FAIL,
            error,
          });
        }
      }
    }
  );
}

export function* retrieveToolbox() {
  yield takeEvery(
    actions.RETRIEVE_TOOLBOX_REQUEST,
    function* ({ id, resolve, reject }) {
      try {
        const response = yield call(api.get, "toolbox/" + id, {});
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}

function* fetchToolboxPDF() {
  yield takeEvery(actions.DOWNLOAD_TOOLBOX_PDF_REQUEST, function* ({ id, resolve, reject }) {
    try {
      const response = yield call(api.get, `toolbox/download/${id}`);
      if (resolve) yield call(resolve, response);
    } catch (error) {
      if (reject) yield call(reject, error);
    }
  });
}



export default function* rootSaga() {
  yield all([
    fork(fetchToolboxList),
    fork(createToolbox),
    fork(deleteToolbox),
    fork(retrieveToolbox),
    fork(fetchToolboxPDF),
  ]);
}

import asyncComponent from "Common/helpers/AsyncFunc";

const assettyperoute = [
    {
        path: 'assettype/create',
        component: asyncComponent(() => import('AssetType/containers/CreateAssetType')),
        scope: 'context:system'
    },
    {
        path: 'assettype',
        component: asyncComponent(() => import('AssetType/containers/ListAssetType')),
        scope: 'context:system'
    },
    {
        path: "assettype/:id",
        component: asyncComponent(() => import('AssetType/containers/RetrieveAssetType')),
        scope: 'context:system'
    },
    {
        path: "assettype/:id/edit",
        component: asyncComponent(() => import("AssetType/containers/EditAssetType")),
        scope: "context:system"
    }
]

export default assettyperoute;

const actions = {
  CREATE_NEWS_TRAINING_REQUEST: "CREATE_NEWS_TRAINING_REQUEST",
  FETCH_NEWS_TRAINING_LIST_REQUEST: "FETCH_NEWS_TRAINING_LIST_REQUEST",
  UPDATE_NEWS_TRAINING_REQUEST: "UPDATE_NEWS_TRAINING_REQUEST",

  createNewsTraining: (data, resolve, reject) => ({
    type: actions.CREATE_NEWS_TRAINING_REQUEST,
    data,
    resolve,
    reject,
  }),

  fetchNewsTraining: (resolve, reject) => ({
    type: actions.FETCH_NEWS_TRAINING_LIST_REQUEST,
    resolve,
    reject,
  }),

  updateNewsTraining: (data, resolve, reject) => ({
    type: actions.UPDATE_NEWS_TRAINING_REQUEST,
    data,
    resolve,
    reject,
  }),
};
export default actions;

import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Modal,notification } from "antd";
import moment from "moment";
import toTitleCase from "Common/toTitleCase";
import Protected from "Common/components/authorization/Protected";
import getProtectedColumn from "Common/components/authorization/VehicleProtectedColumn";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import PageHeader from "Common/components/Page/PageHeader";
import ItemStatus from "Common/components/ItemSummary/status";
import { VehicleSection, AdminVehicleSection } from "Common/components/Table/styles/responsiveTableWrapper.style";
import TableWrapper from "Common/components/Table/index";
import VehicleAdvancedFilterForm from "Vehicle/components/form/VehicleAdvancedFilterForm";
import actions from "Vehicle/redux/actions";
import localizeDate from "Common/localizeDate";
import isMoment from "moment";
import action from "Vehicle/redux/actions";

const { fetchUser } = action;


const { deleteVehicle } = actions;

const sorter = (a, b) => ("" + a.attr).localeCompare(b.attr);

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Vehicles",
  },
];

const quickFilter = {
  filterBy: "status",
  defaultValue: "all",
  options: [
    {
      tab: "All Vehicles",
      key: "all",
    },
    {
      tab: "Active",
      key: "active",
    },
    {
      tab: "Inactive",
      key: "inactive",
    },
  ],
};

class ListVehicle extends Component {
  state = {
    loading: false,
    updatedOn: moment().valueOf(),
    status: "all",
    modalLogin: false,
    visible: false,
    userRole:''
  };

  setModalLogin(modalLogin) {
    this.setState({ modalLogin });
  }

  componentWillMount = () => {
    this.fetchUser();
    this.setState({
      updatedOn: moment().valueOf(),
      status: this.props.match.params.status,
    });
  };

  fetchUser = () => {
    this.setState({ loading: true });
    const userId = this.props.auth.profile.sub;
    const normalizedId = userId.substring(userId.indexOf("|") + 1);
    return new Promise((resolve, reject) => {
      this.props.fetchUser(normalizedId, resolve, reject);
    }).then((response) => {
        this.setState({ 
          loading: false,
          userRole:response.data.role
        });
       })
      .catch((error) => {
        this.setState({ loading: false });
      }, this);
  };

  // componentWillReceiveProps = (nextProps) => {
  //   this.setState({
  //     status:nextProps.match.params.status,
  //   })
  //  }

  handleRowClick = (record) => {
    this.props.history.push("/fleet/" + record.id);
  };

  handleConfirm = (record) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: (
        <div className="modal_data_wrapper">
          Are you sure you want to delete{" "}
          <span style={{ fontWeight: "bold" }}>"{record.model}"</span>? <br />
          You will not be able to undo this action.
        </div>
      ),
      okText: "Yes, Delete Vehicle",
      cancelText: "Cancel",
      className: "deleteVehicle",
      onOk: () => {
        this.onDeleteClicked(record.id);
      },
    });
  };


  render() {
    const {userRole} =this.state;
    const StyledVehicleSection = userRole === "admin" ? AdminVehicleSection : VehicleSection;
    return (
      <LayoutWrapper>
        <PageHeader
          title={<IntlMessages id="pagetitle.listVehicle" />}
          breadcrumbs={breadcrumbs}
        >
          <Protected scope="write:vehicles">
            <p></p>
          </Protected>
        </PageHeader>
        <LayoutContent loading={this.state.loading}>
          <StyledVehicleSection>
            <TableWrapper
              defaultQuickFilterValue={this.props.match.params.status}
              history={this.props.history}
              handleRowClick={this.handleRowClick}
              updatedOn={this.state.updatedOn}
              columns={userRole==='admin'?this.getAdminColumns():this.getDefaultColumns()}
              url="vehicles"
              advancedFilterForm={VehicleAdvancedFilterForm}
              quickFilterElements={quickFilter}
              quickFilterStatus={this.props.match.params.status}
              createTitle="Add Vehicle"
              onClick={this.onCreateClicked}
            />
          </StyledVehicleSection>
        </LayoutContent>
      </LayoutWrapper>
    );
  }
  onCreateClicked = (e) => {
    e.preventDefault();
    this.props.history.push("/fleet/create");
  };

  onDetailClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/fleet/" + id);
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteVehicle(id, resolve, reject);
    })
      .then(() => {
        this.setState({ loading: false, updatedOn: moment().valueOf() });
        notification.success({
          className: "notification-wrap",
          message: "Vehicle Deleted Successfully",
          placement: "bottomRight",
          duration: 20,
        });
        // message.success("Successfully deleted fleet");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error while deleting fleet");
      }, this);
  };

  onEditClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/fleet/" + id + "/edit/");
  };

  confirm = (record) => {
    // var self = this;
    // self.onDeleteClicked();
    this.handleConfirm(record);
  };

  getDefaultColumns = () => {
    return [
      {
        title: "S.N",
        key: "listNumber",
        sorter: {},
        render: (text, row, index) => {
          return <span>{ index + 1 }</span>;
        }
      },
      {
        title: "Vehicle ID",
        key: "vehicleId",
        dataIndex:"vehicleId",
        sorter: {sorter}
      },
      {
        title: "Registration Number",
        key: "registrationNumber",
        dataIndex: "registrationNumber",
      },
    
      {
        title: "Fleet Type",
        dataIndex: "vehicleType.id",
        key: "vehicleType",
        render: (text, row) =>
          row.vehicleType &&
          row.vehicleType.title &&
          toTitleCase(row.vehicleType.title),
      },
      {
        title: "Make",
        dataIndex: "make",
        key: "make",
      },
   
   {
        title: "Registration Expiry",
        dataIndex: "registrationExpiryDate",
        key: "registrationExpiryDate",
        render: (registrationExpiryDate) =>{
            let finalDate = "N/A";
            if (!!registrationExpiryDate){
          if (registrationExpiryDate !== null){
            finalDate = isMoment(localizeDate(registrationExpiryDate)) &&
              localizeDate(registrationExpiryDate).format("DD/MM/YYYY");
          }
        }
             let color = null;
          if (finalDate !== "") {
            const today = moment().startOf("day");
            const targetDate = moment(finalDate, "DD/MM/YYYY");
            if (targetDate.diff(today, "days") <= 0) {
              color = "red"; // Date has passed, set color to red
            } else if (targetDate.diff(today, "days") <= 10) {
              color = "orange"; // 10 or fewer days remaining, set color to orange
            }
          }
          return <span style={{ color }}>{finalDate}</span>;
      },
    },
      {
        title: "Next Service Date",
        dataIndex: "nextServiceDate",
        key: "nextServiceDate",
        render: (text, { nextServiceDetail }) => {
          let finalDate = "N/A";
          if (!!nextServiceDetail) {
            if (nextServiceDetail.nextServiceDate) {
              finalDate = nextServiceDetail.nextServiceDate;
              finalDate = isMoment(localizeDate(finalDate)) && localizeDate(finalDate).format("DD/MM/YYYY");
            } else if (nextServiceDetail.estimatedNextServiceDate && nextServiceDetail.estimatedNextServiceDate !== "N/A") {
              let estdDate = nextServiceDetail.estimatedNextServiceDate;
              finalDate = isMoment(localizeDate(estdDate)) && localizeDate(estdDate).format("DD/MM/YYYY");
              finalDate = estdDate === "N/A" ? estdDate : finalDate;
            }
          }
          let color = null;
          if (finalDate !== "N/A") {
            const today = moment().startOf("day");
            const targetDate = moment(finalDate, "DD/MM/YYYY");
            if (targetDate.diff(today, "days") <= 0) {
              color = "red"; // Date has passed, set color to red
            } else if (targetDate.diff(today, "days") <= 10) {
              color = "orange"; // 10 or fewer days remaining, set color to orange
            }
          }
          return <span style={{ color }}>{finalDate}</span>;
        },
      },
      {
        title: "Kilometers Till Next Service",
        dataIndex: "nextServiceDetail.dueKms",
        key: "dueKms",
        // render: (dueKms) => (dueKms ? dueKms.toLocaleString() : ""),
        render: (duekms) => {
          if (parseInt(duekms) < 0) {
            return <span style={{ color: "red" }}>{duekms}</span>;
          } else if (parseInt(duekms) < 1000) {
            return <span style={{ color: "orange" }}>{duekms}</span>;
          } else {
            return duekms;
          }
        }
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => ("" + a.attr).localeCompare(b.attr),
        render: (text, row) => <ItemStatus status={row.status} />,
      },
    ];
  };

  getColumns = () => {
    return getProtectedColumn(
      this.getDefaultColumns,
      this.onEditClicked,
      this.confirm,
      "vehicles"
    );
  };
  
  getAdminColumns = () => {
    return [
      {
        title: "S.N",
        key: "listNumber",
        render: (text, row, index) => {
          return <span>{ index + 1 }</span>;
        }
      },
      {
        title: "Vehicle Rego",
        key: "registrationNumber",
        dataIndex: "registrationNumber",
        sorter:{sorter}
      },
      {
        title: "Company",
        key: "company",
        dataIndex: "contact.firstName",
      },
      {
        title: "Vehicle ID",
        key: "vehicleId",
        dataIndex:"vehicleId",
        sorter: {sorter}
      },
      {
        title: "Make",
        dataIndex: "make",
        key: "make",
      },
      {
        title: "Model",
        dataIndex: "model",
        key: "model",
      },
      {
        title: "Type",
        dataIndex: "vehicleType.id",
        key: "vehicleType",
        render: (text, row) =>
          row.vehicleType &&
          row.vehicleType.title &&
          toTitleCase(row.vehicleType.title),
      },
      {
        title: "Registration Expiry",
        dataIndex: "registrationExpiryDate",
        key: "registrationExpiryDate",
        render: (registrationExpiryDate) =>{
            let finalDate = "N/A";
            if (!!registrationExpiryDate){
          if (registrationExpiryDate !== null){
            finalDate = isMoment(localizeDate(registrationExpiryDate)) &&
              localizeDate(registrationExpiryDate).format("DD/MM/YYYY");
          }
        }
             let color = null;
          if (finalDate !== "") {
            const today = moment().startOf("day");
            const targetDate = moment(finalDate, "DD/MM/YYYY");
            if (targetDate.diff(today, "days") <= 0) {
              color = "red"; // Date has passed, set color to red
            } else if (targetDate.diff(today, "days") <= 10) {
              color = "orange"; // 10 or fewer days remaining, set color to orange
            }
          }
          return <span style={{ color }}>{finalDate}</span>;
      },
    },
      {
        title: "Next Service Date",
        dataIndex: "nextServiceDate",
        key: "nextServiceDate",
        render: (text, { nextServiceDetail }) => {
          let finalDate = "N/A";
          if (!!nextServiceDetail) {
            if (nextServiceDetail.nextServiceDate) {
              finalDate = nextServiceDetail.nextServiceDate;
              finalDate = isMoment(localizeDate(finalDate)) && localizeDate(finalDate).format("DD/MM/YYYY");
            } else if (
              nextServiceDetail.estimatedNextServiceDate &&
              nextServiceDetail.estimatedNextServiceDate != "N/A"
            ) {
              let estdDate = nextServiceDetail.estimatedNextServiceDate;

              finalDate =
                isMoment(localizeDate(estdDate)) &&
                localizeDate(estdDate).format("DD/MM/YYYY");
              finalDate = estdDate === "N/A" ? estdDate : finalDate;
            }
          }
          let color = null;
          if (finalDate !== "N/A") {
            const today = moment().startOf("day");
            const targetDate = moment(finalDate, "DD/MM/YYYY");
            if (targetDate.diff(today, "days") <= 0) {
              color = "red"; // Date has passed, set color to red
            } else if (targetDate.diff(today, "days") <= 10) {
              color = "orange"; // 10 or fewer days remaining, set color to orange
            }
          }
          return <span style={{ color }}>{finalDate}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => ("" + a.attr).localeCompare(b.attr),
        render: (text, row) => <ItemStatus status={row.status} />,
      },
    ];
  };

  getColumnsAdmin = () => {
    return getProtectedColumn(
      this.getAdminColumns,
      this.onEditClicked,
      this.confirm,
      "vehicles"
    );
  };
}

export default connect(
  (state) => ({
    auth: state.Auth,
  }),
  {
    deleteVehicle,
    fetchUser,
  }
)(ListVehicle);

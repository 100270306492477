import asyncComponent from "Common/helpers/AsyncFunc";
import ListIncident from "Incident/containers/ListIncident";

const incidentroutes = [
  {
    path: "incidents",
    component: asyncComponent(() => import("Incident/containers/ListIncident")),
    scope: "read:incidents"
  },
  {
    path: "incidents/create",
    component: asyncComponent(() => import("Incident/containers/CreateIncident")),
    scope: "write:incidents"
  },
  {
    path: "incidents/:id",
    exact: true,
    component: asyncComponent(() => import("Incident/containers/RetrieveIncident")),
    scope: "read:incidents"
  },
  {
    path: "incidents/:id/edit",
    exact: true,
    component: asyncComponent(() => import("Incident/containers/EditIncident")),
    scope: "write:incidents"
  },
   {
    path: "incidents/type/:id",
    component: ListIncident,
    scope: "read:incidents"
  },
];

export default incidentroutes;

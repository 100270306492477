import axios from 'axios';
import Auth0 from 'Common/helpers/auth0-custom';

const auth0 = new Auth0();

export default class Api {

  handleError = (error) => {
    switch (error.response.status) {
      case 401:
      case 403:
        //TODO: refactor this code
        auth0.logout();
        window.location.replace('/?msg=authentication failed');
        break;
      case 400:
        let formErrors = error.response.data.error || error.response.data.errors || {};
        return {
          ...formErrors,
          _error: (error.response.data.message
            || (error.response.data.error && error.response.data.error["0"])
            || (error.response.data.errors && error.response.data.errors["0"])
          )
        }
      case 404:
        return {
          _error: "Requested Resource doesnot exist",
        }
      case 500:
      case 503:
      default:
        return {
          _error: "We ran into some problem while executing your request.",
        }
    }
  }

  axiosFunction = (isFormData) => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      responseType: 'json',
      headers: {
        "Content-Type": isFormData ? 'multipart/form-data' : "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + auth0.getAccessToken()
      }
    });
  }

  axiosFunctionWithoutAuthorization = () => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      responseType: 'json',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    });
  }

  postWithoutAuthorization = (url, data) => {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axiosFunctionWithoutAuthorization().post(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  patchWithoutAuthorization = (url, data) => {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axiosFunctionWithoutAuthorization().patch(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  post = (url, data) => {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axiosFunction().post(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  postForm = (url, data) => {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axiosFunction(true).post(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  deleteForm = (url, data) => {
    var self = this;
    return new Promise((resolve, reject) => {
      self.axiosFunction(true).delete(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error))
        });
    });
  }

  get = (url, data) => {
    var self = this;

    return new Promise(function (resolve, reject) {
      self.axiosFunction().get(url, {
        params: data
      })
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  delete = (url, data) => {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axiosFunction().delete(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  update = (url, data) => {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axiosFunction().put(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  patch = (url, data) => {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axiosFunction().patch(url, data)
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(self.handleError(error));
        });
    });
  }

  parallel = (requests) => {

    var self = this;

    let axiosParallelRequestArray = requests.map(request => {
      return this.axiosFunction()[request.method](request.url, request.config);
    });

    return new Promise(function (resolve, reject) {
      axios.all(axiosParallelRequestArray)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(self.handleError(error));
        })


    })
  }

}

const actions =  {
  LIST_DROPDOWN_REQUEST: "LIST_DROPDOWN_REQUEST",
  LIST_DROPDOWN_SUCCESS: "LIST_DROPDOWN_SUCCESS",
  LIST_DROPDOWN_FAILURE: "LIST_DROPDOWN_FAILURE",
  
  listDropdown :  () => ({
    type: actions.LIST_DROPDOWN_REQUEST
  }),

};
export default actions;
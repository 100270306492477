import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Api from 'Common/api/Api';

const api = new Api();

export function* listDropdown() {
  yield takeEvery(actions.LIST_DROPDOWN_REQUEST, function*() {
    try{
      const response = yield call(api.get,'dropdownOptions', {});
      yield put({
        type: actions.LIST_DROPDOWN_SUCCESS,
        response: response.data.results
      });
      return response;
    } catch(error) {
      yield put({
        type: actions.LIST_DROPDOWN_FAILURE,
        error: error
      });
      return error;
    }
  });
}

export default function* rootSaga(){
  yield all([
    fork(listDropdown),
  ]);
}

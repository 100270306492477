import actions from './actions';

const initState = {
  loading: false,
  data: []
};

export default function contactReducer(state = initState, action){
  switch (action.type) {
    case actions.LIST_CONTACT_SUCCESS: 
      return { ...state, data: action.response, loading: false};
    case actions.LIST_CONTACT_REQUEST:
      return {...state, loading: true};
    case actions.LIST_CONTACT_FAILURE:
      return {...state, loading: false};
    default:
      return state;
  }
};

import React, { Component } from "react";
import { Field } from "redux-form";
import { Col, Row, Form } from "antd";
import InputField from "Common/components/InputField";
import ImageUploader from "Driver/components/ImageUploader";
import UploadImageWrapper from "Driver/styles/UploadImageWrapper.style";
import Section from "Common/components/section";

const FormItem = Form.Item;
class UploadWorkSheet extends Component {
  render() {
    return (
        <Section
        label={""}
      >
      <UploadImageWrapper>
        <Row gutter={30}>
          <Col lg={24} md={24}>
            <FormItem>
              <ImageUploader
                onChange={this.props.handleImageChange}
                entityType="Work Sheet"
                fileName="image"
                field="Work Sheet"
                data={this.props.workSheetImage}
                displayName="Upload Attachment"
              />
              <Field
                type="hidden"
                component={InputField}
                name="workSheetImage"
              />
            </FormItem>
          </Col>
        </Row>
      </UploadImageWrapper>
      </Section>
      
    );
  }
}
export default UploadWorkSheet;



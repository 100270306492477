// import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import { takeEvery, call, all, fork} from 'redux-saga/effects';
import actions from './actions';
import  Api  from 'Common/api/Api';

const api = new Api()

export function* retrieveMenu(){
    yield takeEvery(actions.RETRIEVE_MENU,function*({resolve,reject}){
        try{
            const response = yield call(api.get,'dropdownOptions');
            yield call(resolve,response);
        }catch(error){
            yield call(reject,error);
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(retrieveMenu),
    ]);
}
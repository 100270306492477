import React, { Component } from 'react'
import AccessDeniedWrapper from './accessStyle';

export default class componentName extends Component {
  render() {
    return (
      <AccessDeniedWrapper>
        Access Denied
      </AccessDeniedWrapper>
    )
  }
}

import styled from "styled-components";

const radius = "5px"

const Card = styled.div`
  border: 1px solid #dadada;
  box-shadow: rgb(187, 187, 187) 0px 2px 9px;
  -webkit-border-radius: ${radius};
  -moz-border-radius: ${radius};
  border-radius: ${radius};
`;

const CardTitle = styled.div`
  background: #2980B9;
  color: #FFF;
  padding: 7px 15px;
  margin: -1px;
  font-weight: 600;
  font-size: 16px;
  -webkit-border-top-right-radius: ${radius};
  -moz-border-top-right-radius: ${radius};
  border-top-right-radius: ${radius};
  -webkit-border-top-left-radius: ${radius};
  -moz-border-top-left-radius: ${radius};
  border-top-left-radius: ${radius};
`;

const CardBody= styled.div`
  padding: 15px;
  span{
    font-weight: 600;     
  }
`;

const CardFooter = styled.div`
  border-top: 1px solid #dadada;
  padding: 7px 15px;
  background: #dadada;
  margin: -1px;
  span{
    font-weight: 600; 
  }
  -webkit-border-bottom-right-radius: ${radius};
  -moz-border-bottom-right-radius: ${radius};
  border-bottom-right-radius: ${radius};
  -webkit-border-bottom-left-radius: ${radius};
  -moz-border-bottom-left-radius: ${radius};
  border-bottom-left-radius: ${radius};
`;

const BookingAllocationStyled = styled.div`

`;

const EmptyAllocationStyled = styled.div`
  padding: 20px;
  border: 1px solid #d0d0d0;
  background: #f0f0f0;
  display: block;
  margin: auto;
  width: 20%;
  -webkit-border-radius: ${radius};
  -moz-border-radius: ${radius};
  border-radius: ${radius};
`;

const AllocationBox = styled.div`
    margin-bottom: 15px;
    float: left;
    margin-right: 55px;
    width: 45%;
`;

const BookingCreateWrapper = styled.div`
  @media 
  only screen and (max-width: 420px){
    button:not(:first-child) {
      margin: 20px 0 0 10px;
    }
  }
`
const RouteInformationWrapper = styled.div`
  .responsiveButton {
    border-color: #fff !important;
  }
  @media 
  only screen and (max-width: 768px) { 
    .responsiveButton{
      margin: 10px 0 0 10px !important;
  }
`


export {
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  BookingAllocationStyled,
  AllocationBox,
  EmptyAllocationStyled,
  BookingCreateWrapper,
  RouteInformationWrapper
};
import React, { Component } from 'react'


class ErrorDisplay extends Component {
  errorMessage = (error) => {
   return error.map(err => {
      return(
        <li> 
         { err }
        </li>
      )
    })
  }
  render() {
    const { error } = this.props;
    return (
      <div>
        <ul style={{ margin: '0em 0em 0em 1.4em',padding: '0px'}}>
          {this.errorMessage(error)}
        </ul> 
      </div>
    )
  }
}
export default ErrorDisplay;
import styled from "styled-components";

const AdvancedFilterWrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  text-align: left;
  .filterButton {
    position: absolute;
    right: 18%;
    bottom: 10%;
    z-index: 9999;
  }
`;

const FilterHeader = styled.div`
  border-bottom: 1px solid #dadada;
  padding: 15px 15px 5px !important;
  .float-right {
    float: right;
  }
  .close {
    font-size: 25px;
    margin-top: -40px;
    cursor: pointer;
  }
`;

const FormWrapper = styled.div`
  top: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  bottom: 0;
  .form-group {
    margin: 10px 0;
    .applyButton {
      margin-top: 10px;
    }
  }
`;

const AppliedFilterWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  .ant-tag {
    background: #2980b9;
    color: #ffffff;
    margin-bottom: 3px;
    border: 0 !important;
    @media only screen and (max-width: 480px) {
      display: none;
    }

    .anticon-cross {
      color: #ffffff;
      &.hover {
        color: #ffffff;
      }
    }
  }
`;

const TableWrapperStyle = styled.div`
.meter-wrapper tr th:nth-child(2),.meter-wrapper tr td:nth-child(2){
  text-align:right !important;
}
.meter-wrapper {
  .isoTableSearchBox {
    max-width: 100%;
    .ant-input-affix-wrapper {
      max-width: 100%;
      #tableFilterInput {
        border-radius: 4px !important;
      }
    }
  }
}

.grouped-notification {
  background-color: #FEF9F2;
}

@media only screen and (max-width: 760px) {
  .meter-wrapper .tableStyled table ,
  .meter-wrapper .tableStyled tbody,
  .meter-wrapper .tableStyled tr,
  .meter-wrapper .tableStyled th,
  .meter-wrapper .tableStyled td{
    display:revert !important;
  }
  .meter-wrapper tr th:nth-child(2),.meter-wrapper tr td:nth-child(2){
    text-align:none !important;
  }
}
  th {
    padding: 16px 6px;
  }
  th span {
    color: #000000;
    font-family: "Manrope";
    font-weight: 600;
  }
  tr > td {
    padding: 16px 6px !important;
    font-family: "Manrope";
    font-weight: 600;
    color: #676e7d;
    a {
      color: #0067A1;
    }
    i {
      margin-right: 15px;
    }
  }
  tr:hover {
    cursor: pointer;
  }
  .ant-badge-status {
    padding: 5px 7px;
    border-radius: 23px;
    font-size: 14px;
    font-family: "Manrope";
    text-align: center;
    min-width: 80px;
  }
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
  .success.ant-badge-status {
    background-color: #d0e6d7;
    .ant-badge-status-text {
      color: #128239 !important;
    }
  }
  .warning.ant-badge-status {
    background-color: #fbe8cc;
    .ant-badge-status-text {
      color: #ed8a00 !important;
    }
  }
  .error.ant-badge-status {
    background-color: #f4d1d4;
    .ant-badge-status-text {
      color: #c91827 !important;
    }
  }
  .default.ant-badge-status {
    background-color: #f1eded;
    .ant-badge-status-default{
      color:#6f5f5f;
    }
    .ant-badge-status-text {
      color: #776e6e !important;
    }
  }
  .info.ant-badge-status {
    background-color: #fbe8cc;
    color: #ed8a00 !important;
  }
  .searchWrap {
    text-align: right;
    margin-bottom: 20px;
    & > div {
      display: inline-block;
      .advFilterButton {
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        line-height: 20px;
        span.filtercount {
          margin-right: 5px;
          @media only screen and (min-width: 480px) {
            display: none;
          }
        }
      }
    }
  }
  .ant-table-column-sorter {
    width: 32px;
    padding-top: 3px;
  }

  .ant-table-column-sorter-up:after,
  .ant-table-column-sorter-down:after {
    width: 6px !important;
  }
  .ant-table-pagination.ant-pagination {
    float: left;
    display: flex;
  }
  .ant-pagination-item {
    border: none;
  }
  .ant-pagination-item-active {
    background: #128239;
    border-radius: 10px;
    border-color: #128239;
  }
  .ant-pagination-item-active:hover a,
  .ant-pagination-item-active a {
    color: #ffffff !important;
    font-weight: bold;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #128239;
  }
  .ant-pagination-item a {
    color: #808692;
    i {
      margin-right: 0px;
    }
  }
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
    color: #128239;
  }

  @media // only screen and (max-width: 568px) { // #tableFilterInput{
  //     margin-bottom:20px;
  //   } // };


`;

const StickyWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .DateRangePicker {
    width: 100%;
  }

  .Select {
    width: 100%;
  }

  .ant-select-selector {
    width: 100% !important;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;

    .DateRangePicker,
    .Select {
      width: 100%;
      height: auto;
      overflow: visible;
    }
  }

  @media only screen and (max-width: 420px) {
    .DateRangePicker {
      width: 100% !important;
    }
    .ant-select-selector {
      width: 100% !important;
    }
  }
`;

const PowerSearchWrapper = styled.div`
  input {
    border-radius: 5px !important;
  }
  #tableFilterInput {
    border-bottom-left-radius: 7px !important;
    border-top-left-radius: 7px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

export {
  AdvancedFilterWrapper,
  FilterHeader,
  FormWrapper,
  AppliedFilterWrapper,
  TableWrapperStyle,
  StickyWrapperStyle,
  PowerSearchWrapper,
};

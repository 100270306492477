import styled from "styled-components";

const FormWrapper = styled.div`
h1{
    font-size:20px;
    color:#889;
    border-bottom:2px solid #2a8ebb;
    display:inline-block;
}
.form-group{
    margin-bottom:20px;
}
.ant-calendar-picker{
    width:100%;
}
.btn-gray{
    background-color:#999 !important;
    color:#ffffff !important;
}
.ant-form-item-control{
    line-height:35px !important;
}
.border-block{
    border:1px solid #999;
    padding:10px;
}
`;
export default FormWrapper;
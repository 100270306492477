const actions = {
  CREATE_CONTACT_REQUEST:'CREATE_CONTACT_REQUEST',
  RETRIEVE_CONTACT_REQUEST:'RETREIVE_CONTACT_REQUEST',
  LIST_CONTACT_REQUEST:'LIST_CONTACT_REQUEST',
  LIST_CONTACT_SUCCESS:'LIST_CONTACT_SUCCESS',
  LIST_CONTACT_FAILURE:'LIST_CONTACT_FAILED',
  DELETE_CONTACT_REQUEST:"DELETE_CONTACT_REQUEST",
  UPDATE_CONTACT_REQUEST:"UPDATE_CONTACT_REQUEST",

  CREATE_COMPANY_TELEMATICS_REQUEST:'CREATE_COMPANY_TELEMATICS_REQUEST',
  UPDATE_COMPANY_TELEMATICS_REQUEST:'UPDATE_COMPANY_TELEMATICS_REQUEST',
  RETRIEVE_COMPANY_TELEMATICS_REQUEST:'RETRIEVE_COMPANY_TELEMATICS_REQUEST',
  DELETE_COMPANY_TELEMATICS_REQUEST:'DELETE_COMPANY_TELEMATICS_REQUEST',

  createContact:(data,context,resolve,reject) => ({
      type:actions.CREATE_CONTACT_REQUEST,
      data,
      context,
      resolve,
      reject
  }),
  retrieveContact:(id,resolve,reject) => ({
      type:actions.RETRIEVE_CONTACT_REQUEST,
      id,
      resolve,
      reject
  }),
  deleteContact:(id,resolve,reject) => ({
    type:actions.DELETE_CONTACT_REQUEST,
    id,
    resolve,
    reject
  }),
  updateContact:(id,data,resolve,reject) => ({
    type:actions.UPDATE_CONTACT_REQUEST,
    id,
    data,
    resolve,
    reject
  }),
  patchContact:(id,data,resolve,reject) => ({
    type:actions.PATCH_CONTACT_REQUEST,
    id,
    data,
    resolve,
    reject
  }),
  listContact: () => ({
    type: actions.LIST_CONTACT_REQUEST
  }),
  createCompanyTelematics:(companyId,data,resolve,reject) => ({
    type: actions.CREATE_COMPANY_TELEMATICS_REQUEST,
    companyId,
    data,
    resolve,
    reject
  }),
  updateCompanyTelematics:(companyId,data,resolve,reject) => ({
    type: actions.UPDATE_COMPANY_TELEMATICS_REQUEST,
    companyId,
    data,
    resolve,
    reject
  }),
  getCompanyTelematics:(companyId,resolve,reject) => ({
    type: actions.RETRIEVE_COMPANY_TELEMATICS_REQUEST,
    companyId,
    resolve,
    reject
  }),
  deleteCompanyTelematics:(companyId,platform,resolve,reject) => ({
    type: actions.DELETE_COMPANY_TELEMATICS_REQUEST,
    companyId,
    platform,
    resolve,
    reject
  }),
};
export default actions;

import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'Passenger/redux/actions';
import { push } from 'react-router-redux';
import  Api  from 'Common/api/Api';

const api = new Api();

export function* createPassenger() {
  yield takeEvery(actions.CREATE_PASSENGER_REQUEST, function*({data,context,resolve,reject}) {
    try{
      const response = yield call(api.post,'passengers',data);
      yield call(resolve,response);
      if(context === 'save'){
        yield(put(push('/passengers/'+response.data.id)));
      }
     } 
     catch(error) {
      yield call(reject, error);     
    }
  });
}

export function* retrievePassenger() {
  yield takeEvery(actions.RETRIEVE_PASSENGER_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'passengers/' + id, {});
        yield call(resolve,response);
    } 
    catch(error) {
      yield call(reject,error);
    }
  });
}

export function* updatePassenger() {
  yield takeEvery(actions.UPDATE_PASSENGER_REQUEST, function*({data,id,resolve,reject}) {
    try{
      const response = yield call(api.update,'passengers/' + id, data);
      yield call(resolve,response);
      yield put(push('/passengers/' + response.data.id));
    } 
    catch(error) {
      yield call(reject, error);
    }
  });
 }

export function* deletePassenger(){
  yield takeEvery(actions.DELETE_PASSENGER_REQUEST, function*({id,resolve,reject}){
    try{
      const response = yield call(api.delete,'passengers/'+ id, {})
      yield call(resolve,response);
      yield put(push('/passengers'));
    }catch(error){
      yield call(reject,error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createPassenger),
    fork(retrievePassenger),
    fork(updatePassenger),
    fork(deletePassenger),
  ]);
}

import React from 'react';
import { Field, FormSection} from 'redux-form';
import { Row, Col, Form, Icon } from 'antd';

import IntlMessages from 'Common/components/intlMessages';
import Button from 'Common/components/button';
import LocationSelect from 'Location/components/form/LocationSelect';
import Protected from "Common/components/authorization/Protected";

import StopWrapper from 'Booking/styles/stopWrapper.style';

// const required = value =>  value ? undefined : 'This field is required'
const keyRequired = value => {
  return value && value.key ? undefined : 'This field is required'
}
const FormItem = Form.Item;
const RouteStop = ({fields, meta:{error, submitFailed}, showLocationModal, excludedLocations}) => (
  <StopWrapper>
    <Row>
      <h3><IntlMessages id="forms.label.stops"/></h3>
    </Row>
    {fields.map((stop, index) => (
      <Row gutter={48}>
        <Col md={10}>
          <FormItem>
            <label><IntlMessages id="forms.label.location"/></label>
            <Row gutter={20}>
              <Col lg={16} md={24}>
                <FormSection name={`${stop}.location`}>
                  <Field validate={keyRequired}
                    defaultValue={{key:""}}
                    name="id"
                    component={LocationSelect}
                    labelInValue={true}
                    type="stop"
                    excludes={excludedLocations}
                    />
                </FormSection>
              </Col>
              <Protected scope="write:locations">
                <Col lg={6} md={24}>
                  <Button onClick={()=>(showLocationModal(`${stop}.location`,"stop"))} className="btn-gray responsiveButton"><Icon type="plus"/><IntlMessages id="forms.button.newlocation"/></Button>
                </Col>
              </Protected>
            </Row>
          </FormItem>
        </Col>
        <Col md={2}>
          <FormItem>
            <br/>
            <Icon type="close" style={{cursor:'pointer'}} onClick={() => fields.remove(index)} />
          </FormItem>
        </Col>
      </Row>
    ))}
    <Button style={{marginTop:'5px'}} type="default" onClick={() => fields.push({})}>New Stop</Button>
    {submitFailed && error && <span>{error}</span>}
  </StopWrapper>
);

export default RouteStop;

import asyncComponent from "Common/helpers/AsyncFunc";
import ListMaintenance from "Maintenance/containers/ListMaintenance";

const maintenanceroutes = [
  {
    path: "maintenance",
    component: asyncComponent(() => import("Maintenance/containers/ListMaintenance")),
    scope: "read:users"
  },
  {
    path: "service/create",
    component: asyncComponent(() => import("Maintenance/containers/CreateService")),
    scope: "write:medias",
  },
  {
      path: "maintenance/:id/edit",
      component: asyncComponent(() => import("Maintenance/containers/EditService")),
      scope: "write:vehicles"
   }, 
    {
      path: "repair/create",
      component: asyncComponent(() => import("Maintenance/containers/CreateRepair")),
      scope: "write:medias",
    },
    {
        path: "maintenance/type/:type",
        component: ListMaintenance,
        scope: "read:vehicles"
    }

];

export default maintenanceroutes;

import React from 'react';
import SectionField from 'Common/styles/sectionField.style';

const Section=({label, children}) => (
  <SectionField>
		 {label}
		 {children}
	</SectionField> 
);

export default Section;
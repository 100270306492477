import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Tag, DatePicker, Icon, Select } from 'antd';
import moment from 'moment';

// import { StickyWrapperStyle } from 'Common/components/Table/styles/index.style';

const { RangePicker } = DatePicker;
const { Option } = Select;

class StickyPeriodFilter extends Component {

  state = {
    inputVisible: false,
    inputValue: '',
    displayStartDate: moment().format("DD/MM/YYYY"),
    displayEndDate: moment().format("DD/MM/YYYY")
  };

  showInput = () => {
    this.setState({ inputVisible: true });
  }

  handleInputChange = value => {
    // console.log('value',value);
    const { dispatch } = this.props;
    const startDate = value[0].format("YYYY-MM-DD")
    const endDate =  value[1].format("YYYY-MM-DD")
   this.props.storeDaterange && dispatch(this.props.storeDaterange(startDate, endDate));
    this.setState({
      displayStartDate: startDate,
      displayEndDate: endDate,
      inputVisible: false
    });
    this.props.stickyFilter(value);
  }

  handleEndOpenChange = value => {
    this.setState({ inputVisible: value })
  }

  renderRangePickerDate = () => {
    const { selectedRangeStart, selectedRangeEnd } = this.props;
    const { displayStartDate, displayEndDate } = this.state;
    if(selectedRangeStart && selectedRangeEnd){
      return selectedRangeStart + " - " + selectedRangeEnd
    }
    else {
      return displayStartDate + " - " + displayEndDate
    }
  }
  handleRangeSelect =(value) => {
    let startDate = moment();
    const endDate= moment()
    switch (value) {

        case 'today':
            startDate =moment();
            break;
        case 'yesterday':
            startDate = moment().subtract(1, "days")
            break;
        case 'week':
            startDate = moment().subtract(7, "days")
            break;
        case 'month':
            startDate = moment().subtract(30, "days")
            break;
    }
    this.handleInputChange([startDate,endDate])

  }
  render() {
    const {inputVisible } = this.state;
    return (
      <>
        {inputVisible && (
          <RangePicker
            open
            onChange={this.handleInputChange}
            onOpenChange={this.handleEndOpenChange.bind(this)}
            // value={[moment(selectedRangeStart), moment(selectedRangeEnd)]}
          />
        )}
        {!inputVisible && (
          <Tag 
           onClick={this.showInput}
          >
            {this.renderRangePickerDate()} <Icon type="edit" />
          </Tag>
        )}
        <Select
          defaultValue="today"
          style={{ width: 120 }}
          onChange={this.handleRangeSelect}
        >
          <Option value="month">Last Month</Option>
          <Option value="week">This Week</Option>
          <Option value="today">Today</Option>
          <Option value="yesterday">Yesterday</Option>
        </Select>
      </>
    );
  }
}


export default connect(
    // state => ({
    // selectedRangeStart: state.Booking.selectedRange.start,
    // selectedRangeEnd: state.Booking.selectedRange.end,

    // })
)(StickyPeriodFilter);
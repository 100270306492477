
const actions = {

    FETCH_DOCUMENT_LIST_REQUEST:"FETCH_DOCUMENT_LIST_REQUEST",
    FETCH_DOCUMENT_LIST_SUCCESS:"FETCH_DOCUMENT_LIST_SUCCESS",
    FETCH_DOCUMENT_LIST_FAIL:"FETCH_DOCUMENT_LIST_FAIL",

    fetchDocumentList:(data,resolve, reject) => (console.log("fetch document list called"),{
        type:actions.FETCH_DOCUMENT_LIST_REQUEST,
        data,
        resolve,
        reject
      }),

}
export default actions;


import actions from './actions';
const initialState = {
  assets:[],
  loading:false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ASSETTYPE_LIST_REQUEST:
      return {
        ...state,
        loading:true,
      }
    case actions.FETCH_ASSETTYPE_LIST_SUCCESS:
      return {
        ...state,
        assets:action.response,
        loading:false,
      }
    case actions.FETCH_ASSETTYPE_LIST_FAIL:

      return {
        
        ...state,
        loading:false,
      }
    default:
      return state;
  }
}

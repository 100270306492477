import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";

export default class Auth {
  userProfile;
  tokenRenewalTimeout;
  requestedScopes = "openid profile offline_access";
  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH_CALLBACK_URL,
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
    responseType: "token id_token",
    scope: this.requestedScopes,
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.userHasScopes = this.userHasScopes.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
  }

  login(username, password) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.auth0.client.login(
        {
          realm: process.env.REACT_APP_AUTH_DB_CONNECTION_NAME,
          username,
          password,
        },
        (err, authResult) => {
          if (err) {
            reject(err);
          } else if (authResult && authResult.accessToken && authResult.idToken) {
            self.setSession(authResult);
            resolve(authResult.idToken);
          } else {
            reject("Invalid Token");
          }
        }
      );
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    // schedule a token renewal
    this.scheduleRenewal();
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    const delay = expiresAt - Date.now();
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    }
  }

  renewToken() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(result);
      }
    });
  }

  getAccessToken() {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      return null;
      //throw new Error('No access token found');
    }
    return accessToken;
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  getProfileFromIdToken(idToken) {
    return jwt_decode(idToken);
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  userHasScopes(scopes) {
    const grantedScopes = (JSON.parse(localStorage.getItem("scopes")) || "").split(" ");
    return scopes.every((scope) => grantedScopes.includes(scope));
  }
  getCurrentUserId = () => {
    const access_token = localStorage.getItem("access_token");
    const sub = jwt_decode(access_token).sub;
    const extractId = sub.split("|");
    return extractId[1];
  };
  userHasScope(requiredScope) {
    if (!requiredScope) {
      return true;
    }
    const access_token = localStorage.getItem("access_token");

    // FIXME: this is needed to be done manually untile Auth0 migration is completed for dev & prod
    // TODO: @zen for test
    // const scopes = jwt_decode(access_token).permissions;
    // const grantedScopes = Array.isArray(scopes) ? scopes : (scopes || "").split(" ");
    // TODO: @zen for dev & prod
    
    let scopes;
    let grantedScopes;
    if (process.env.REACT_APP_DEPLOYMENT_ENV==="test") {
      scopes = jwt_decode(access_token).permissions;
      grantedScopes = Array.isArray(scopes) ? scopes : (scopes || "").split(" ");
    } else {
      scopes = jwt_decode(access_token).scope;
      grantedScopes = (scopes || "").split(" ");
    }

    const userHasScope =
      grantedScopes.filter((scope) => {
        let pattern = new RegExp("^" + requiredScope + ":");
        return scope === requiredScope || pattern.test(scope);
      }, requiredScope).length > 0;
    if (userHasScope) {
      this.scheduleRenewal();
    }
    return userHasScope;
  }

  hasWebLoginAccess = () => {
    const access_token = localStorage.getItem("access_token");
    const scopes = jwt_decode(access_token).scope;
    const regExp = new RegExp("login:web");
    return regExp.test(scopes);
  };

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("userData");
    this.userProfile = null;
    clearTimeout(this.tokenRenewalTimeout);
    // navigate to the home route
    //this.props.history.push('/home');
  }
}

import React, { Component } from 'react'
import {Input} from 'antd';
import { PowerSearchWrapper } from './styles/index.style';
import { connect } from 'react-redux';
import _ from 'lodash';

class PowerSearch extends Component {
   constructor(props){
     super(props);
     this.state = {
       search : props.value
     }
     this.debouncedSearch = _.debounce(this.onSearch, 700);
   }

  onInputChange = (key,event) => {
    const searchValue = event.target.value;
    this.setState({ [key]: event.target.value });
    this.debouncedSearch(searchValue);
  }

  onSearch = (value) => {
      const { dispatch } = this.props;
      this.props.onChange(value);
      this.props.storePowerSearchValue && dispatch(this.props.storePowerSearchValue(value))
  }

  render() {
    return (
      <PowerSearchWrapper className="isoTableSearchBox" style={{textAlign: 'right'}}>

         <Input
          id="tableFilterInput"
          placeholder={ this.props.placeholderValue || "Search name" }
          value={this.state.search}
          onChange={(event) => this.onInputChange("search", event)} 
          onPressEnter={() => this.onSearch(this.state.search)} 
          style={{width:'200px',marginBottom: '10px'}}
          prefix={ <i className="ri-search-line" style={{color:"#808692"}}></i>}
        />
        
      </PowerSearchWrapper>
    )
  }
}
export default connect(
null
)(PowerSearch);

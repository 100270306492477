import asyncComponent from "Common/helpers/AsyncFunc";

const locationroutes = [
    {
      path: "locations/create",
      component: asyncComponent(() => import("Location/containers/CreateLocation")),
      scope: "write:locations"
    },
    {
      path: "locations/:id/edit",
      component: asyncComponent(() => import("Location/containers/EditLocation")),
      scope: "write:locations"
    },
    {
      path: "locations/:id*",
      component: asyncComponent(() => import("Location/containers/ListLocation")),
      scope: "read:locations"
    },
  ];

export default locationroutes;

import asyncComponent from "Common/helpers/AsyncFunc";

const ncrroutes = [
    {
        path: 'ncr',
        component: asyncComponent(() => import ('NCR/containers/ListNCR')),
        scope: 'read:users'
    },
    {
        path:'ncr/create',
        component: asyncComponent(() => import ('NCR/containers/CreateNCR')),
        scope: 'write:users'
    },
    {
        path:"ncr/:id",
        component: asyncComponent(() => import ('NCR/containers/RetrieveNCR')),
        scope:'read:users'
    },
    {
        path: "ncr/:id/edit",
        component: asyncComponent(() => import("NCR/containers/EditNCR")),
        scope: "write:users"
    }
]

export default ncrroutes;

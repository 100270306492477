

import moment from "moment";


export function generateScheduledBookingsQuery() {


    let startDate = moment().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');

    return {

        [`excludes[status][]`]: 'active',
        [`period[start]`]: startDate,
        [`period[end]`]: endDate
    }

}


export const mockApiUrls = {

    scheduledBookings: "https://jsonblob.com/api/3296023b-9c6d-11e8-b69d-9bada28ab5c6",
    geoFences: "https://jsonblob.com/api/327c5624-9c61-11e8-b69d-4bb229ac6bc9",
    traces: "https://jsonblob.com/api/23b862ba-9c60-11e8-b69d-2517a2244763",
    real: "baseURl/shadows"

}


export function getTracingArrayFromBookingAllocations(bookings = []) {


    let tracingArray = [];

    bookings.forEach(booking => {



        if (Array.isArray(booking.allocations)) {

            let allocationTraceArray = booking.allocations.map(allocation => {

                if (allocation.trackingId && (allocation.status === "start" || allocation.status === "stop")) {

                    return allocation.trackingId
                }
            }).filter(trackingId => trackingId);

            tracingArray = [...tracingArray, ...allocationTraceArray];
        }
    });
    return tracingArray;


}


export function mapTracesToTrackingId(trackingData = [], trackingIdArray = []) {

    let traces = {};

    trackingData.forEach((trace, index) => {
        traces[trackingIdArray[index]] = trace.data.data[0]
    });

    return traces;



}
const actions = {
  CREATE_TOOLBOX_REQUEST: "CREATE_TOOLBOX_REQUEST",
  RETRIEVE_TOOLBOX_REQUEST: "RETRIEVE_TOOLBOX_REQUEST",
  FETCH_TOOLBOX_LIST_REQUEST: "FETCH_TOOLBOX_LIST_REQUEST",
  FETCH_TOOLBOX_LIST_SUCCESS: "FETCH_TOOLBOX_LIST_SUCCESS",
  FETCH_TOOLBOX_LIST_FAIL: "FETCH_TOOLBOX_LIST_FAIL",
  DELETE_TOOLBOX_REQUEST: "DELETE_TOOLBOX_REQUEST",
  DELETE_TOOLBOX_SUCCESS: "DELETE_TOOLBOX_SUCCESS",
  DELETE_TOOLBOX_FAIL: "DELETE_TOOLBOX_FAIL",
  UPDATE_TOOLBOX_REQUEST: "UPDATE_TOOLBOX_REQUEST",
  DOWNLOAD_TOOLBOX_PDF_REQUEST: "DOWNLOAD_TOOLBOX_PDF_REQUEST",

  createToolbox: (data, context, resolve, reject) => ({
    type: actions.CREATE_TOOLBOX_REQUEST,
    data,
    context,
    resolve,
    reject,
  }),
  retrieveToolbox: (id, resolve, reject) => ({
    type: actions.RETRIEVE_TOOLBOX_REQUEST,
    id,
    resolve,
    reject,
  }),
  fetchToolboxList: (resolve, reject) => ({
    type: actions.FETCH_TOOLBOX_LIST_REQUEST,
    resolve,
    reject,
  }),

  deleteToolbox(id, resolve, reject) {
    return {
      type: actions.DELETE_TOOLBOX_REQUEST,
      id,
      resolve,
      reject,
    };
  },
  fetchToolboxPDF(id, resolve, reject) {
    return {
      type: actions.DOWNLOAD_TOOLBOX_PDF_REQUEST,
      id,
      resolve,
      reject,
    };
  },
};
export default actions;

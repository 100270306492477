import asyncComponent from "Common/helpers/AsyncFunc";

const fatigueoutes = [
  {
    path: "fatigue",
    component: asyncComponent(() => import("Fatigue/containers/fatigue")),
    scope: "read:users",
  },
  {
    path: "fatigue/:driverId",
    component: asyncComponent(() => import("Fatigue/containers/fatigueDetails")),
    scope: "read:users",
  },
  //   {
  //     path: "fatigue/:key",
  //     component: asyncComponent(() => import("Fatigue/containers/RetrieveFatigue")),
  //     scope: "read:users",
  //   },
  //   {
  //     path: "fatigue/:id/:date",
  //     component: asyncComponent(() => import("Fatigue/containers/FatigueActivity")),
  //     scope: "read:users",
  //   },
];

export default fatigueoutes;

import React, { Component } from 'react'
import { Tabs } from 'antd';
import PageTopFilter from 'User/styles/pagetopfilter.style';

const TabPane = Tabs.TabPane;

class QuickFilter extends Component {
  render() {
    const { quickFilterElements, quickFilterValue } = this.props;
    return (
      <PageTopFilter>
        <Tabs defaultActiveKey={quickFilterElements.defaultValue} activeKey={quickFilterValue} onChange={this.props.onChange}>
        { quickFilterElements && quickFilterElements.options.map((option) => {
            return(
              <TabPane tab={option.tab} key={option.key}></TabPane>
            )
          }) 
        }
        </Tabs>
      </PageTopFilter>
    )
  }
}
export default QuickFilter;
import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'Maintenance/redux/actions';
import {push} from 'react-router-redux';
import  Api  from 'Common/api/Api';

const api = new Api();

export function* createMaintenance(){
    yield takeEvery(actions.CREATE_MAINTENANCE_REQUEST,function*({id, data,context, resolve,reject}){
        try{
            const response = yield call(api.post,'vehicles/'+id+'/maintenance',data);
            yield call(resolve,response);
            if(context === "save"){
                 yield put(push("/maintenance/"+response.data.id))
        }
        }catch(error){
            yield call(reject,error);
        }
    });
}

export function* updateMaintenance() {
    yield takeEvery(actions.UPDATE_MAINTENANCE_REQUEST, function*({vid,id, data, resolve,reject}) {
        try{
            const response = yield call(api.update,'vehicles/'+vid+'/maintenance/' + id, data);
            yield call(resolve,response);
            yield put(push('/maintenance/' + response.data.id));
        } catch(error) {
            yield call(reject, error);
        }
    });
}


export function* retrieveMaintenance() {
  yield takeEvery(actions.RETRIEVE_MAINTENANCE_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'maintenance/' + id, {});
        yield call(resolve,response);
    } catch(error) {
        yield call(reject,error);
    }
  });
}


export function* deleteMaintenance() {
  yield takeEvery(actions.DELETE_MAINTENANCE_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.delete,'maintenance/'+ id, {})
      if(resolve){
        yield call(resolve,response);
        yield put(push('/maintenance/type/all'));
      }
    } catch(error) {
        yield call(reject,error);
    }
  });
}

export function* createService() {
    yield takeEvery(actions.CREATE_SERVICE_REQUEST, function*({id, data,context,resolve,reject}) {
        try{
            const response = yield call(api.post,'maintenance/'+id+'/service',data);
            yield call(resolve,response);
            if(context === 'save'){
                yield put(push('/maintenance'));
            }
        }
        catch(error) {
            yield call(reject, error);
        }
    });
}

export function* retrieveService() {
  yield takeEvery(actions.RETRIEVE_SERVICE_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get,'service/' + id, {});
        yield call(resolve,response);
    } catch(error) {
        yield call(reject,error);
    }
  });
}

export function* fetchServiceList(){
    yield takeEvery(actions.FETCH_SERVICE_LIST_REQUEST, function*({resolve, reject}){
        try{
            const response = yield call(api.get, "service", {});
            if(resolve){
                yield call(resolve, response);
            } else {
                yield put({
                    type:actions.FETCH_SERVICE_LIST_SUCCESS,
                    response:response.data.results
                });
            }
        } catch (error){
            if(reject){
                yield call(reject, error);
            } else {
                yield put({
                    type:actions.FETCH_SERVICE_LIST_FAIL,
                    response:error
                });
            }
        }
    });
}
export default function* rootSaga() {
    yield all([
        fork(createMaintenance),
        fork(retrieveMaintenance),
        fork(updateMaintenance),
        fork(deleteMaintenance),
        fork(createService),
        fork(retrieveService),
        fork(fetchServiceList)
    ]);
}

import React from 'react';
import { Route, Redirect, Switch} from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import App from 'App/containers/App';
import asyncComponent from 'Common/helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
          <Route
            exact
            path={'/'}
            component={asyncComponent(() => import('Public/containers/SignIn'))}
          />
          <Route
            exact
            path={'/signin'}
            component={asyncComponent(() => import('Public/containers/SignIn'))}
          />
          <Route
            exact
            path={"/forgot-password"}
            component={asyncComponent(() =>
              import("Public/containers/ForgotPasswordEmailValidation")
            )}
          />
          <Route
            exact
            path={"/forgot-password/:token"}
            component={asyncComponent(() =>
              import("Public/containers/SetForgotPassword")
            )}
          />
          <Route
            exact
            path={"/set-password"}
            component={asyncComponent(() =>
              import("Public/containers/SetPasswordFirstTime")
            )}
          />
          <RestrictedRoute
            component={App}
            isLoggedIn={isLoggedIn}
          />
        </Switch>
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.profile !== null
}))(PublicRoutes);

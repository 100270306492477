import React, { Component } from 'react';
import HereMap from 'Common/components/HereMap/HereMap';
import MarkerLocation from 'Common/components/HereMap/MarkerLocation';

const normalizeGeoLocation = (coordinates) => ( coordinates ? JSON.parse(coordinates) : null )

class LocationMapView extends Component {
	constructor(props){
		super(props);
		this.state={ 
			zoom: 16
		}
	}

	render() {
		const { geofenceRadius,type } = this.props;
		const { zoom } = this.state;
		const geolocation = normalizeGeoLocation(this.props.geolocation);
		return (
			<div className="location-map">
				<div style={{ width: '100$',height: '500px'}}>
					<HereMap
						zoom= { zoom }
						height={500}
					>
							<MarkerLocation
							 geofenceRadius={parseInt(geofenceRadius)} 
							 lat={geolocation && geolocation.lat } 
							 lng={ geolocation && geolocation.lng }
							 type={type}
							 />	
					</HereMap>
				</div>
			</div>
		)
	}
}


export default LocationMapView;
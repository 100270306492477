import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Spin, Alert} from 'antd';
import Select,{SelectOption} from 'Common/components/select';

import SelectFieldWrapper from 'Common/styles/selectfield.style';

import actions from "Location/redux/actions";
const { fetchLocationLists } = actions;

class LocationSelect extends Component {
  state = {
    data:[],
    fetching: false
  }

  getFilter = () => (this.props.type && {filter: this.props.type}) || {} ;


  getLocationBySearch=(value) => {
    let filter=this.getFilter();
    if(value && value.length>2){
      filter={
        ...filter,
        search:value
      }
      this.fetchLocation(filter);
    }
  }

  fetchLocation = (filter) => {
    this.setState({fetching: true});
    new Promise((resolve,reject) => {
      this.props.fetchLocationLists(filter,resolve,reject);
    }).then((locationResponse)=>{
      this.setState({
        fetching: false,
        data: locationResponse.data.results
      })
    },(error)=>{
      console.log("Error",error);
    },this);
  }

  componentWillMount = () => {
    if(!this.props.disabled){
      // this.fetchLocation();
    }
  }

  locationExists = id => (
    this.state.data.filter(location => location.id === id ,id).length > 0
  )

  componentWillReceiveProps = (nextProps) => {
    if(!this.props.disabled && nextProps.input.value
      && nextProps.input.value.key
      && (!this.props.input.value || nextProps.input.value.key !== this.props.input.value.key)
      && !this.locationExists(nextProps.input.value.key)
    ) {
      // this.fetchLocation(this.getFilter());
    }
  }

  getLocationListByContext = () => {
    const { excludes, input} = this.props;
    const { data } = this.state;
    const activeLocation = data.filter((location)=>(
      location.status==="active"
    ))
    if(!excludes){
      return activeLocation;
    }else{
      const selectedLocationId = input && input.value && input.value.key || "";
      return activeLocation.filter( location => (
         location.id === selectedLocationId ||  !excludes.includes(location.id)
       ))
      }
    }


  render(){
    const {defaultValue, input, meta: { touched, error }, type, ...custom } = this.props;
    const {fetching} = this.state;
    const hasError = touched && error !== undefined;
    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={fetching}>
          <Select
            defaultValue={defaultValue}
            {...input}
            {...custom}
            className="inputField ant-select-lg"
            value={input.value || defaultValue}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSearch={this.getLocationBySearch}
          >
            <SelectOption value="">Select Location</SelectOption>
            {this.getLocationListByContext().map((location,index)=>(
              <SelectOption key={index} value={location.id}>{location.name}</SelectOption>
            ))}
          </Select>
          { hasError &&
            <Alert
            type="error"
            message={error}
            banner />
          }
        </Spin>
      </SelectFieldWrapper>
   );
  }
}

export default connect(
  null,
  { fetchLocationLists }
)(LocationSelect);

import React from 'react';
import { DatePicker, Alert } from 'antd';
import moment from 'moment';

import ErrorDisplay from 'Common/components/error'; 

const DateTimePicker = ({   input: { onChange, value}, meta: { touched, error }, formType, ...custom}) => {
  const hasError = touched && error !== undefined;
  return (
    <div>
      <DatePicker
        showTime = {{format:"HH:mm"}}
        format={"DD/MM/YYYY HH:mm"}
        onChange={onChange}
        value={!value ? value : moment(value)}
        {...custom}
      />
      {
        hasError &&
        <Alert
        className="alert"
        type="error"
        message={ Array.isArray(error) && error.length > 1 ? <ErrorDisplay error={error}/> : error }
        banner />
      }
  </div>
  );
}

export default DateTimePicker;

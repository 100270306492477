import styled from "styled-components";

const StopWrapper = styled.div`
  margin-bottom:24px;
  border:1px solid #d9d9d9;
  border-radius: 4px;
  padding:15px;
  h3{
    font-size:18px;
  }
  .anticon-calendar{
    margin-right:10px;
  }
 `;

export default StopWrapper;
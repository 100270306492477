import { takeEvery, call, all, fork } from 'redux-saga/effects';
import moment from 'moment';

import actions from './actions';
import Api from 'Common/api/Api';
import Api2 from 'Common/api/Api2'

const api = new Api();
const api2 = new Api2();

function* fetchBookingSummary() {
  yield takeEvery(actions.FETCH_BOOKING_SUMMARY_REQUEST, function* ({ resolve, reject }) {
    try {
      const startDate = moment().format('YYYY-MM-DD') + " 00:00:00";
      const endDate = moment().format('YYYY-MM-DD') + " 23:59:59";
      const response = yield call(api.get, `bookings-summary?period[0][start]=${moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss')}&period[0][end]=${moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss')}`, {})
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error)
    }
  })
}

function* fetchDriverVehicleSummary() {
  yield takeEvery(actions.FETCH_DRIVER_VEHICLE_SUMMARY_REQUEST, function* ({ url, resolve, reject }) {
    try {
      const startDate = moment().format('YYYY-MM-DD') + " 00:00:00";
      const endDate = moment().format('YYYY-MM-DD') + " 23:59:59";
      const response = yield call(api.get, `${url}-summary?period[0][start]=${moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss')}&period[0][end]=${moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss')}`, {})
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error)
    }
  })
}

function* fetchPassangerSummary() {
  yield takeEvery(actions.FETCH_PASSENGER_SUMMARY_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "passengerSummary", {})
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error)
    }
  })
}

const getUrl = () => {
  let url = ['bookings-summary?'];
  for (let i = 0; i < 7; i++) {
    const startDate = moment().day(i).format('YYYY-MM-DD') + " 00:00:00";
    const endDate = moment().day(i).format('YYYY-MM-DD') + " 23:59:59";
    url.push(`period[${i}][start]=${moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss')}&period[${i}][end]=${moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss')}`)
  }
  return url.join("&");
}

function* fetchBookingStatistic() {
  yield takeEvery(actions.FETCH_BOOKING_STATISTIC_REQUEST, function* ({ resolve, reject }) {
    try {
      const url = yield call(getUrl);
      const response = yield call(api.get, url, {})
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error)
    }
  })
}


// function* fetchDashboardData() {
//   yield takeEvery(actions.FETCH_DASHBOARD_DATA, function* ({ args,resolve, reject }) {
//     try {
//       const response = yield call(api.get, '/dashboard', args)
//       yield call(resolve, response);
//     } catch (error) {
//       yield call(reject, error)
//     }
//   })
// }

function* fetchDashboardData() {
  yield takeEvery(actions.FETCH_DASHBOARD_DATA, function* ({ args,resolve, reject }) {
    try {
      const response = yield call(api.get, '/dashboard-insights', args)
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error)
    }
  })
}

// function* fetchMaintenanceData() {
//   yield takeEvery(actions.FETCH_MAINTENANCE_DATA, function* ({ resolve, reject }) {
//     try {
//       const response = yield call(api.get, '/maintenance/upcoming')
//       yield call(resolve, response);
//     } catch (error) {
//       yield call(reject, error)
//     }
//   })
// }

function* fetchAlertsData() {
  yield takeEvery(actions.FETCH_ALERTS_DATA, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, '/upcoming-alerts')
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error)
    }
  })
}

function* fetchDashboardEvents() {
  yield takeEvery(actions.FETCH_DASHBOARD_EVENTS, function* ({startDate, endDate, resolve, reject }) {
    try {
      const response = yield call(api2.get, '/dashboard/events?startDate='+startDate+'&endDate='+endDate)
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error)
    }
  })
}

export default function* rootSagas() {
  yield all([
    fork(fetchBookingSummary),
    fork(fetchDriverVehicleSummary),
    fork(fetchPassangerSummary),
    fork(fetchBookingStatistic),
    fork(fetchDashboardData),
    fork(fetchAlertsData),
    fork(fetchDashboardEvents)
  ]);
}

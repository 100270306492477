const isStrongPassword = password => {
  const strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()\-_+={}[\]|\\:;\"'<,>\.?\/])(?=.{8,})/;
  return strongPassword.test(password);
}

const validate = values => {
  const errors = {};
  if (!values.username || values.username.trim() === '') {
    errors.username = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
    errors.username = 'Invalid email address';
  }

  if (!values.userStatus || values.userStatus.trim() === '') {
    errors.userStatus = 'This field is required'
  }
  if (!values.lastName || values.lastName.trim() === '') {
    errors.lastName = 'This field is required'
  }

  if (!values.firstName || values.firstName.trim() === '') {
    errors.firstName = 'This field is required'
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = 'This field is required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'This field is required'
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = "Valid 10 digit mobile number with no spaces or international dialing code"
  } else if (!/^0[0-9]\d{8}$/g.test(values.phoneNumber)) {
    errors.phoneNumber = 'Invalid phone number'
  }
  if (!values.role || values.role.trim() === '') {
    errors.role = 'This field is required'
  }
  if (!values.organization || values.organization.trim() === '') {
    errors.organization = 'This field is required'
  }
  if (!values.name || values.name.trim() === '') {
    errors.name = 'This field is required'
  }
  if (!values.address || values.address.trim() === '') {
    errors.address = 'This field is required'
  }
  if (!values.code || values.code.trim() === '') {
    errors.code = 'This field is required'
  }
  if (!values.code || (!/^[a-zA-Z0-9]*$/i.test(values.code)) || values.code.length > 6 || values.code.length < 3) {
    errors.code = 'Location ID should be maximum of 6 and minimum of 3 alpha-numeric characters'
  }
  if (!values.type || values.type.trim() === '') {
    errors.type = 'This field is required'
  }
  if (!values.status || values.status.trim() === '') {
    errors.status = 'This field is required'
  }

  if (!values.oldpassword || values.oldpassword.trim() === '') {
    errors.oldpassword = 'This field is required'
  }

  if (!values.password || values.password.trim() === '') {
    errors.password = 'This field is required'
  } else if (values.password.length < 8) {
    errors.password = "Password is too short";
  } else if (!isStrongPassword(values.password)) {
    errors.password = "Password must contain at least one uppercase character, one lowercase character, one special character and one number";
  }

  if (!values.confirmpassword || values.confirmpassword.trim() === '') {
    errors.confirmpassword = 'This field is required'
  } else if (values.password != values.confirmpassword) {
    errors.confirmpassword = "Password does not match";
  }
  return errors;
}
export default validate;

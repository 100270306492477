import React, { Component} from 'react';
import { reduxForm, Field, FieldArray, FormSection, change, reset, SubmissionError, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Row, Col, Icon, Select, message} from 'antd';

import RouteStop from 'Route/components/RouteStop';
import IntlMessages from 'Common/components/intlMessages';
import Button from 'Common/components/button';
import InputField from 'Common/components/InputField';
import SelectField from 'Common/components/SelectField';
import {ContactSelectWFS as ContactSelect} from 'Contact/components/form/ContactSelect';
import Protected from "Common/components/authorization/Protected";
import LocationSelect from 'Location/components/form/LocationSelect';
import { ButtonWrappper } from 'Common/components/Form/styles/formWrapper.style';
import { CreateRouteWrapper } from 'Route/styles/route.style';
import Section from 'Common/components/section';
import FormWrapper from 'Common/styles/FormWrapper/formwrapper.style';
import ModalWrapper from 'Common/components/modalWrapper';
import LocationCreateForm from 'Location/components/LocationCreateForm';
import actions from 'Route/redux/actions';

const { createRoute, updateRoute } = actions;
const FORM_NAME = "CreateRoute";
const FormItem = Form.Item;
const Option = Select.Option;
const selector = formValueSelector(FORM_NAME);
export const required = value => {
  return ( value || typeof value == 'number' || typeof value == "object") ? undefined : 'This field is required'
}
export const keyRequired = value => value && value.key ? undefined : 'This field is required'
export const normalizeDropdownOption = value => ({id: {key: value.id, label: value.name}});

class RouteCreateForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      locationModalVisibility: false,
      locationCreatingField: "",
      locationStrictDefaultType:null
    }
  }

  componentDidMount(){
    const { editingMode } = this.props;
    if(!editingMode){
      this.props.initialize({
        status:"active"
      });
    }
    if(this.props.embeddedMode){
      this.props.dispatch(change(FORM_NAME,"contact", this.props.defaultContact))
    }
  }

  componentWillReceiveProps = (nextProps) => {
		if(nextProps.entity && nextProps.entity != this.props.entity){
			let entity = nextProps.entity;
      delete entity["id"];
      this.props.initialize({
        ...entity,
        departureRouteLocation: normalizeDropdownOption(entity.departureRouteLocation),
        arrivalRouteLocation: normalizeDropdownOption(entity.arrivalRouteLocation),
        contact: normalizeDropdownOption(entity.contact),
        stops: entity.stops.map((stop, index)=> {
          //delete stop['id'];
          return {
          ...stop,
          location: normalizeDropdownOption(stop.location)
          }}
        )
    });
	 }
  }

  componentWillUnmount(){
    this.props.dispatch(reset(FORM_NAME));
  }

  showLocationModal = (field, strictType) => {
    this.setState({
      locationModalVisibility: true,
      locationCreatingField: field,
      locationStrictDefaultType: strictType
    });
  }

  hideLocationModal = () => {
    this.setState({
      locationModalVisibility: false,
      locationCreatingField: "",
      locationStrictDefaultType: ""
    })
  }

  handleExternalFieldChange = (field, value) => {
    this.props.dispatch(change(FORM_NAME, field, value ));
  }

  onLocationCreated = (newLocation) => {
    this.handleExternalFieldChange(
      this.state.locationCreatingField,
      {id: {key:newLocation.id, label: newLocation.name}}
    );
    this.hideLocationModal();
  }

  setClientContactDefaultValue = (value) => {
      this.props.dispatch(change(FORM_NAME,'contact',{id:value}));
  }

  onSaveClick = (data) => {
    return this.handleCreate(this.normalizeDataBeforeSubmit(data),'save');
  }

  onSaveandCreateClick = (data) => {
    return this.handleCreate(this.normalizeDataBeforeSubmit(data), 'saveandcreate').then(() => {
      this.props.dispatch(reset(FORM_NAME));
    }, this);
  }

  onSaveandClone = (data) => {
    return this.handleCreate(this.normalizeDataBeforeSubmit(data), 'saveandclone');
  }

  normalizeDataBeforeSubmit = (data) => {
    return {
      ...data,
      contact: {
        id: data.contact.id.key
      },
      arrivalRouteLocation: { ...data.arrivalRouteLocation, "id": data.arrivalRouteLocation.id.key },
      departureRouteLocation : { ...data.departureRouteLocation, "id": data.departureRouteLocation.id.key },
      stops: data.stops && data.stops.map((stop)=>({
            location: {
              id: stop.location.id.key
            }
          }))
    }
  }

  onEmbeddedSaveClick = (data) => {
		return this.handleCreate(this.normalizeDataBeforeSubmit(data),"embeddedSave").then((route)=>{
			this.props.onRouteCreated(route);
		},this);
	}

  handleCreate = (data, context) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.createRoute(data, context, resolve, reject);
    }).then((response) => {
      this.setState({ loading: false });
      message.success("Route created successfully");
      return response.data;
    }, (error) => {
      this.setState({ loading: false });
      throw new SubmissionError(error);
    }, this)
  }

  onhandleUpdate = (data) => {
    return this.handleUpdate(this.normalizeDataBeforeSubmit(data))
  }

  handleUpdate = (data) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.updateRoute(this.props.entity_id, data, resolve, reject);
    }).then(() => {
      this.setState({ loading: false });
      message.success("Route Updated successfully");
      return true;
    }, (error) => {
      this.setState({ loading: false });
      throw new SubmissionError(error);
    }, this)
  }


  normalizeLocation = (location) => {
    if(!(location && location.id && location.id.key !== '')){
      return [];
    }
    return  [location.id.key];
  }

  getSelectedLocations = () => {
    const { departureRouteLocation, arrivalRouteLocation } = this.props;
    const stops = this.props.stop || [];
    return this.normalizeLocation(departureRouteLocation)
      .concat(this.normalizeLocation(arrivalRouteLocation))
      .concat(stops.map(stop => (
            this.normalizeLocation(stop.location)
          )
        ).reduce((acc, val)=> acc.concat(val),[])
      )
  }

  render(){
    const { handleSubmit, editingMode, embeddedMode,defaultContact} = this.props;
    const selectedLocations = this.getSelectedLocations();
    return(
    <CreateRouteWrapper>
      <FormWrapper>
        <Section label={<h2> <IntlMessages id="sectiontitle.basic"/></h2>}>
           <Row gutter={48}>
             <Col lg={12} md={12}>
               <FormItem>
                 <label><IntlMessages id="forms.label.routeId"/></label>
                 <Field validate={required }  name="routeId" component={InputField} />
               </FormItem>
             </Col>
             <Col lg={12} md={12}>
               <FormItem>
                 <label><IntlMessages id="forms.label.name"/></label>
                 <Field validate={required }  name="name" component={InputField} />
               </FormItem>
             </Col>
           </Row>

           <Row gutter={48}>
           <Col lg={12} md={12}>
             <FormItem>
               <label><IntlMessages id="forms.label.departureLocation"/></label>
               <Row gutter={20}>
                 <Col lg={16} md={24}>
                   <FormSection name="departureRouteLocation">
                     <Field
                      validate={keyRequired}
                      defaultValue={{key:""}}
                      name="id"
                      component={LocationSelect}
                      labelInValue={true}
                      excludes={selectedLocations}
                      />
                   </FormSection>
                 </Col>
                 <Protected scope="write:locations">
                   <Col lg={8} md={24}>
                     <Button onClick={this.showLocationModal.bind(this,"departureRouteLocation",null)} className="btn-gray responsiveButton"><Icon type="plus"/><IntlMessages id="forms.button.newlocation"/></Button>
                   </Col>
                 </Protected>
               </Row>
             </FormItem>
           </Col>
           <Col lg={12} md={12}>
             <FormItem>
                 <label><IntlMessages id="forms.label.arrivalLocation"/></label>
               <Row gutter={20}>
                 <Col lg={16} md={24}>
                   <FormSection name="arrivalRouteLocation">
                     <Field
                      validate={keyRequired}
                      defaultValue={{key:""}}
                      name="id"
                      component={LocationSelect}
                      labelInValue={true}
                      excludes={selectedLocations}
                      />
                   </FormSection>
                 </Col>
                 <Protected scope="write:locations">
                   <Col lg={8} md={24}>
                     <Button onClick={this.showLocationModal.bind(this,"arrivalRouteLocation", null)} className="btn-gray responsiveButton"><Icon type="plus" /><IntlMessages id="forms.button.newlocation"/></Button>
                   </Col>
                 </Protected>
               </Row>
             </FormItem>
             </Col>
           </Row>

           <Row gutter={48}>
             <Col lg={12} md={12}>
                <FormItem>
                   <label><IntlMessages id="forms.label.contact"/></label>
                   <ContactSelect
                     validate={required}
                     name="contact"
                     defaultValue={{key:""}}
                     labelInValue={true}
                     defaultContact={embeddedMode && defaultContact}
                     types={["CLIENT","CLIENTINDIVIDUAL"]}
                     setDefaultValue={this.setClientContactDefaultValue}
                     />
                 </FormItem>
             </Col>
             <Col lg={12} md={12}>
               <FormItem>
                 <label><IntlMessages id="forms.label.status"/></label>
                 <Field validate={required }  defaultValue="" name="status" component={SelectField} >
                 <Option value="">Select</Option>
                 <Option value="active">Active</Option>
                 <Option value="inactive">Inactive</Option>
                 </Field>
               </FormItem>
             </Col>
           </Row>

           <FieldArray
             name="stops"
             component={RouteStop}
             showLocationModal={this.showLocationModal}
             excludedLocations={selectedLocations}
            />

           <Row gutter={48}>
             <Col lg={20} md={24}>
               <ButtonWrappper>
               {editingMode ? (
                  <Button type="primary" onClick={handleSubmit(this.onhandleUpdate.bind(this))} loading={this.state.loading}><IntlMessages id="forms.button.update"/></Button>
                 ):embeddedMode ? (
                   <Button
                     type="primary"
                     className="save"
                     loading={this.state.loading}
                     onClick={handleSubmit(this.onEmbeddedSaveClick.bind(this))
                   }
                   >
                     <IntlMessages id="forms.button.save" />
                   </Button>
                 )
                 :
                 (
                   <div>
                     <Button type="primary" loading={this.state.loading} onClick={handleSubmit(this.onSaveClick.bind(this))}><IntlMessages id="forms.button.save" /></Button>
                     <Button loading={this.state.loading} onClick={handleSubmit(this.onSaveandClone.bind(this))}><IntlMessages id="forms.button.saveandclone"/></Button>
                     <Button loading={this.state.loading} onClick={handleSubmit(this.onSaveandCreateClick.bind(this))}><IntlMessages id="forms.button.saveandcreate"/></Button>
                   </div>
                 )



               }
               </ButtonWrappper>
             </Col>
           </Row>

        </Section>
        <ModalWrapper
          visible={this.state.locationModalVisibility}
          onCancel={this.hideLocationModal}
          title = {"Add New Location"}
        >
        { this.state.locationModalVisibility && (
          <LocationCreateForm
            embeddedMode={true}
            strictDefaultType = {this.state.locationStrictDefaultType}
            onLocationCreated={this.onLocationCreated}
          />
        )}
        </ModalWrapper>
      </FormWrapper>
    </CreateRouteWrapper>
    )
  }
}

RouteCreateForm = reduxForm({
  form:FORM_NAME
})(RouteCreateForm)

export default connect((state) => {
  const departureRouteLocation = selector(state, "departureRouteLocation");
  const arrivalRouteLocation = selector(state, "arrivalRouteLocation");
  const stop = selector(state, "stops");
  const contact = selector(state,'contact');
  return {
    departureRouteLocation,
    arrivalRouteLocation,
    stop,
    contact
  }
}, { createRoute, updateRoute })(RouteCreateForm);

const actions = {
    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_ERROR: 'CREATE_USER_ERROR',
    FETCH_ORGANIZATION_REQUEST: 'FETCH_ORGANIZATION_REQUEST',
    FETCH_USER_LIST_REQUEST: 'FETCH_USER_LIST_REQUEST',
    FETCH_ALL_USERS_LIST_REQUEST: 'FETCH_ALL_USERS_LIST_REQUEST',
    FETCH_ALL_USERS_LIST_SUCCESS: 'FETCH_ALL_USERS_LIST_SUCCESS',
    FETCH_ALL_USERS_LIST_FAIL: 'FETCH_ALL_USERS_LIST_FAIL',
    FETCH_USER_LIST_SUCCESS: 'FETCH_USER_LIST_SUCCESS',
    FETCH_USER_LIST_FAIL: 'FETCH_USER_LIST_FAIL',
    FETCH_USER_DETAIL_REQUEST: 'FETCH_USER_DETAIL_REQUEST',
    FETCH_USER_DETAIL_SUCCESS: 'FETCH_USER_DETAIL_SUCCESS',
    FETCH_USER_DETAIL_FAIL: 'FETCH_USER_DETAIL_FAIL',
    EDIT_USER_DETAIL_REQUEST: 'EDIT_USER_DETAIL_REQUEST',
    EDIT_USER_DETAIL_SUCCESS: 'EDIT_USER_DETAIL_SUCCESS',
    EDIT_USER_DETAIL_FAILED: 'EDIT_USER_DETAIL_FAILED',
    DELETE_USER_DETAIL_REQUEST: 'DELETE_USER_DETAIL_REQUEST',
    DELETE_USER_DETAIL_SUCCESS: 'DELETE_USER_DETAIL_SUCCESS',
    DELETE_USER_DETAIL_FAILED: 'DELETE_USER_DETAIL_FAILED',
    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',

    createUser: (data,context,resolve,reject) => ({
      type: actions.CREATE_USER_REQUEST,
      data,
      context,
      resolve,
      reject
    }),

    fetchUserList: (resolve, reject) => ({
      type:actions.FETCH_USER_LIST_REQUEST,
      resolve,
      reject
    }),

    fetchAllUsersList: (resolve, reject) => ({
      type:actions.FETCH_ALL_USERS_LIST_REQUEST,
      resolve,
      reject
    }),

    fetchOrganisation: (resolve,reject) => ({
      type: actions.FETCH_ORGANIZATION_REQUEST,
      resolve,
      reject
    }),
    fetchUserDetail: (id, resolve, reject) => ({
      type: actions.FETCH_USER_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }),
    userDetailEdit: (data,id,resolve,reject) => ({
      type: actions.EDIT_USER_DETAIL_REQUEST,
      data,
      id,
      resolve,
      reject
    }),
    deleteUserDetail: (id,resolve,reject) => ({
      type: actions.DELETE_USER_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }),
    changePassword:(data, id, context,  resolve, reject) => ({
      type:actions.CHANGE_PASSWORD_REQUEST,
      data,
      id,
      context,
      resolve,
      reject
    })
  };
  export default actions;

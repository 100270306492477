import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'Common/components/Calendar/redux/actions';
import  Api  from 'Common/api/Api';

const api = new Api();

export function* createCalendarEvent() {
  yield takeEvery(actions.CREATE_CALENDAR_EVENT_REQUEST, function*({data,id,url,resolve,reject}) {
    try{
      const response = yield call(api.post,`${url}/${id}/calendar-events`,data);
      if(response){
        yield put({
          type: actions.CREATE_CALENDAR_EVENT_SUCCESS,
          response: response.data
        })
        yield call(resolve,response);
      }
     } 
     catch(error) {
      yield call(reject, error);     
    }
  });
}

export function* retrieveCalendarEvent() {
  yield takeEvery(actions.RETRIEVE_CALENDAR_EVENT_REQUEST, function*({id,url,event_id,resolve, reject}) {
    try{
      const response = yield call(api.get,`${url}/${id}/calendar-events` + event_id, {});
        yield call(resolve,response);
    } 
    catch(error) {
      yield call(reject,error);
    }
  });
}

export function* listCalendarEvent() {
  yield takeEvery(actions.LIST_CALENDAR_EVENT_REQUEST, function*({id,url}) {
    try{
      const response = yield call(api.get,`${url}/${id}/calendar-events?pageSize=-1`, {});
      yield put({
        type: actions.LIST_CALENDAR_EVENT_SUCCESS,
        response: response.data.results
      })
    } 
    catch(error) {}
  });
}


export function* updateCalendarEvent() {
  yield takeEvery(actions.UPDATE_CALENDAR_EVENT_REQUEST, function*({data,id,url,event_id,resolve,reject}) {
    try{
      const response = yield call(api.update,`${url}/${id}/calendar-events/`+ event_id, data);
      yield put({
        type: actions.UPDATE_CALENDAR_EVENT_SUCCESS,
        updateData: response.data,
        event_id: event_id
      })
      yield call(resolve,response);
    } 
    catch(error) {
      yield call(reject, error);
    }
  });
 }

export function* deleteCalendarEvent(){
  yield takeEvery(actions.DELETE_CALENDAR_EVENT_REQUEST, function*({id,url,event_id,resolve,reject}){
    try{
      const response = yield call(api.delete,`${url}/${id}/calendar-events/`+ event_id, {})
      yield put({
        type: actions.DELETE_CALENDAR_EVENT_SUCCESS,
        event_id: event_id
      })
      yield call(resolve,response);
    }catch(error){
      yield call(reject,error);
    }
  });
  
}

export default function* rootSaga() {
  yield all([
    fork(createCalendarEvent),
    fork(retrieveCalendarEvent),
    fork(updateCalendarEvent),
    fork(deleteCalendarEvent),
    fork(listCalendarEvent)
  ]);
}

import asyncComponent from "Common/helpers/AsyncFunc";

const newsRoutes = [
  {
    path: "news",
    component: asyncComponent(() => import("NewsTraining/containers/NewsTraining")),
    scope: "read:users",
  },
];

export default newsRoutes;

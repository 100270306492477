import asyncComponent from "Common/helpers/AsyncFunc";
import ListUser from "../containers/ListUser";

const userroutes = [
  {
    path: "users",
    component: asyncComponent(() => import("User/containers/ListUser")),
    scope: "read:users"
  },
  {
    path: "users/create",
    component: asyncComponent(() => import("User/containers/CreateUser")),
    scope: "write:users"
  },
  {
    path: "users/:id",
    exact: true,
    component: asyncComponent(() => import("User/containers/RetrieveUser")),
    scope: "read:users"
  },
  {
    path: "users/:id/edit",
    exact: true,
    component: asyncComponent(() => import("User/containers/EditUser")),
    scope: "write:users"
  },
  {
    path: "change-password",
    exact: true,
    component: asyncComponent(() => import("User/containers/ChangePassword")),
    scope: "write:users"
  },
  {
    path: "users/role/:type",
    component: ListUser,
    scope: "read:users"
  }
];

export default userroutes;

import asyncComponent from "Common/helpers/AsyncFunc";
import ListDocument from "Document/containers/ListDocument";

const documentroutes = [
  {
        path:'documents/create',
        component: asyncComponent(() => import ('Document/containers/CreateDocument')),
        scope: 'write:users'
    },
      {
        path: "documents",
        component: asyncComponent(() => import("Document/containers/ListDocument")),
        scope: 'read:users'
      },
       {
        path: "documents/:id/edit",
        component: asyncComponent(() => import("Document/containers/EditDocument")),
        scope: 'write:users'
      },
      {
          path:"documents/:id",
          component:asyncComponent(() => import("Document/containers/RetrieveDocument")),
          scope:'read:users'
      },
      {
          path:"documents/type/:id",
          component:ListDocument,
          scope:'read:users'
      }
    
]

export default documentroutes;


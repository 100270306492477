import actions from "./actions";

const initState = {
  loading: false,
  data: [],
};

export default function dropdownReducer(state = initState, action) {
  switch (action.type) {
    case actions.LIST_DROPDOWN_SUCCESS: {
      let groupedDropdown = {};
      action.response.forEach((d) => {
        groupedDropdown[d.dropdown] = [
          ...(groupedDropdown[d.dropdown] || []),
          d,
        ];
      });
      return {
        ...state,
        data: action.response,
        ...groupedDropdown,
        loading: false,
      };
    }
    case actions.LIST_DROPDOWN_REQUEST:
      return { ...state, loading: true };
    case actions.LIST_DROPDOWN_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

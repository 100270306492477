import { takeEvery, call, all, fork } from "redux-saga/effects";

import actions from "./actions";
import Api from "Common/api/Api2";
const api = new Api();

function* listNotifications() {
  yield takeEvery(
    actions.LIST_NOTIFICATIONS,
    function* ({ page, filters, resolve, reject }) {
      try {
        const response = yield call(
          api.get,
          `dashboard/all-notifications/${page}?${filters}`
        );
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}

function* markFalseNotification() {
  yield takeEvery(
    actions.MARK_FALSE_NOTIFICATIONS,
    function* ({ notificationId,status, resolve, reject }) {
      const payload = { falsePositive: status };
      try {
        const response = yield call(
          api.update,
          `dashboard/notification/${notificationId}`,
          payload
        );
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}

function* recordNotificationActions() {
  yield takeEvery(
    actions.RECORD_NOTIFICATION_ACTIONS,
    function* ({ payload, resolve, reject }) {
      try {
        const response = yield call(
          api.post,
          `dashboard/notification-action/`,
          payload
        );
        yield call(resolve, response);
      } catch (error) {
        yield call(reject, error);
      }
    }
  );
}

function* fetchNotifications() {
  yield takeEvery(actions.FETCH_NOTIFICATIONS, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "dashboard/notifications");
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listNotifications),
    fork(markFalseNotification),
    fork(fetchNotifications),
    fork(recordNotificationActions),
  ]);
}

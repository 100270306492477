const actions = {
  CALENDAR_VIEW: 'CALENDAR_VIEW',
  CALENDAR_EVENTS: 'CALENDAR_EVENTS',
  CREATE_CALENDAR_EVENT_REQUEST: 'CREATE_CALENDAR_EVENT_REQUEST',
  CREATE_CALENDAR_EVENT_SUCCESS: 'CREATE_CALENDAR_EVENT_SUCCESS',
  LIST_CALENDAR_EVENT_REQUEST: 'LIST_CALENDAR_EVENT_REQUEST',
  LIST_CALENDAR_EVENT_SUCCESS: 'LIST_CALENDAR_EVENT_SUCCESS',
  RETRIEVE_CALENDAR_EVENT_REQUEST: 'RETRIEVE_CALENDAR_EVENT_REQUEST',
  UPDATE_CALENDAR_EVENT_REQUEST: 'UPDATE_CALENDAR_EVENT_REQUEST',
  UPDATE_CALENDAR_EVENT_SUCCESS: 'UPDATE_CALENDAR_EVENT_SUCCESS',
  DELETE_CALENDAR_EVENT_REQUEST: 'DELETE_CALENDAR_EVENT_REQUEST',
  DELETE_CALENDAR_EVENT_SUCCESS: 'DELETE_CALENDAR_EVENT_SUCCESS',
  changeView: view => ({
    type: actions.CALENDAR_VIEW,
    view
  }),
  changeEvents: events => ({
    type: actions.CALENDAR_EVENTS,
    events
  }),
  createCalendarEvent:(data,id,url,resolve,reject) => ({
    type:actions.CREATE_CALENDAR_EVENT_REQUEST,
    data,
    id,
    url,
    resolve,
    reject
  }),
  retrieveCalendarEvent:(id,url,event_id,resolve,reject) => ({
    type:actions.RETRIEVE_CALENDAR_EVENT_REQUEST,
    id,
    url,
    event_id,
    resolve,
    reject
  }),
  listCalendarEvent: (id,url) => ({
    type:actions.LIST_CALENDAR_EVENT_REQUEST,
    id,
    url
  }),
  updateCalendarEvent:(data,id,url,event_id,resolve,reject) => ({
    type:actions.UPDATE_CALENDAR_EVENT_REQUEST,
    data,
    id,
    url,
    event_id,
    resolve,
    reject
  }),
  deleteCalendarEvent:(id,url,event_id,resolve,reject) =>({
    type:actions.DELETE_CALENDAR_EVENT_REQUEST,
    id,
    url,
    event_id,
    resolve,
    reject
  }),
};
export default actions;

import { takeEvery, call, put, fork , all} from "redux-saga/effects";
import actions from 'Feedback/redux/actions';
import Api from 'Common/api/Api';
import { push } from 'react-router-redux';

const api = new Api();

export function* createFeedback(){
  yield takeEvery(actions.CREATE_FEEDBACK_REQUEST, function*({data, context, resolve, reject}){
    try{
      const response = yield call( api.post, 'feedbacks', data);
      yield call(resolve,response);
      if(context === "save"){
        yield put(push("/feedbacks/"+response.data.id))
      }
    } catch(error){
      yield call(reject,error);
    }
  });
}

export function* updateFeedback(){
  yield takeEvery(actions.UPDATE_FEEDBACK_REQUEST, function*({data, id, resolve, reject}){
    try{
      const response = yield call(api.update, 'feedbacks/'+id, data);
      yield call(resolve,response);
      yield put(push("/feedbacks/"+id));
    }catch(error){
      yield call(reject,error);
    }
  });
}

export function* fetchFeedbackDetail(){
  yield takeEvery(actions.FETCH_FEEDBACK_DETAIL_REQUEST, function*({id, resolve, reject }){
    try{
      const response = yield call(api.get, "feedbacks/" + id, {});
      if(resolve){
        yield call(resolve, response)
      }else {
        yield put({
          type:actions.FETCH_FEEDBACK_DETAIL_SUCCESS,
          response:response.data
        })
      }
    } catch(error){
       if(reject){
         yield call(reject, error)
       }else {
         yield put({
           type:actions.FETCH_FEEDBACK_DETAIL_FAIL,
           error
         });
       }
    }
  });
}

export function* deleteFeedbackDetail(){
  yield takeEvery(actions.DELETE_FEEDBACK_DETAIL_REQUEST, function*({id, resolve, reject}){
    try{
     const response = yield call(api.delete, "feedbacks/" + id, {});
     if(resolve){
       yield call(resolve, response);
       yield put(push("/feedbacks"));
     }else{
       yield put({
         type:actions.DELETE_FEEDBACK_DETAIL_SUCCESS,
         response
       })
     }
    } catch(error){
      if(reject){
         yield call(reject, error);
      }else{
        yield put({
          type:actions.DELETE_FEEDBACK_DETAIL_FAIL,
          error
        })
      }
    }
  })
}


export default function* rootSaga(){
  yield all([
    fork(createFeedback),
    fork(updateFeedback),
    fork(fetchFeedbackDetail),
    fork(deleteFeedbackDetail),
  ])
}

import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { isMoment } from "moment";
import { message } from "antd";
import LayoutContent from "Common/components/layoutContent";
import LayoutWrapper from "Common/components/layoutWrapper.js";
import IntlMessages from "Common/components/intlMessages";
import PageHeader from "Common/components/Page/PageHeader";
import { Icon } from "antd";
// import Button from "Common/components/button";
// import Protected from "Common/components/authorization/Protected";
import TableWrapper from "Common/components/Table/index";
import getProtectedColumn from "Common/components/authorization/ProtectedColumn";
import action from "Vehicle/redux/actions";
import actionsDropdown from "Dropdown/redux/actions";
import localizeDate from "Common/localizeDate";
import DocumentAdvancedFilter from "../components/DocumentAdvancedFilter";
import { DocumentsSection,JobDocumentsSection } from "../../Common/components/Table/styles/responsiveTableWrapper.style";
const { deleteDocument } = action;
const { listDropdown } = actionsDropdown;
// const scope = {
//   write: "write:documents",
// };

const breadcrumbs = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Documents",
  },
];

const defaultQuickFilter = {
  filterBy: "subType.id",
  defaultValue: "all",
  options: [
    {
      tab: "All Documents",
      key: "all",
    },
  ],
};

const getquickFilter = (documentsSubTypes) => {
  let newQuickFilterOptions = defaultQuickFilter["options"];
  for (let subType of documentsSubTypes) {
    newQuickFilterOptions = newQuickFilterOptions.concat({
      tab: subType.title,
      key: subType.id,
    });
  }
  return {
    ...defaultQuickFilter,
    options: newQuickFilterOptions,
  };
};

const sorter = (a, b) => ("" + a.attr).localeCompare(b.attr);

class ListDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updatedOn: moment().valueOf(),
      subType: "all",
    };
  }

  onCreateClicked = () => {
    this.props.history.push("/documents/create");
  };

  componentWillMount() {
    if (!this.props.dropdownList.length && !this.props.loading) {
      this.props.listDropdown();
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      subType: nextProps.match.params.id,
    });
  };

  confirm = (id) => {
    this.onDeleteClicked(id);
  };

  onDeleteClicked = (id) => {
    this.setState({ loading: true });
    return new Promise((resolve, reject) => {
      this.props.deleteDocument(id, resolve, reject);
    })
      .then(() => {
        this.setState({
          loading: false,
          updatedOn: moment().valueOf(),
        });
        message.success("Successfully deleted document");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("Error while deleting document");
        console.log(error);
      }, this);
  };

  onEditClicked = (id, e) => {
    e.preventDefault();
    this.props.history.push("/documents/" + id + "/edit/");
  };

  handleRowClick = (record) => {
    this.props.history.push("/documents/" + record.id);
  };

  render(isActivities) {
    isActivities =
        (
          this.props.dropdownList.find(
            ({ title }) => title === "Job Activities"
          ) || {}
        ).id == this.props.match.params.id;
        const tableWrapper= <TableWrapper
              defaultQuickFilterValue={this.props.match.params.id}
              history={this.props.history}
              handleRowClick={this.handleRowClick}
              updatedOn={this.state.updatedOn}
              advancedFilterForm={DocumentAdvancedFilter}
              columns={this.getColumns()}
              quickFilterElements={getquickFilter(this.props.dropdownList)}
              url="documents"
              quickFilterStatus={this.props.match.params.id}
              createTitle="Create Document"
              onClick={this.onCreateClicked}
              defaultSort={{field: 'reviewDate', order: 'descend'}}
            />
    return (
      <div>
        <LayoutWrapper>
          <PageHeader
            title={<IntlMessages id="pagetitle.documents" />}
            breadcrumbs={breadcrumbs}
          ></PageHeader>
          <LayoutContent loading={this.state.loading}>
            {isActivities ? <JobDocumentsSection>
           {tableWrapper}
            </JobDocumentsSection>:<DocumentsSection> 
            {tableWrapper}
              </DocumentsSection>}
          </LayoutContent>
        </LayoutWrapper>
      </div>
    );
  }

  getDefaultColumns = () => {
    let isActivities = false;
    if (
      !!this.props.match &&
      !!this.props.match.params &&
      !!this.props.match.params.id
    ) {
      isActivities =
        (
          this.props.dropdownList.find(
            ({ title }) => title === "Job Activities"
          ) || {}
        ).id == this.props.match.params.id;
    }
    // const self = this;

    return isActivities
      ? [
          {
            title: "Creation Date",
            key: "createdOn",
            dataIndex: "createdOn",
            sorter: { sorter },
            render: (date) =>
              isMoment(localizeDate(date)) &&
              localizeDate(date).format("DD/MM/YYYY HH:mm"),
          },
          {
            title: "Driver Name",
            key: "driver",
            dataIndex: "driver",
            sorter: { sorter },
            render: (driver = {}, row) => {
              const { user = {} } = driver !== null ? driver : {};
              return <span>{(user.firstName && user.lastName)? user.firstName + " " + user.lastName:""}</span>;
            },
          },
          {
            title: "Document Name",
            key: "name",
            dataIndex: "name",
            render: (text, row) => <span>{text}</span>,
          },
          {
            title: "Route From",
            key: "routeFrom",
            dataIndex: "metaData",
            render: (metaData, row) => {
              const { routeFrom = "" } =
                metaData !== null && metaData !== ""
                  ? JSON.parse(`${metaData}`)
                  : {};
              return <span>{routeFrom}</span>;
            },
          },
          {
            title: "Route To",
            key: "routeTo",
            dataIndex: "metaData",
            render: (metaData, row) => {
              const { routeTo = "" } =
                metaData !== null && metaData !== ""
                  ? JSON.parse(`${metaData}`)
                  : {};
              return <span>{routeTo}</span>;
            },
          },
          {
            title: "Uploads",
            key: "uploadedDocument",
            dataIndex: "uploadedDocument",
            render: (uploadedDocument = [], row) => {
              return (
                <span>
                  {uploadedDocument.map(({ diskName }) => (
                    <a
                      key={diskName}
                      target="_blank"
                      href={process.env.REACT_APP_MEDIA_BASE_URL + diskName}
                    >
                      <Icon
                        type="download"
                        style={{ fontSize: "25px", color: "#08c" }}
                      />
                    </a>
                  ))}
                </span>
              );
            },
          },
          ,
        ]
      : [
          {
            title: "Serial No.",
            key: "documentNumber",
            dataIndex: "documentNumber",
            render: (text, row) => <span>{text}</span>,
          },
          {
            title: "Document Name",
            key: "name",
            dataIndex: "name",
            render: (text, row) => <span>{text}</span>,
          },
          {
            title: "Creation Date",
            key: "createdOn",
            dataIndex: "createdOn",
            sorter: { sorter },
            render: (date) =>
              isMoment(localizeDate(date)) &&
              localizeDate(date).format("DD/MM/YYYY HH:mm"),
          },
          {
            title: "Review Date",
            key: "reviewDate",
            dataIndex: "reviewDate",
            sorter: { sorter },
            render: (date) =>
              isMoment(localizeDate(date)) &&
              localizeDate(date).format("DD/MM/YYYY HH:mm"),
          },
          {
            title: "Created By",
            key: "createdBy",
            dataIndex: "createdBy",
          },
          {
            title: "Number of Document",
            key: "numberDocument",
            dataIndex: "createdBy",
            render: (text, row) => <div>{row.uploadedDocument.length}</div>,
          },
          {
            title: "Sub Type",
            key: "subType",
            dataIndex: "subType",
            render: (text, row) => {
              if (row.subType) {
                return <span> {row.subType.title}</span>;
              } else {
                return <span> </span>;
              }
            },
          },
        ];
  };
  getColumns = () => {
    return getProtectedColumn(
      this.getDefaultColumns,
      this.onEditClicked,
      this.confirm,
      "documents"
    );
  };
}

export default connect(
  (state) => ({
    // contacts:state.Contact.contacts,
    loading: state.Dropdown.loading,
    dropdownList: state.Dropdown.documentsSubTypes || [],
  }),
  {
    deleteDocument,
    listDropdown,
  }
)(ListDocument);


const actions = {
    CREATE_ASSETS_REQUEST: 'CREATE_ASSETS_REQUEST',
    FETCH_ASSETS_LIST_REQUEST: 'FETCH_ASSETS_LIST_REQUEST',
    FETCH_ASSETS_LIST_SUCCESS: "FETCH_ASSETS_LIST_SUCCESS",
    FETCH_ASSETS_LIST_FAIL: "FETCH_ASSETS_LIST_FAIL",

    RETRIEVE_ASSETS_REQUEST: "RETRIEVE_ASSETS_REQUEST",
    UPDATE_ASSETS_REQUEST: "UPDATE_ASSETS_REQUEST",

    DELETE_ASSETS_REQUEST: "DELETE_ASSETS_REQUEST",

    createAssets: (data, context, resolve, reject) => ({
        type: actions.CREATE_ASSETS_REQUEST,
        data,
        context,
        resolve,
        reject
    }),
    fetchAssetsList: (resolve, reject) => ({
        type: actions.FETCH_ASSETS_LIST_REQUEST,
        resolve,
        reject
    }),
    updateAssets: (data, id, resolve, reject) => ({
        type: actions.UPDATE_ASSETS_REQUEST,
        data,
        id,
        resolve,
        reject
    }),
    retrieveAssets: (id, resolve, reject) => {
        return {
            type: actions.RETRIEVE_ASSETS_REQUEST,
            id,
            resolve,
            reject
        }
    },
    deleteAssets: (id, resolve, reject) => ({
        type: actions.DELETE_ASSETS_REQUEST,
        id,
        resolve,
        reject
    }),
};
export default actions;

const documentoptions = [
    {
      key: "documents",
      label: "sidebar.documents",
      leftIcon: "ri-file-copy-2-line",
      scope: "read:users",
      subMenuUrl:"documents/type",
    },
  ];
  export default documentoptions;

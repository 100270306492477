const actions = {
  CREATE_FEEDBACK_REQUEST:"CREATE_FEEDBACK_REQUEST",
  CREATE_FEEDBACK_SUCCESS:"CREATE_FEEDBACK_SUCCESS",
  CREATE_FEEDBACK_FAIL:"CREATE_FEEDBACK_FAIL",

  UPDATE_FEEDBACK_REQUEST:"UPDATE_FEEDBACK_REQUEST",
  UPDATE_FEEDBACK_SUCCESS:"UPDATE_FEEDBACK_SUCCESS",
  UPDATE_FEEDBACK_FAIL:"UPDATE_FEEDBACK_FAIL",

  FETCH_FEEDBACK_LIST_REQUEST:"FETCH_FEEDBACK_LIST_REQUEST",
  FETCH_FEEDBACK_LIST_SUCCESS:"FETCH_FEEDBACK_LIST_SUCCESS",
  FETCH_FEEDBACK_LIST_FAIL:"FETCH_FEEDBACK_LIST_FAIL",

  FETCH_FEEDBACK_DETAIL_REQUEST:"FETCH_FEEDBACK_DETAIL_REQUEST",
  FETCH_FEEDBACK_DETAIL_SUCCESS:"FETCH_FEEDBACK_DETAIL_SUCCESS",
  FETCH_FEEDBACK_DETAIL_FAIL:"FETCH_FEEDBACK_DETAIL_FAIL",

  DELETE_FEEDBACK_DETAIL_REQUEST:"DELETE_FEEDBACK_DETAIL_REQUEST",
  DELETE_FEEDBACK_DETAIL_SUCCESS:"DELETE_FEEDBACK_DETAIL_SUCCESS",
  DELETE_FEEDBACK_DETAIL_FAIL:"DELETE_FEEDBACK_DETAIL_FAIL",

  createFeedback(data, context, resolve, reject){
    return {
      type:actions.CREATE_FEEDBACK_REQUEST,
      data,
      context,
      resolve,
      reject
    }
  },

  updateFeedback(data, id, resolve, reject){
    return {
      type:actions.UPDATE_FEEDBACK_REQUEST,
      data,
      id,
      resolve,
      reject
    }
  },

  fetchFeedbackDetail(id, resolve, reject){
    return {
      type:actions.FETCH_FEEDBACK_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }
  },

  deleteFeedbackDetail(id, resolve, reject){
    return {
      type:actions.DELETE_FEEDBACK_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }
  },

};
export default actions;

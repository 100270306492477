import React, { Component } from 'react';

class StopPropagationWrapper extends Component {
    render(){
        return (
            <span onClick= {(e) => e.stopPropagation()}>
         {this.props.children}
      </span>
        )
    }
}

export default StopPropagationWrapper;
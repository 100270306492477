import asynComponent from "Common/helpers/AsyncFunc";

const allocationRoutes = [
    {
        path: 'roster',
        component: asynComponent(() => import('Allocation/containers/CreateAllocation')),
        scope: 'write:allocations'
    }
];

export default allocationRoutes;

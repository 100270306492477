import styled from "styled-components";

const CreateRouteWrapper = styled.div`
  @media 
  only screen and (max-width: 420px){
    button:not(:first-child) {
      margin: 10px 0 0 10px;
    }
  }
  .responsiveButton {
    border-color: #fff !important;
  }
  @media 
  only screen and (max-width: 768px) { 
    .responsiveButton{
      margin: 10px 0 0 10px;
  }
`;
export { CreateRouteWrapper };
import asyncComponent from "Common/helpers/AsyncFunc";

const noticeRoutes = [
  {
    path: "notice",
    component: asyncComponent(() => import("NoticeBoard/containers/NoticeBoard")),
    scope: "read:users",
  },
];

export default noticeRoutes;

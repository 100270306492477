import React from 'react';
import { Field } from 'redux-form';
import IntlMessages from 'Common/components/intlMessages';
import InputField from 'Common/components/InputField';
import { ContractorDropdownWFS as ContractorDropdown } from "Vehicle/components/form/ContractorDropdown";
import { ContactSelectWFS as ContactSelect } from 'Contact/components/form/ContactSelect';
import Protected from "Common/components/authorization/Protected";

import {
    DropdownSelectWithFormSection as DropdownSelect
} from 'Dropdown/components/form/DropdownSelect';


const VehicleAdvancedFilterForm = () => (
    <div>
        <div className="form-group">
            <label><IntlMessages id="forms.label.registrationNumber" /> </label>
            <Field name="registrationNumber" component={InputField} />
        </div>
        <div className="form-group">
            <label><IntlMessages id="forms.label.vehicleType" /> </label>
            <DropdownSelect name="vehicleType" type="vehicleType" labelInValue={true} defaultValue={{ key: "" }} />
        </div>
        <div className="form-group">
            <label><IntlMessages id="forms.label.contractor" /> </label>
            <ContractorDropdown
                name="contractor"
                defaultValue={{ key: "" }} />
        </div>
        <Protected scope="read:contacts">
        <div>
            <label><IntlMessages id="forms.label.companyLabel" /> </label>
            <ContactSelect name="contact" />
        </div>
        </Protected>
    </div>
);

export default VehicleAdvancedFilterForm;

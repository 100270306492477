import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Field } from "redux-form";
import InputField from "Common/components/InputField";
import AutocompleteSearchResultWrapper from "Location/styles/autocompleteSearchResultWrapper.style";

class LocationSearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressInput: props.value,
      loading: true,
    };
  }

  render = () => {
    return (
      <PlacesAutocomplete
        value={this.state.addressInput}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        {...this.props}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search-field-wrap">
            <Field
              component={InputField}
              {...getInputProps()}
              {...this.props}
            />
            <AutocompleteSearchResultWrapper className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </AutocompleteSearchResultWrapper>
          </div>
        )}
      </PlacesAutocomplete>
    );
  };

  handleChange = (address) => {
    this.setState({ addressInput: address });
  };

  handleSelect = (address) => {
    const self = this;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        self.props.handleLocationChange(address, JSON.stringify(latLng));
      })
      .catch((error) => console.error("Error", error));
  };
}

export default LocationSearchBox;

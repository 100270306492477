const actions = {
    CREATE_ASSETTYPE_REQUEST: 'CREATE_ASSETTYPE_REQUEST',
    FETCH_ASSETTYPE_LIST_REQUEST: 'FETCH_ASSETTYPE_LIST_REQUEST',
    FETCH_ASSETTYPE_LIST_SUCCESS: "FETCH_ASSETTYPE_LIST_SUCCESS",
    FETCH_ASSETTYPE_LIST_FAIL: "FETCH_ASSETTYPE_LIST_FAIL",

    RETRIEVE_ASSETTYPE_REQUEST: "RETRIEVE_ASSETTYPE_REQUEST",
    UPDATE_ASSETTYPE_REQUEST: "UPDATE_ASSETTYPE_REQUEST",

    DELETE_ASSETTYPE_REQUEST: "DELETE_ASSETTYPE_REQUEST",
    DELETE_INSPECTION_ITEM_REQUEST: "DELETE_INSPECTION_ITEM_REQUEST",

    createAssetType: (data, context, resolve, reject) => {
        return ({
            type: actions.CREATE_ASSETTYPE_REQUEST,
            data,
            context,
            resolve,
            reject
        })
    },
    fetchAssetTypetList: (resolve, reject) => ({
        type: actions.FETCH_ASSETTYPE_LIST_REQUEST,
        resolve,
        reject
    }),
    updateAssetType: (data, id, resolve, reject) => ({
        type: actions.UPDATE_ASSETTYPE_REQUEST,
        data,
        id,
        resolve,
        reject
    }),
    retrieveAssetType: (id, resolve, reject) => {
        return {
            type: actions.RETRIEVE_ASSETTYPE_REQUEST,
            id,
            resolve,
            reject
        }
    },
    deleteAssetType: (id, resolve, reject) => ({
        type: actions.DELETE_ASSETTYPE_REQUEST,
        id,
        resolve,
        reject
    }),
    deleteInspectionItem: (id, resolve, reject) => ({
        type: actions.DELETE_INSPECTION_ITEM_REQUEST,
        id,
        resolve,
        reject
    }),
    
};
export default actions;

import asyncComponent from "Common/helpers/AsyncFunc";

const dashboardroutes = [
  {
    path: "dashboard",
    component: asyncComponent(() => import("Dashboard/containers/Dashboard2"))
  }

];

export default dashboardroutes;

import React from 'react';
import {Field} from 'redux-form';
import {DropdownSelectWithFormSection as DropdownSelect} from 'Dropdown/components/form/DropdownSelect';
import DateTimePicker from 'Common/components/DateTimePicker';
import IntlMessages from 'Common/components/intlMessages';
import { UserDropdownSelectWithFormSection as UserDropdownSelect } from 'User/components/form/UserDropdown';

const IncidentAdvancedFilterForm = () => (
  <div>
    <div className="form-group">
      <label><IntlMessages id="forms.label.reportedBy" /> </label>
      <UserDropdownSelect name="reportedBy" labelInValue={true} defaultValue={{key:""}}/>
    </div>
    <div className="form-group">
      <label><IntlMessages id="forms.label.submissionDate" /> </label>
        <Field name="submissionDate" component={DateTimePicker}/>
    </div>
    <div className="form-group">
      <label><IntlMessages id="forms.label.incidentType" /> </label>
      <DropdownSelect  name="incidentType" type="incidentType" labelInValue={true} defaultValue={{key:""}}/>
    </div>
  </div>
)
export default IncidentAdvancedFilterForm;

export const FETCH_SHIFTS_START = "FETCH_SHIFTS_START";
export const FETCH_SHIFTS_SUCCESS = "FETCH_SHIFTS_SUCCESS";
export const FETCH_SHIFTS_FAILURE = "FETCH_SHIFTS_FAILURE";
export const SELECT_SHIFT_REQUEST = "SELECT_SHIFT_REQUEST";
export const SELECT_SHIFT = "SELECT_SHIFT";
export const FETCH_DRIVER_REQUEST = "FETCH_DRIVER_REQUEST";
export const FETCH_DRIVER = "FETCH_DRIVER";
export const FETCH_CONTRACTOR_REQUEST = "FETCH_CONTRACTOR_REQUEST";
export const FETCH_CONTRACTOR = "FETCH_CONTRACTOR";
export const FETCH_VEHICLE_REQUEST = "FETCH_VEHICLE_REQUEST";
export const FETCH_VEHICLE = "FETCH_VEHICLE";
export const FETCH_WORK_REST_REQUEST = "FETCH_WORK_REST_REQUEST";
export const FETCH_WORK_REST = "FETCH_WORK_REST";
export const FETCH_LOCATION_REQUEST = "FETCH_LOCATION_REQUEST";
export const FETCH_LOCATION_HISTORY = "FETCH_LOCATION_HISTORY";
export const FETCH_LOCATION_HISTORY_ERROR = "FETCH_LOCATION_HISTORY_ERROR";
export const FETCH_PDF_REQUEST = "FETCH_PDF_REQUEST";
export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST";
export const ADD_ACTIVITY_REQUEST = "ADD_ACTIVITY_REQUEST";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";

export const fetchShiftsStart = (resolve, reject, query) => ({
  type: FETCH_SHIFTS_START,
  query,
  resolve,
  reject,
});

export const fetchShiftsSuccess = (shifts) => ({
  type: FETCH_SHIFTS_SUCCESS,
  payload: shifts,
});

export const fetchShiftsFailure = (error) => ({
  type: FETCH_SHIFTS_FAILURE,
  payload: error,
});

export const selectShiftRequest = (resolve, reject, driverId, shiftId) => ({
  type: SELECT_SHIFT_REQUEST,
  driverId,
  shiftId,
  resolve,
  reject,
});

export const selectShiftSuccess = (shift) => ({
  type: SELECT_SHIFT,
  payload: shift,
});

export const fetchDriverRequest = (resolve, reject) => ({
  type: FETCH_DRIVER_REQUEST,
  resolve,
  reject,
});
export const fetchDriver = (data) => ({
  type: FETCH_DRIVER,
  payload: data,
});

export const fetchContractorRequest = (resolve, reject) => ({
  type: FETCH_CONTRACTOR_REQUEST,
  resolve,
  reject,
});
export const fetchContractor = (data) => ({
  type: FETCH_CONTRACTOR,
  payload: data,
});

export const fetchVehicleRequest = (resolve, reject) => ({
  type: FETCH_VEHICLE_REQUEST,
  resolve,
  reject,
});
export const fetchVehicle = (data) => ({
  type: FETCH_VEHICLE,
  payload: data,
});

export const fetchWorkRestRequest = (resolve, reject, driverId, startDate, endDate) => ({
  type: FETCH_WORK_REST_REQUEST,
  driverId,
  startDate,
  endDate,
  resolve,
  reject,
});
export const fetchWorkRest = (data) => ({
  type: FETCH_WORK_REST,
  payload: data,
});

export const fetchLocationRequest = (resolve, reject, vehicleId, beginTime, endTime) => ({
  type: FETCH_LOCATION_REQUEST,
  vehicleId,
  beginTime,
  endTime,
  resolve,
  reject,
});

export const fetchLocationHistory = (data) => ({
  type: FETCH_LOCATION_HISTORY,
  payload: data,
});

export const fetchLocationHistoryError = (data) => ({
  type: FETCH_LOCATION_HISTORY_ERROR,
  payload: data,
});

export const fetchPDFRequest = (id, resolve, reject) => ({
  type: FETCH_PDF_REQUEST,
  id,
  resolve,
  reject,
});

export const updateActivityRequest = (resolve, reject, id, data) => ({
  type: UPDATE_ACTIVITY_REQUEST,
  id,
  data,
  resolve,
  reject,
});

export const addActivityRequest = (resolve, reject, data) => ({
  type: ADD_ACTIVITY_REQUEST,
  data,
  resolve,
  reject,
});

export const updateActivity = (data) => ({
  type: UPDATE_ACTIVITY,
  payload: data,
});

import asyncComponent from "Common/helpers/AsyncFunc";

const driverSignuproutes = [
  // {
  //   path: "driver-signup/create",
  //   component: asyncComponent(() =>
  //     import("DriverSignup/containers/CreateDriver")
  //   ),
  //   scope: "write:users:driver"
  // },
  {
    path: "driver-signup/:id/edit",
    component: asyncComponent(() =>
      import("DriverSignup/containers/EditDriver")
    ),
    scope: "context:system"
  },
  {
    path: "driver-signup",
    component: asyncComponent(() =>
      import("DriverSignup/containers/ListDriver")
    ),
    scope: "context:system"
  },
  {
    path: "driver-signup/:id",
    component: asyncComponent(() =>
      import("DriverSignup/containers/RetrieveDriver")
    ),
    scope: "context:system"
  }
];

export default driverSignuproutes;

import { takeEvery, call,put, all, fork} from 'redux-saga/effects';
import actions from 'Fuel/redux/actions';
import  Api  from 'Common/api/Api';

const api = new Api();


export function* fetchFueltList(){
    yield takeEvery(actions.FETCH_FUEL_LIST_REQUEST, function*({resolve, reject}){
        try{
            const response = yield call(api.get, "fuels", {});
            if(resolve){
                yield call(resolve, response);
            } else {
                yield put({
                    type:actions.FETCH_FUEL_LIST_SUCCESS,
                    response:response.data.results
                });
            }
        } catch (error){
            if(reject){
                yield call(reject, error);
            } else {
                yield put({
                    type:actions.FETCH_FUEL_LIST_FAIL,
                    response:error
                });
            }
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(fetchFueltList),
    ]);
}

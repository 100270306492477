import asyncComponent from "Common/helpers/AsyncFunc";

const routeroutes = [
  {
    path:'routes/create',
    component: asyncComponent(() => import ('Route/containers/CreateRoute')),
    scope: 'write:routes'
  },
  {
    path: 'routes',
    component:asyncComponent(() => import ('Route/containers/ListRoute')),
    scope:'read:routes'
  },
  {
  path: 'routes/:id',
  component:asyncComponent(() => import ('Route/containers/RetrieveRoute')),
  scope:'read:routes'
  },
  {
  path: 'routes/:id/edit',
  component:asyncComponent(() => import('Route/containers/EditRoute')),
  scope:'write:routes'
  }
]

export default routeroutes;

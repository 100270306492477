const actions = {
    CREATE_LOCATION_REQUEST: 'CREATE_LOCATION_REQUEST',
    CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
    CREATE_LOCATION_ERROR: 'CREATE_LOCATION_ERROR',
    DELETE_LOCATION_REQUEST: 'DELETE_LOCATION_REQUEST',
    FETCH_LOCATION_LIST_REQUEST: 'FETCH_LOCATION_LIST_REQUEST',
    FETCH_LOCATION_LIST_SUCCESS: 'FETCH_LOCATION_LIST_SUCCESS',
    FETCH_LOCATION_LIST_FAIL: 'FETCH_LOCATION_LIST_FAIL',
    FETCH_LOCATION_DETAIL_REQUEST: 'FETCH_LOCATION_DETAIL_REQUEST',
    EDIT_LOCATION_DETAIL_REQUEST: 'EDIT_LOCATION_DETAIL_REQUEST',
    createLocation: (data,context,resolve,reject) => ({
      type: actions.CREATE_LOCATION_REQUEST,
      data,
      context,
      resolve,
      reject
    }),
    fetchLocationLists: (data, resolve,reject) => ({
      type: actions.FETCH_LOCATION_LIST_REQUEST,
      data,
      resolve,
      reject
    }),
    fetchLocationDetail: (id, resolve, reject) => ({
      type: actions.FETCH_LOCATION_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }),
    userLocationEdit: (data,id,resolve,reject) => ({
      type: actions.EDIT_LOCATION_DETAIL_REQUEST,
      data,
      id,
      resolve,
      reject
    }),
    deleteLocation: (id,resolve,reject) => ({
      type: actions.DELETE_LOCATION_REQUEST,
      id,
      resolve,
      reject
    }),
  };
  export default actions;


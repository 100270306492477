import styled from 'styled-components';

const LayoutContentStyle = styled.div`
  width: 100%;
  background-color: #ffffff;
  height: auto;
  margin-bottom: 20px;
  tr th{
    padding: 16px 6px !important;
  }
`;

export default LayoutContentStyle;

const actions = {
  CREATE_RUNS_REQUEST:'CREATE_RUNS_REQUEST',
  RETRIEVE_RUNS_REQUEST:'RETRIEVE_RUNS_REQUEST',
  UPDATE_RUNS_REQUEST:'UPDATE_RUNS_REQUEST',
  DELETE_RUNS_REQUEST:'DELETE_RUNS_REQUEST',
  LIST_RUNS_REQUEST: "LIST_RUNS_REQUEST",
  LIST_RUNS_SUCCESS: "LIST_RUNS_SUCCESS",
  LIST_RUNS_FAILURE: "LIST_RUNS_FAILURE",
  LIST_RUNS_LOCATION_REQUEST: "LIST_RUNS_LOCATION_REQUEST",
  LIST_RUNS_LOCATION_SUCCESS: "LIST_RUNS_LOCATION_SUCCESS",
  LIST_RUNS_LOCATION_FAILED: "LIST_RUNS_LOCATION_FAILED",

  createRun:(data,context,resolve,reject) => ({
      type:actions.CREATE_RUNS_REQUEST,
      data,
      context,
      resolve,
      reject
  }),
  retrieveRun:(id,resolve,reject) => ({
      type:actions.RETRIEVE_RUNS_REQUEST,
      id,
      resolve,
      reject
  }),
  updateRun:(data,id,resolve,reject) => ({
      type:actions.UPDATE_RUNS_REQUEST,
      data,
      id,
      resolve,
      reject
  }),
  deleteRun:(id,resolve,reject) =>({
      type:actions.DELETE_RUNS_REQUEST,
      id,
      resolve,
      reject
  }),
  listRun: () => ({
    type: actions.LIST_RUNS_REQUEST
  }),
  listRunLocation: () => ({
    type: actions.LIST_RUNS_LOCATION_REQUEST
  })
};
export default actions;

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_ANNOTATION_REQUEST,
  FETCH_DRIVERS_REQUEST,
  FETCH_EWD_PDF,
  FETCH_EWD_REQUEST,
  fetchAnnotation,
  fetchDrivers,
  fetchEWD,
} from "./actions";
import Api from "Common/api/Api2";

const api = new Api();

function* fetchDriversSaga() {
  yield takeEvery(FETCH_DRIVERS_REQUEST, function* ({ resolve, reject }) {
    try {
      const response = yield call(api.get, "driver/dropdown/all");
      if (resolve) yield put(fetchDrivers(response?.data));
    } catch (err) {
      if (reject) yield put(fetchDrivers([]));
    }
  });
}

function* fetchAnnotationSaga() {
  yield takeEvery(FETCH_ANNOTATION_REQUEST, function* ({ resolve, reject, driverId }) {
    try {
      const response = yield call(api.get, `ewd/annotation?driverId=${driverId}`);
      if (resolve) yield put(fetchAnnotation(response?.data));
    } catch (err) {
      if (reject) yield put(fetchAnnotation([]));
    }
  });
}

function* fetchEWDSaga() {
  yield takeEvery(FETCH_EWD_REQUEST, function* ({ resolve, reject, driverId, startDate, endDate }) {
    try {
      const response = yield call(api.get, `ewd/workRestHour/${driverId}?startDate=${startDate}&endDate=${endDate}`);
      if (resolve) yield put(fetchEWD(response?.data));
    } catch (err) {
      if (reject) yield put(fetchEWD(null));
    }
  });
}

function* fetchEwdPDFSaga() {
  yield takeEvery(FETCH_EWD_PDF, function* ({ resolve, reject, driverId, startDate, endDate }) {
    try {
      const response = yield call(api.getBlob, `ewd/${driverId}/download?startDate=${startDate}&endDate=${endDate}`);
      if (resolve) yield call(resolve, response);
    } catch (error) {
      if (reject) yield call(reject, error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchDriversSaga), fork(fetchAnnotationSaga), fork(fetchEWDSaga), fork(fetchEwdPDFSaga)]);
}

import asyncComponent from "Common/helpers/AsyncFunc";

const supplierroutes = [
    {
        path: 'suppliers/create',
        component: asyncComponent(() => import('Supplier/containers/CreateSupplier')),
        scope: 'write:bookings'
    },
    {
        path: 'suppliers',
        component: asyncComponent(() => import('Supplier/containers/ListSupplier')),
        scope: 'read:bookings'
    },
    {
        path: "suppliers/:id",
        component: asyncComponent(() => import('Supplier/containers/RetrieveSupplier')),
        scope: 'read:bookings'
    },
    {
        path: "suppliers/:id/edit",
        component: asyncComponent(() => import("Supplier/containers/EditSupplier")),
        scope: "write:bookings"
    }
]

export default supplierroutes;

import { Select } from 'antd';
import { AntSelect } from 'Common/styles/select.style';
import WithDirection from 'App/settings/withDirection';

const WDSelect = AntSelect(Select);
const isoSelect = WithDirection(WDSelect);
const SelectOption = Select.Option;

export default isoSelect;
export { SelectOption };

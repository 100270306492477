const maintenanceoptions = [
  {
    key: "maintenance/type/all",
    label: "maintenance.upcoming",
    leftIcon: "ri-tools-fill",
    scope: "read:users",
  },
  {
    key: "maintenance/type/Repairs",
    label: "maintenance.repair",
    leftIcon: "ri-calendar-todo-line",
    scope: "read:users",
  },
  {
    key: "faults/type/all",
    label: "sidebar.fault",
    leftIcon: "ri-error-warning-fill",
    scope: "read:users",
  },

  {
    key: "maintenance/type/Services",
    label: "sidebar.service",
    leftIcon: "ri-hammer-line",
    scope: "read:users",
  },
];
export default maintenanceoptions;


const actions = {
    CREATE_ALLOCATION_REQUEST: 'CREATE_ALLOCATION_REQUEST',
    UPDATE_ALLOCATION_REQUEST:'UPDATE_ALLOCATION_REQUEST',

    
    LIST_VEHICLES_CALENDAR_EVENTS_REQUEST: 'LIST_VEHICLES_CALENDAR_EVENTS_REQUEST',
    LIST_VEHICLES_CALENDAR_EVENTS_SUCCESS : 'LIST_VEHICLES_CALENDAR_EVENTS_SUCCESS',
    LIST_VEHICLES_CALENDAR_EVENTS_FAILED : 'LIST_VEHICLES_CALENDAR_EVENTS_FAILED',

    LIST_DRIVERS_CALENDAR_EVENTS_REQUEST: 'LIST_DRIVERS_CALENDAR_EVENTS_REQUEST',
    LIST_DRIVERS_CALENDAR_EVENTS_SUCCESS : 'LIST_DRIVERS_CALENDAR_EVENTS_SUCCESS',
    LIST_DRIVERS_CALENDAR_EVENTS_FAILED : 'LIST_DRIVERS_CALENDAR_EVENTS_FAILED',
    
    LIST_EVENTS_REQUEST : 'LIST_EVENTS_REQUEST',

    SET_ALLOCATIONS: 'SET_ALLOCATIONS',
    UNSET_ALLOCATIONS: 'UNSET_ALLOCATIONS',


    createAllocation: (data, context, resolve, reject) => ({
        type: actions.CREATE_ALLOCATION_REQUEST,
        data,
        context,
        resolve,
        reject
    }),
    updateAllocation:(id,data,resolve,reject) => ({
        type:actions.UPDATE_ALLOCATION_REQUEST,
        id,
        data,
        resolve,
        reject
    }),
    listVehiclesCalendarEvents: (period, resolve, reject) => ({
        type: actions.LIST_VEHICLES_CALENDAR_EVENTS_REQUEST,
        period,
        resolve,
        reject
    }),
    listDriversCalendarEvents: (period, resolve, reject) => ({
        type: actions.LIST_DRIVERS_CALENDAR_EVENTS_REQUEST,
        period,
        resolve,
        reject
    }),
    setAllocations: (allocations) => ({
        type: actions.SET_ALLOCATIONS,
        allocations
    }),
    unsetAllocations: () => ({
        type: actions.UNSET_ALLOCATIONS
    })
};


export default actions;
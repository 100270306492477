const validate = values => {
    const errors = {};
    if(values.bookingStop && values.bookingStop.length){
      const bookingStopArrayErrors = [];
      values.bookingStop.forEach((bookingStop, index) => {
        const bookingStopErrors = {};
        if(!bookingStop || !bookingStop.location || !bookingStop.location.id || !bookingStop.location.id.key){
          bookingStopErrors.location = { id: "This field is required" }
        }
        bookingStopArrayErrors[index] = bookingStopErrors;
      });
      if(bookingStopArrayErrors.length){
        errors.bookingStop = bookingStopArrayErrors;
      }
    }

    if (values.startMeterReading && values.startMeterReading.speedoMeter == 0) {
      errors.startMeterReading = {
        speedoMeter: 'Start odometer Reading cannot be 0 or empty',
      };
    }
  
    if (values.endMeterReading && values.endMeterReading.speedoMeter == 0) {
      errors.endMeterReading = {
        speedoMeter: 'End odometer Reading cannot be 0 or empty',
      };
    }
    return errors;
  }  
  export default validate;
    
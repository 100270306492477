import React from 'react';
import {Row, Col} from 'antd';
import ItemSummaryWrapper from './styles/index.style';
import moment from "moment";
import { localizeDate } from "Common/helpers/utility";
import { Tooltip } from "antd";

const placeholder = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

const getStatusIcon = (getStatus, status) => {
    if(getStatus) {
        if(status) {
            let driverLastApiCallTime = localizeDate(status).format("YYYY-MM-DD HH:mm:ss");
            let currentTime = moment();
            if(currentTime.diff(driverLastApiCallTime, 'hour') <= 1) {
                return svgIcon('green', driverLastApiCallTime);
            }
            if (currentTime.diff(driverLastApiCallTime, 'hour') > 1 && currentTime.diff(driverLastApiCallTime, 'hour')  <= 5) {
                return svgIcon('orange', driverLastApiCallTime);
            }
        }
        return svgIcon('red',  status ? localizeDate(status).format("YYYY-MM-DD HH:mm:ss") : 'Never');
    }
}

const svgIcon = (color, lastUsed) => {
    return <div className="activity-status">
            <Tooltip placement="top" title={'Last Seen at: ' + lastUsed}>
                <svg height="15" width="15">
                    <circle cx="5" cy="5" r="5" fill={color} />
                </svg>
            </Tooltip>
          </div>
}

const ItemSummary = ({avatar,title,subtitle, getStatus = false, status = null}) => (
    <ItemSummaryWrapper>
        <Row gutter={8}>
            <Col span={4}>
                <img
                    alt="gravatar"
                    style={{ width: 40, height: 40 , borderRadius:'50%'}}
                    src={avatar || placeholder}
                />
                { getStatusIcon(getStatus, status) }
            </Col>
            <Col span={12}>
              <h4>{title}</h4>
              <div>{subtitle}</div>
            </Col>
        </Row>
    </ItemSummaryWrapper>
);
export default ItemSummary;
import React from "react";
import { Alert, DatePicker } from "antd";
import moment from "moment";

const DatePickerField = ({
  input: { onChange, value },
  disablePrev,
  type,
  reset,
  meta: { touched, error },
  ...custom
}) => {
  const hasError = touched && error !== undefined;

  const disabledDate = (current) => {
    // var days = 1;
    // return current && (current.valueOf() < (Date.now() - days * 24 * 60 * 60 * 1000));
    // return  current < moment().startOf('day');
    // return current && current < moment().endOf('day');
    return current && current.valueOf() > Date.now();
  };

  return (
    <div className={hasError ? "has-error" : ""}>
      <DatePicker
        disabledDate={disabledDate}
        format={"DD/MM/YYYY"}
        onChange={onChange}
        value={!value ? value : moment(value)}
        {...custom}
      />

      {hasError && (
        <Alert className="alert" type="error" message={error} banner />
      )}
    </div>
  );
};

export default DatePickerField;

const actions = {
  LIST_NOTIFICATIONS: "LIST_NOTIFICATIONS",
  RECORD_NOTIFICATION_ACTIONS: " RECORD_NOTIFICATION_ACTIONS",
  MARK_FALSE_NOTIFICATIONS: "MARK_FALSE_NOTIFICATIONS",
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",

  fetchNotifications(resolve, reject) {
    return {
      type: actions.FETCH_NOTIFICATIONS,
      resolve,
      reject,
    };
  },

  listNotifications(page, filters, resolve, reject) {
    return {
      type: actions.LIST_NOTIFICATIONS,
      page,
      filters,
      resolve,
      reject,
    };
  },

  markFalseNotification(notificationId,status, resolve, reject) {
    return {
      type: actions.MARK_FALSE_NOTIFICATIONS,
      notificationId,
      status,
      resolve,
      reject,
    };
  },

  recordNotificationActions(payload, resolve, reject) {
    return {
      type: actions.RECORD_NOTIFICATION_ACTIONS,
      payload,
      resolve,
      reject,
    };
  },
};
export default actions;

import asyncComponent from "Common/helpers/AsyncFunc";

const assetsroutes = [
    {
        path: 'assets/create',
        component: asyncComponent(() => import('Assets/containers/CreateAssets')),
        scope: 'write:users'
    },
    {
        path: 'assets',
        component: asyncComponent(() => import('Assets/containers/ListAssets')),
        scope: 'read:users'
    },
    {
        path: 'assets/:id',
        component: asyncComponent(() => import('Assets/containers/RetrieveAssets')),
        scope: 'read:users'
    },
    {
        path: 'assets/:id/edit',
        component: asyncComponent(() => import('Assets/containers/EditAssets')),
        scope: 'write:users'
    }
]

export default assetsroutes;

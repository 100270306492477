import styled from "styled-components";

const LayoutContentWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  .ant-radio-inner {
    width: 12px;
    height: 12px;
  }
  .ant-radio-checked .ant-radio-inner{
    border-color:unset;
  }
h2{
	font-size:20px;
	color:#353E51;
	margin:25px 0;
}
span{
  font-weight:600;
}
button.ant-btn-primary span{
 color:#fff;
}

.ant-radio-wrapper{
  background: #FFFFFF;
  border-radius: 20px;
  padding:3px 10px;
  margin-top:4px;
  span{
    padding:3px;
  }
}
.activeradio{
  border: 1px solid #128239;
  span{
    color:#128239;
  }
  .ant-radio-inner {
    background-color:#128239;
  }
}
.activeradio.ant-radio-checked .ant-radio-inner {
  border-color: #128239  !important;
  span{
    padding:3px;
  }
}
.inactiveradio{
  border: 1px solid #C91827;
  span{
    color:#C91827;
  }
  .ant-radio-inner {
    background-color:#C91827;
  }
}
.inactiveradio.ant-radio-checked .ant-radio-inner {
 border-color: #C91827;
}
.activeradio.ant-radio-checked .ant-radio-inner {
 border-color: #128239;
}
.ant-radio-wrapper:hover .ant-radio .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-focused .ant-radio-inner {
  border-color: unset;
}
.inactiveradio .ant-radio-inner:after,.inactiveradio .ant-radio-checked:after {
  background-color: #c93d55;
}
.inactiveradio.ant-radio-wrapper-checked{
background-color:#F4D1D4;
}
.activeradio .ant-radio-inner:after,.activeradio .ant-radio-checked:after {
      background-color: #128239;
  }
  .activeradio.ant-radio-wrapper-checked{
    background-color:#D0E6D7;
  }
// .ant-col-sm-24:nth-child(even) .isoCardWidget{
//    background-color:#FEF4E6;
// }
// .ant-col-sm-24:nth-child(odd) .isoCardWidget{
//    background-color:#E8EDF9;
// }
  @media only screen and (max-width: 767px) {
    padding: 20px 20px;
  }

  @media (max-width: 580px) {
    padding: 15px;
  }
  .ant-tabs{
    width:100%;
  }
  .rbc-time-header{
    // display:none !important;
  }
  .rbc-toolbar{
    // display:none !important;
  }
  h1.activitytitle{
    display: flex;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb;
    writing-mode: vertical-lr;
    min-height: 514px;
    vertical-align: middle;
    justify-content: center;
    transform: rotate(180deg);
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
  }
  .ant-table-pagination.ant-pagination{
    float:left;
  }
  .ant-pagination-item{
    border:none;
  }
  .ant-pagination-item-active{
    background: #128239;
    border-radius: 10px;
    border-color: #128239;
  }
   .ant-pagination-item-active:hover a, .ant-pagination-item-active a {
     color:#ffffff !important;
  }
  .ant-pagination-item:focus a, .ant-pagination-item:hover a{
    color:#128239;
  }
  .ant-pagination-item a{
    color:#808692;
  }
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border:none;
    color:#128239;
  }
  .topButton{
      text-align:right;
      width:100%;
      margin-bottom:25px;
      display: flex; 
      align-items: center; 
      justify-content: flex-end;
      .ant-btn-primary{
        background-color: #128239;
        border-color: #128239;
        border-radius: 4px;
      }
       button{
         margin-right:15px;
         padding: 0 14px !important;
         display: flex;
         align-items: center; 
         justify-content: center;
       }
       button:last-child{
        margin-right:0px;
      }
      .ant-btn-danger {
        background-color: #FAE8EA;
        border: none;
        color: #C91827;
        &:hover{
            background-color: #e4c5c8;
            border: none;
        }
      }
      .lightBlue {
        background-color: #E8EDF9;
        border: none;
        color: #1548BF;
        &:hover{
          background-color: #d5dcec;
          border: none;
          color: #1548BF;
      }
      }
  }
  .responsiveButtonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media (max-width: 600px) {
  .responsiveButtonContainer {
    flex-direction: column;
    align-items: stretch;
  }
  .topButton button {
    width: 100%;
  }
}

  .gray-btn{
    background-color: #E6E7EA;
    color:#4E5768;
    float:left;
  }
  span{
    font-weight:600;
  }
  // .ant-row{
  //   margin-bottom:25px;
  // }
 `;


const DiaryBlock = styled.div`
  border-top:2px solid #9b9494;
  width: 100%;
  padding: 0;
  font-size:13px;

  &.timefooter {
    .inner-block {
      padding-top: 0px!important;
    }
  }

.inner-section{
   width:130px;
   float:left;
   padding:45px 10px 0px 10px;
   font-weight:bold;
   text-align:right;
}
.inner-block{
  width:4.16%;
  border-right:2px solid #9b9494;
  display: flex;
}
.inner-block:last-child{
border:none;
}
.slot-block{
  width: 1.5%;
  border:1px solid #ffffff;
  padding:41px 0;
}
.wrap-block{
  border-left:2px solid #9b9494;  
  border-right:2px solid #9b9494;  
 }
.slot-block:nth-child(4n+4){
  border-right:2px solid #9b9494;
}
.slot-block:last-child{
  border-right:none;
}
.slot-block:first-child{
  border-left:1px solid #9b9494 !important;
}
.inner-text{
    display:flex;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb;
    writing-mode: vertical-lr;
    vertical-align: middle;
    justify-content: center;
    transform: rotate(180deg);
    font-size: 13px;
    line-height:22px;
 }
 .timeWrap{
  border-bottom:2px solid #9b9494;
 }
`;

const DriverWrapper = styled.div`
color:#221e1e;
margin-bottom:25px;
width:100%;
 h2{
    background-color:#9f9f9f;
    padding:10px;
    text-align:center;
    color:#fff;
    font-size:14px;
    text-transform:uppercase;
 }
 .blockbox.box{
    padding:0;
 }
 .blockbox{
     border:1px solid #787373;
     padding: 7px;
     margin-bottom: 10px;
     height: 35px;
     span{
        font-size:16px;
     }
     .weekblock{
        float: left;
        width: 14.28%;
        border-right: 1px solid #444;
        text-align: center;
        height: 35px;
        padding: 5px;

        .selectedWeek{
          border: 2px solid #008000;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          display: block;
        }
     }
     .timeblock{
          position: relative;
          float:left;
          width: 12.5%;
          border-right: 1px solid #444;
          text-align: center;
          height: 35px;
          padding: 5px;

          i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 36px;
            color: #008000;
            opacity: 0.6;
          }
        }
    }
`;
const TotalWrapper = styled.div`
 font-size:11px;
 font-weight:bold;
 text-align:right;
   .totalwrapper{
      padding:4px;
      border-top:2px solid #9b9494;
      border-right:2px solid #9b9494;
      word-break: break-word;
      span{
        font-size:12px;
      }
      .totalhour{
        text-align: center;
        padding-top: 16px;
        font-size: 16px;
        word-break:break-word
  }
  
`;


export { LayoutContentWrapper, DiaryBlock, DriverWrapper, TotalWrapper };

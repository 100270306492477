import actions from './actions';

const initialState = {
  routes:[],
  loading:false,
  error:null
}
export default (state=initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ROUTE_LIST_REQUEST:
      return {
        ...state,
        loading:true,
      }
    case actions.FETCH_ROUTE_LIST_SUCCESS:
      return {
        ...state,
        loading:false,
        routes:action.response
      }
    case actions.FETCH_ROUTE_LIST_FAIL:
      return {
        ...state,
        loading:false,
        error:action.error
      }
    default:
      return state;

  }
}

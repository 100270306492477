import React, { Component } from "react";
import PermissionRoute from "App/settings/PermissionRoute";
import routes from 'Common/routes';
import {Switch} from 'react-router-dom';

class AppRouter extends Component {
  render() {
    return (
      <Switch>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <PermissionRoute
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </Switch>
    );
  }
}

export default AppRouter;

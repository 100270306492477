import { takeEvery, call, put, all, fork } from "redux-saga/effects";
import { push } from 'react-router-redux';
import actions from 'Contact/redux/actions';
import Api from 'Common/api/Api';
import Api2 from "Common/api/Api2";

const api = new Api();
const api2 = new Api2();

export function* createContact() {
  yield takeEvery(actions.CREATE_CONTACT_REQUEST, function*({data,context,resolve,reject}) {
    try{
      const response = yield call(api.post, 'contacts',data);
      yield call(resolve,response);
      if(context === 'save'){
      yield (put(push('/company/'+response.data.id)))
      }
    }
    catch(error){
      yield call(reject,error);
    }
  });
}

export function* retrieveContact() {
  yield takeEvery(actions.RETRIEVE_CONTACT_REQUEST, function*({id, resolve, reject}) {
    try{
      const response = yield call(api.get, 'contacts/'+ id, {})
      yield call(resolve,response);
    }
    catch(error) {
      yield call(reject,error);
    }
  });
}

export function* listContact(){
  yield takeEvery(actions.LIST_CONTACT_REQUEST,function*({resolve, reject}){
    try{
      const contactResponse = resolve ? 'contacts' : 'contacts?pageSize=-1';
      const response = yield call(api.get,contactResponse,{});
      if(resolve){
        yield call(resolve,response);
      } else {
        yield put({
          type: actions.LIST_CONTACT_SUCCESS,
          response: response.data.results
        });
      }
    }catch(error){
      if(reject){
        yield call(reject,error);
      } else {
        yield put({
          type: actions.LIST_CONTACT_FAILURE,
          error
        });
      }
    }
  })
}

export function* deleteContact() {
  yield takeEvery(actions.DELETE_CONTACT_REQUEST, function*({id,resolve,reject}){
    try{
      const response = yield call(api.delete,'contacts/'+ id, {})
      yield call(resolve,response);
      yield put(push('/company'));
    }catch(error){
      yield call(reject,error);
    }
  });
}

export function* updateContact() {
  yield takeEvery(actions.UPDATE_CONTACT_REQUEST, function*({data, id, resolve,reject}){
    try{
      const response = yield call(api.update,'contacts/' + id, data)
      yield call(resolve,response);
      yield put(push('/company/'+ response.data.id));
    }catch(error){
      yield call(reject,error);
    }
  });

}

export function* createCompanyTelematics() {
  yield takeEvery(actions.CREATE_COMPANY_TELEMATICS_REQUEST, function* ({ companyId,data,resolve, reject  }) {
    try {
      const response = yield call(api2.post, "telematics/companyTelematicsInfo/"+companyId, data);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* updateCompanyTelematics() {
  yield takeEvery(actions.UPDATE_COMPANY_TELEMATICS_REQUEST, function* ({ data,companyId,resolve, reject  }) {
    try {
      const response = yield call(api2.update, "telematics/companyTelematicsInfo/"+companyId, data);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export function* getCompanyTelematics() {
  yield takeEvery(actions.RETRIEVE_COMPANY_TELEMATICS_REQUEST, function* ({ companyId,resolve, reject  }) {
    try {
      const response = yield call(api2.get, "telematics/companyTelematicsInfo/"+companyId);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}
export function* deleteCompanyTelematics() {
  yield takeEvery(actions.DELETE_COMPANY_TELEMATICS_REQUEST, function* ({ companyId,platform,resolve, reject  }) {
    try {
      const response = yield call(api2.delete, "telematics/companyTelematicsInfo/"+companyId+"?platform=" + platform);
      if (resolve) {
        yield call(resolve, response);
      }
    } catch (error) {
      if (reject) {
        yield call(reject, error);
      }
    }
  });
}

export default function *rootSaga() {
    yield all ([
      fork(createContact),
      fork(retrieveContact),
      fork(deleteContact),
      fork(updateContact),
      fork(listContact),
      fork(createCompanyTelematics),
      fork(updateCompanyTelematics),
      fork(getCompanyTelematics),
      fork(deleteCompanyTelematics),
    ]);
}

import React,{ Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { message } from 'antd';
import toTitleCase from 'Common/toTitleCase';
import LayoutWrapper from 'Common/components/layoutWrapper.js';
import LayoutContent from 'Common/components/layoutContent';
import PageHeader from 'Common/components/Page/PageHeader';
import IntlMessages from 'Common/components/intlMessages';
import ItemStatus from 'Common/components/ItemSummary/status';
import ItemTag from 'Common/components/ItemSummary/tag';
import { ContactSection } from 'Common/components/Table/styles/responsiveTableWrapper.style';
import TableWrapper from "Common/components/Table/index";
import Protected from "Common/components/authorization/Protected";
import getProtectedColumn from "Common/components/authorization/ProtectedColumn";
import actions from 'Contact/redux/actions';
import actionsDropdown from 'Dropdown/redux/actions';
import {ContactName} from 'Contact/settings/contactType';


const { deleteContact, listContact } = actions;
const { listDropdown } = actionsDropdown;

const sorter = (a, b) => ('' + a.attr).localeCompare(b.attr);

const breadcrumbs = [{
  title: 'Dashboard',
  path: '/dashboard'
},
{
  title:'Company',
}]

const defaultQuickFilter = {
  filterBy: "contactType.id",
  defaultValue: "all",
  options: [{
    tab: "All Company",
    key: "all"
  }]
};

function capitalizeFirstLetter(capitalString) {
    const lowerCaseString = capitalString.toLowerCase();
    const requiredFormString = lowerCaseString == "clientindividual" ? lowerCaseString.replace("clientindividual","Client Individual") : lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
    return requiredFormString;
}

const getquickFilter = (contactTypes) => {
   let newQuickFilterOptions = defaultQuickFilter['options'];
   for(let contactType of contactTypes){
     newQuickFilterOptions = newQuickFilterOptions.concat({
       tab:capitalizeFirstLetter(contactType.title),
       key:contactType.id
     })
   }
  return {
    ...defaultQuickFilter,
    options:newQuickFilterOptions
  }
}

class ListContact extends Component{
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      updatedOn: moment().valueOf(),
      status: "all"
    }
  }

  componentWillMount(){
    if(!this.props.data.length && !this.props.loading){
      this.props.listDropdown();
    }
    this.setState({
      status:this.props.match.params.id,
    })
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      subType:nextProps.match.params.id,
    })
  }

  filterContactTypes = (type, dropdownDatas) => {
      const contactTypes = dropdownDatas.filter(dropdownData => {
         return dropdownData.dropdown == type;
      });
      return contactTypes;
  }

  onEditClicked = (id,e) => {
    e.preventDefault();
    this.props.history.push("/company/" + id + "/edit/")
  }

  confirm = (id,e) => {
    this.onDelete(id);
   }

  onDelete = (id) => {
    this.setState({loading:true});
    return new Promise((resolve, reject) => {
      this.props.deleteContact(id,resolve,reject);
    })
    .then(() => {
      this.setState({loading:false, updatedOn: moment().valueOf()});
      message.success("Successfully deleted company");
    }).catch(() => {
      message.error('Error while deleting contact');
    },this);
  }

  onCreateClicked = (e) => {
    e.preventDefault();
    this.props.history.push('/company/create');
  }

  handleRowClick = (record) => {
    this.props.history.push("/company/"+record.id);
  }; 

  getDefaultColumns = () => {
    return [
      {
        title: "S.N",
        key: "listNumber",
        render: (text, row, index) => {
          return <span>{ index + 1 }</span>;
        }
      },
      {
        title:'Name',
        key: 'firstName',
        render: (text, row) => {
          return <span>
            <ContactName contact={row}/>
          </span>
        }
    },
    {
      title:'Phone',
      key:'phoneNumber',
      dataIndex: 'phoneNumber'
    },
    {
      title:'Status',
      key:'status',
      sorter: {sorter},
      dataIndex:'status',
      render: status => <ItemStatus status={status}/>
    },
    {
      title:'Contact Type',
      key:'contactType',
      dataIndex:'contactType.title',
      render: text => <ItemTag type="usertype" value={toTitleCase(text)}/>
    }]
  }

  getColumns = () => {
     return getProtectedColumn(this.getDefaultColumns,this.onEditClicked,this.confirm,"contacts");
  }

  render(){
     const { loading } = this.state;
    return(
        <LayoutWrapper>
          <PageHeader title={<IntlMessages id="pagetitle.listcontact"/>} breadcrumbs={breadcrumbs}>
           <Protected scope="write:contacts">
              <p></p>
           </Protected>
          </PageHeader>
          <LayoutContent loading={loading}>
            <ContactSection>
              <TableWrapper
              defaultQuickFilterValue={this.props.match.params.id}
              history={this.props.history}
              handleRowClick={this.handleRowClick} 
              updatedOn={this.state.updatedOn} 
              columns={this.getColumns()} 
              quickFilterElements={getquickFilter(this.filterContactTypes("contactType",this.props.data))}
              url="contacts"
              createTitle="Create Company"
              quickFilterStatus={this.props.match.params.id}
              onClick={this.onCreateClicked} />
            </ContactSection>
          </LayoutContent>
      </LayoutWrapper>
        );
    }
}

export default connect(state=>({
  contacts:state.Contact.contacts,
  loading: state.Dropdown.loading,
  data:state.Dropdown.data
}),{
  listContact, deleteContact, listDropdown,
})
(ListContact);

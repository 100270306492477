import asyncComponent from "Common/helpers/AsyncFunc";

const reportroutes = [
  {
    path: "report",
    component: asyncComponent(() => import("Report/containers/ListReport")),
    scope: "read:vehicles"
  }, {
    path: "report/vehiclelist",
    component: asyncComponent(() => import("Report/containers/ListVehicleReport")),
    scope: "read:vehicles"
  },{
    path: "report/vehicleservice",
    component: asyncComponent(() => import("Report/containers/VehicleService")),
    scope: "read:vehicles"
  },{
    path: "report/vehiclesummary",
    component: asyncComponent(() => import("Report/containers/VehicleSummary")),
    scope: "read:vehicles"
  }, {
    path: "report/vehicle-inspection-summary",
    component: asyncComponent(() => import("Report/containers/ListVehicleInspection")),
    scope: "read:vehicles"
  }, {
    path: "report/vehicle-fault-list",
    component: asyncComponent(() => import("Report/containers/ ListVehicleFaults")),
    scope: "read:vehicles"
  }, {
    path: "report/fuelsummary",
    component: asyncComponent(() => import("Report/containers/FuelSummary")),
    scope: "read:vehicles"
  }, {
    path: "report/servicesummary",
    component: asyncComponent(() => import("Report/containers/ServiceSummary")),
    scope: "read:vehicles"
  }, {
    path: "report/driverlist",
    component: asyncComponent(() => import("Report/containers/ListDriver")),
    scope: "read:vehicles"
  }, {
    path: "report/driverfitforduty",
    component: asyncComponent(() => import("Report/containers/ListDriverFitForDuty")),
    scope: "read:vehicles"
  }, {
    path: "report/fatiguelist",
    component: asyncComponent(() => import("Report/containers/ListFatigue")),
    scope: "read:vehicles"
  }, {
    path: "report/incidentlist",
    component: asyncComponent(() => import("Report/containers/ListIncident")),
    scope: "write:vehicles"
  }, {
    path: "report/driverWorkHour",
    component: asyncComponent(() => import("Report/containers/ListDriverWorkHourReport")),
    scope: "read:bookings"
  }, {
    path: "report/jobAttachments",
    component: asyncComponent(() => import("Report/containers/ListJobAttachments")),
    scope: "read:bookings"
  }
];

export default reportroutes;

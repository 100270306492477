const actions = {
  CREATE_PASSENGER_REQUEST:'CREATE_PASSENGER_REQUEST',
  RETRIEVE_PASSENGER_REQUEST:'RETRIEVE_PASSENGER_REQUEST',
  UPDATE_PASSENGER_REQUEST:'UPDATE_PASSENGER_REQUEST',
  DELETE_PASSENGER_REQUEST:'DELETE_PASSENGER_REQUEST',
  
  createPassenger:(data,context,resolve,reject) => ({
      type:actions.CREATE_PASSENGER_REQUEST,
      data,
      context,
      resolve,
      reject
  }),
  retrievePassenger:(id,resolve,reject) => ({
      type:actions.RETRIEVE_PASSENGER_REQUEST,
      id,
      resolve,
      reject
  }),
  updatePassenger:(id,data,resolve,reject) => ({
      type:actions.UPDATE_PASSENGER_REQUEST,
      id,
      data,
      resolve,
      reject
  }),
  deletePassenger:(id,resolve,reject) =>({
      type:actions.DELETE_PASSENGER_REQUEST,
      id,
      resolve,
      reject
  }),
};
export default actions;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError, change, reset, formValueSelector} from 'redux-form';
import scriptLoader from 'react-async-load-script';

import {Spin} from 'antd';
import {Select, message, Row, Col, Alert} from 'antd';

import IntlMessages from 'Common/components/intlMessages';
import Button from 'Common/components/button';
import { ButtonWrappper } from 'Common/components/Form/styles/formWrapper.style';
import InputField from 'Common/components/InputField';
import SelectField from 'Common/components/SelectField';
import validate from 'Common/validate';

import CreateLocationStyledWrapper from 'Location/styles/createLocationStyleWrapper.style';
import LocationSearchBox from 'Location/components/LocationSearchBox';
import LocationMapView from 'Location/components/LocationMapView';

import actions from "Location/redux/actions";

const { createLocation,userLocationEdit } = actions;
const Option = Select.Option;
const FORM_NAME = "createLocation";
const selector = formValueSelector(FORM_NAME);


class LocationCreateForm extends Component {
	constructor(props){
	  super(props);
	  this.state = {
			loading: false
		}
	}
  componentDidMount = () => {
		if(!this.props.editingMode){
			this.props.initialize({
				geofenceRadius: "200",
				type: this.props.strictDefaultType || "",
				status:"active"
			});
		}
	}

	componentWillReceiveProps = (nextProps) => {
		if(nextProps.entity && nextProps.entity != this.props.entity){
			let entity = nextProps.entity;
			delete entity["id"];
			delete entity["geofenceId"];
			this.props.initialize(entity);
		}
	}

	componentWillUnmount(){
		this.props.dispatch(reset(FORM_NAME));
	}

	onSaveClick = (data) => {
		return this.handleLocationCreate(data,"save");
	}

	onSaveAndCreateClick = (data) => {
		return this.handleLocationCreate(data,"saveAndCreate").then(()=>{
			this.props.dispatch(reset(FORM_NAME));
		},this);
	}

	onEmbeddedSaveClick = (data) => {
		return this.handleLocationCreate(data,"embeddedSave").then((location)=>{
			this.props.onLocationCreated(location);
		},this);
	}

	handleLocationCreate = (data, context) => {
		this.setState({ loading: true });
		return new Promise((resolve, reject) => {
	  	this.props.createLocation(data,context,resolve,reject);
	  }).then((response) => {
		  this.setState({loading: false});
			message.success("Location created successfully");
			return response.data;
	  },(error) => {
		  this.setState({loading: false});
		  throw new SubmissionError(error);
	  },this);
	}

	handleLocationUpdate = (data) => {
		this.setState({ loading: true });
		return new Promise((resolve, reject) => {
			const id = this.props.entity_id;
			this.props.userLocationEdit(data,id,resolve,reject);
		}).then(() => {
			this.setState({loading: false});
			message.success('Location updated successfully');
			return true;
		}).catch((error) => {
			this.setState({loading: false});
			throw new SubmissionError(error);
		},this);
	}

	handleLocationSearchChange = (address, geolocation,type) => {
		this.props.dispatch(change(FORM_NAME,'address',address));
		this.props.dispatch(change(FORM_NAME,'geolocation',geolocation));
		this.props.dispatch(change(FORM_NAME,'type',type))
	}

	render() {
		const { handleSubmit, error, editingMode, embeddedMode} = this.props;
		return (
			<Spin spinning={!this.props.isScriptLoadSucceed}>
				<CreateLocationStyledWrapper>
					<Row gutter={30}>
						<Col lg={8} sm={24} md={24}>
							{this.props.isScriptLoadSucceed &&
								<div className='create-location-form-group'>
									<label><IntlMessages id="forms.Label.address" /></label>
									<LocationSearchBox
										handleLocationChange = {this.handleLocationSearchChange}
									/>
								</div>
							}
							<div className='create-location-form-group'>
								<label><IntlMessages id="forms.Label.name" /></label>
								<Field name="name" component={InputField} />
							</div>

							<div className='create-location-form-group'>
								<label><IntlMessages id="forms.Label.code" /></label>
								<Field name="code" component={InputField} />
							</div>

							<div className='create-location-form-group'>
								<label><IntlMessages id="forms.Label.type" /></label>
								<Field defaultValue=""  name="type" component={SelectField} disabled={this.props.strictDefaultType && true}>
									<Option value="">Select Location Type</Option>
									<Option value="stop">Stop</Option>
									<Option value="depot">Depot</Option>
								</Field>
							</div>

							<div className='create-location-form-group'>
							   	<label><IntlMessages id="forms.Label.status" /></label>
								<Field defaultValue="" name="status" component={SelectField}>
									<Option value="">Select Location Status</Option>
									<Option value="active">Active</Option>
									<Option value="inactive">Inactive</Option>
								</Field>
							</div>

							<div className='create-location-form-group'>
								<label><IntlMessages id="forms.Label.geofenceRadius" /></label>
								<Field
									name="geofenceRadius"
									component={InputField}
									type="number"
								/>
							</div>

							<div className='create-location-form-group'>
								<Field name="geolocation" type="hidden" component={InputField} />
							</div>

							<Row gutter={30}>
								<Col className="gutter-row" lg={24} md={24}>
									{
										error &&
										<Alert
										className="alert"
										type="error"
										message={error}
										banner />
									}
								</Col>
							</Row>

              <Row gutter={30}>
                <Col lg={24} md={24}>
                  <ButtonWrappper>
                    {editingMode ? (
                      <Button
                        type="primary"
                        className="save"
                        loading={this.state.loading}
                        onClick={handleSubmit(this.handleLocationUpdate.bind(this))
                      }
                      >
                        <IntlMessages id="forms.button.update" />
                      </Button>
                    ) : embeddedMode ? (
                      <Button
                        type="primary"
                        className="save"
                        loading={this.state.loading}
                        onClick={handleSubmit(this.onEmbeddedSaveClick.bind(this))
                      }
                      >
                        <IntlMessages id="forms.button.save" />
                      </Button>
                    ) : (
                      <div>
                        <Button
                          type="primary"
                          loading={this.state.loading}
                          className="save"
                          onClick={handleSubmit(this.onSaveClick.bind(this))}
                        >
                          <IntlMessages id="forms.button.save"/>
                        </Button>
                        <Button
                          loading={this.state.loading}
                          className="save-and-create-new"
                          onClick={handleSubmit(this.onSaveAndCreateClick.bind(this))}
                        >
                          <IntlMessages id="forms.button.saveAndCreateNew"/>
                        </Button>
                      </div>
                    )}
                  </ButtonWrappper>
                </Col>
              </Row>
						</Col>
						<Col lg={16} sm={24} md={24}>
							<div className="hereMap" style={{ width: '100%',height: '500px'}}>
								{this.props.isScriptLoadSucceed &&
									<LocationMapView
										geolocation = {this.props.geolocation}
										geofenceRadius = {this.props.geofenceRadius}
										type={this.props.type}
									/>
								}
							</div>
						</Col>
					</Row>
				</CreateLocationStyledWrapper>
			</Spin>
		)
	}
}

const LocationCreate = reduxForm({
	form: FORM_NAME,
	validate
})(LocationCreateForm);

export default scriptLoader([
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyBtILKnXre0jbA91S3p2ih_RLX8MMNbH0A&libraries=places'
])(connect(state => {
	const geofenceRadius = selector(state,'geofenceRadius');
	const address = selector(state,'address');
	const geolocation = selector(state,'geolocation');
	const type = selector(state,'type');
	return{
		geofenceRadius,
		address,
		geolocation,
		type
	}
} , {
	createLocation, userLocationEdit
})(LocationCreate));

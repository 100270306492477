import actions from './actions';
const initialState = {
  documents:[],
  error:null,
  document:null,
  loading:false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        loading:true,
      }
    case actions.FETCH_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        documents:action.response,
        loading:false,
      }
    case actions.FETCH_DOCUMENT_LIST_FAIL:
      return {
        ...state,
        loading:false,
      }
    default:
      return state;
  }
}

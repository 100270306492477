import asyncComponent from "Common/helpers/AsyncFunc";
import ListBooking from "Booking/containers/ListBooking";

const bookingroutes = [
  {
    path:'jobs/create',
    component: asyncComponent(() => import ('Booking/containers/CreateBooking')),
    scope: 'write:bookings'
  },
  {
    path: 'jobs',
    component:asyncComponent(() => import ('Booking/containers/ListBooking')),
    scope:'read:bookings'
  },
  {
  path: 'jobs/:id',
  component:asyncComponent(() => import ('Booking/containers/RetrieveBooking')),
  scope:'read:bookings'
  },
  {
  path: 'jobs/:id/edit',
  component:asyncComponent(() => import('Booking/containers/EditBooking')),
  scope:'write:bookings'
  },
  {
  path: "jobs/status/:status",
  component: ListBooking,
  scope: "read:bookings"
  },
]

export default bookingroutes;

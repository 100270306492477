const actions = {
  CREATE_BOOKING_REQUEST: 'CREATE_BOOKING_REQUEST',
  LIST_BOOKING_REQUEST: 'LIST_BOOKING_REQUEST',
  LIST_BOOKING_SUCCESS: 'LIST_BOOKING_SUCCESS',
  LIST_BOOKING_FAILED: 'LIST_BOOKING_FAILED',
  UPDATE_BOOKING_REQUEST: 'UPDATE_BOOKING_REQUEST',
  DELETE_BOOKING_REQUEST: 'DELETE_BOOKING_REQUEST',
  RETRIEVE_BOOKING_REQUEST: 'RETRIEVE_BOOKING_REQUEST',
  STORE_DATERANGE_BOOKING:'STORE_DATERANGE_BOOKING',
  STORE_POWERSEARCH_VALUE:'STORE_POWERSEARCH_VALUE',
  LIST_JOB_ATTACHMENT_REQUEST:'LIST_JOB_ATTACHMENT_REQUEST',
  UPDATE_METER_READING: 'UPDATE_METER_READING',

  createBooking:(data,context,resolve,reject) => ({
    type:actions.CREATE_BOOKING_REQUEST,
    data,
    context,
    resolve,
    reject
  }),  
  listJob: (resolve,reject, query) => ({
    type: actions.LIST_BOOKING_REQUEST,
    resolve,
    reject,
    query
  }),
  listJobAttachment: (driverId, startDateTime, endDateTime,pageSize,currentPage, resolve, reject) => ({
    type: actions.LIST_JOB_ATTACHMENT_REQUEST,
    driverId,
    startDateTime,
    endDateTime,
    pageSize,
    currentPage,
    resolve,
    reject
  }),
  deleteBooking: (id,resolve,reject) => ({
    type: actions.DELETE_BOOKING_REQUEST,
    id,
    resolve,
    reject
  }),
  retrieveBooking: (id, resolve, reject) => ({
    type:actions.RETRIEVE_BOOKING_REQUEST,
    id,
    resolve,
    reject
  }),
  updateBooking: (id, data, resolve,reject) => ({
    type: actions.UPDATE_BOOKING_REQUEST,
    id,
    data,
    resolve,
    reject
  }),
  storeDaterangeBooking: (startdate, enddate) => ({
    type: actions.STORE_DATERANGE_BOOKING,
    startdate,
    enddate
  }),
  storePowerSearchValue: (value) =>({
    type: actions.STORE_POWERSEARCH_VALUE,
    value
  }),
  updateMeterReading: (id,vehicleId, data, resolve,reject) => ({
    type: actions.UPDATE_METER_READING,
    id,
    vehicleId,
    data,
    resolve,
    reject
  }),


}
export default actions;
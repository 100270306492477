import styled from "styled-components";

const CreateLocationStyleWrapper = styled.div`
	margin: 0px 10px 0 0;
	
	.inputField{
		margin: 8px 0;
	}

	.action-buttons{
		margin-top: 15px;
		tex-align: right;

		button.save {
			display: inline-block;
		}

		button.save-and-create-new {
			margin-left: 15px;
			display: inline-block;
		}	
	}

	.search-field-wrap{
		position:relative;
	}


	.float-right{
		float: right;
	}
	@media 
	only screen and (max-width: 420px){
		button:not(:first-child) {
			margin: 10px 0 0 10px;
		}
		.hereMap{
			margin-top: 10px;
		}
	}
	

`;

export default CreateLocationStyleWrapper;


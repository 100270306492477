const actions = {
    CREATE_SUPPLIER_REQUEST: 'CREATE_SUPPLIER_REQUEST',
    FETCH_SUPPLIER_LIST_REQUEST: 'FETCH_SUPPLIER_LIST_REQUEST',
    FETCH_SUPPLIER_LIST_SUCCESS: "FETCH_SUPpLIER_LIST_SUCCESS",
    FETCH_SUPPLIER_LIST_FAIL: "FETCH_SUPPLIER_LIST_FAIL",

    RETRIEVE_SUPPLIER_REQUEST: "RETRIEVE_SUPPLIER_REQUEST",
    UPDATE_SUPPLIER_REQUEST: "UPDATE_SUPPLIER_REQUEST",

    DELETE_SUPPLIER_REQUEST: "DELETE_SUPPLIER_REQUEST",

    createSupplier: (data, context, resolve, reject) => {
        return ({
            type: actions.CREATE_SUPPLIER_REQUEST,
            data,
            context,
            resolve,
            reject
        })
    },
    fetchSuppliertList: (resolve, reject) => ({
        type: actions.FETCH_SUPPLIER_LIST_REQUEST,
        resolve,
        reject
    }),
    updateSupplier: (data, id, resolve, reject) => ({
        type: actions.UPDATE_SUPPLIER_REQUEST,
        data,
        id,
        resolve,
        reject
    }),
    retrieveSupplier: (id, resolve, reject) => {
        return {
            type: actions.RETRIEVE_SUPPLIER_REQUEST,
            id,
            resolve,
            reject
        }
    },
    deleteSupplier: (id, resolve, reject) => ({
        type: actions.DELETE_SUPPLIER_REQUEST,
        id,
        resolve,
        reject
    }),
};
export default actions;

import React from "react";
import ReactDOM from "react-dom";
import DashApp from "DashApp";
import { unregister } from "registerServiceWorker";
import { clarity } from "react-microsoft-clarity";
import "antd/dist/antd.css";

ReactDOM.render(<DashApp />, document.getElementById("root"));

//User session tracking in clarity
if (process.env.NODE_ENV === "development") {
  clarity.init("mvbqmqm0we");
}
if (process.env.NODE_ENV === "production") {
  clarity.init("mvbmt6fakj");
}
// Hot Module Replacement API
if (module.hot) {
  unregister();
  module.hot.accept("DashApp.js", () => {
    const NextApp = require("DashApp").default;
    ReactDOM.render(<NextApp />, document.getElementById("root"));
  });
}

// register();

import asyncComponent from "Common/helpers/AsyncFunc";

const driverroutes = [
    {
        path:'drivers/create',
        component: asyncComponent(() => import ('Driver/containers/CreateDriver')),
        scope: 'write:users:driver'
    },
    {
        path: "drivers/:id/edit",
        component: asyncComponent(() => import("Driver/containers/EditDriver")),
        scope: 'write:users:driver'
      },
      {
        path: "drivers",
        component: asyncComponent(() => import("Driver/containers/ListDriver")),
        scope: 'read:users:driver'
      },
      {
          path:"drivers/:id",
          component:asyncComponent(() => import("Driver/containers/RetrieveDriver")),
          scope:'read:users:driver'
      }
]

export default driverroutes;

import actions from './actions';

const initState = {
  user: null,
  error: null,
  users: [],
  allUsers: [],
  loading:false
 };

export default function userReducer(state = initState, action) {
  switch (action.type) {
    case actions.CREATE_USER_SUCCESS:
      return {
        ...state,
        user: action.user
      };
    case actions.FETCH_USER_LIST_REQUEST:
      return {
        ...state,
        loading:true
      };
    case actions.FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        users: action.response,
        loading:false
      };
    case actions.FETCH_USER_LIST_FAIL:
      return {
        ...state,
        error:action.error,
        loading:false
      };
      case actions.FETCH_ALL_USERS_LIST_REQUEST:
        return {
          ...state,
          loading:true
        };
      case actions.FETCH_ALL_USERS_LIST_SUCCESS:
        return {
          ...state,
          allUsers: action.response,
          loading:false
        };
      case actions.FETCH_ALL_USERS_LIST_FAIL:
        return {
          ...state,
          error:action.error,
          loading:false
        };

    default:
      return state;
  }
}

import { Map } from 'immutable';
// import { Avatar } from 'antd';
import moment from 'moment';

export function clearToken() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('scopes');

}

export function passengerValidationUtil(vehicles, passengers) {
  let totalAllocated = vehicles.reduce((total, current) => {
    return total += parseInt(current.passengerSeats);
  }, 0);

  const valid = totalAllocated >= parseInt(passengers);
  return { valid, totalAllocated };
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function getBookingPeriod(booking) {


  let bookingDepartureDate = moment.utc(booking.departureDate).format('YYYY-MM-DD 00:00:00');
  let bookingArrivalDate = moment.utc(booking.arrivalDate).format('YYYY-MM-DD 23:59:59');

  if (booking.returnArrivalDate) {
    bookingArrivalDate = moment.utc(booking.returnArrivalDate).format('YYYY-MM-DD 23:59:59');
  };

  return {
    bookingDepartureDate, bookingArrivalDate
  };
}


export const formatDate = (dateString) => {
  let date = moment.utc(dateString).local().format('YYYY-MM-DD');
  let time = moment.utc(dateString).local().format('HH:MM:SS');
  return (`${date} ${time}`);
}

export const isFreeInterval = (bookingDepartureDate, bookingArrivalDate, startInterval, endInterval) => {
  if(!(bookingDepartureDate <= endInterval && bookingArrivalDate >= startInterval)){
    return true;
  }
  return false;
}


export function getAvailableSelections(bookingObject, eventObjects) {

  // function to filter the available selections based on the booking datetime
  let sortedResourceArray = {
    events: eventObjects.events
  };
  let options = eventObjects.resources.map((resource) => {


    let eventsAtResource = eventObjects.events.filter(event => event.resourceId === resource.id);
    resource.name.valid = false;
    if (eventsAtResource.length === 0) {
      resource.name.valid = true;
      return resource
    }

    let bookingArrivalDate = Date.parse(formatDate(bookingObject.arrivalDate));
    let bookingDepartureDate = Date.parse(formatDate(bookingObject.departureDate));
    if(bookingObject.returnArrivalDate){
      bookingArrivalDate += Date.parse(formatDate(bookingObject.returnArrivalDate));
    }

    resource.name.valid = true;
    for (let i = 0; i < eventsAtResource.length; i++) {

      if(eventsAtResource[i].type !== 'temp'){
        let eventDepartureDate = Date.parse(eventsAtResource[i].start);
        let eventArrivalDate = Date.parse(eventsAtResource[i].end);

        if( !isFreeInterval( bookingDepartureDate, bookingArrivalDate, eventDepartureDate, eventArrivalDate)){
          resource.name.valid = false;
          break;
        }
      }



    }
    return resource;
  }).sort((a, b) => {


    if (a.valid && b.valid) {
      return 0;
    }
    else if (a.valid) {
      return -1;
    }
    else {
      return 1;
    }
  });

  sortedResourceArray.resources = options;
  return sortedResourceArray;
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => num > 9 ? '' + num : '0' + num;
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export const localizeDate = fromUtc => ((fromUtc && moment.utc(fromUtc).local()) || null);

export const localToUTC = localDateTime => ((localDateTime && moment(localDateTime).utc()) || null);

export const utcToTimezone = (fromUtc, location) => {
  let convertedDate;
  let [hours, minutes] = location.split("UTC")[1].slice(0, -1).split(":");
  if(+hours < 0){
    convertedDate = moment.utc(fromUtc).subtract({ hours, minutes });
  } else {
    convertedDate = moment.utc(fromUtc).add({ hours, minutes });
  }
  return convertedDate;
}

export const  convertMilliSecondsToDuration=(milliSeconds) =>{
  let result = "";
  const hours = Math.floor(milliSeconds / (1000 * 60 * 60));;
  const minutes =Math.floor((milliSeconds % (1000 * 60 * 60)) / (1000 * 60)); 
  const seconds = Math.floor((milliSeconds % (1000 * 60)) / 1000);
  
  if (hours > 0) {
    result += `${hours}hr${hours > 1 ? "s" : ""}`;
  }

  if (minutes > 0) {
    result += `${hours > 0 ? " " : ""}${minutes}min${minutes > 1 ? "s" : ""}`;
  }

  if (seconds >0) {
    result += `${seconds > 0 ? " " : ""}${seconds}s`;
    ;
  }

  return result || "0";
}
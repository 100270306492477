import asyncComponent from "Common/helpers/AsyncFunc";

const workdiaryroutes = [
    {
        path: 'workdiaryold',
        component: asyncComponent(() => import ('WorkDiary/components/WorkDiary')),
        scope: 'read:users'
    },
];

export default workdiaryroutes;
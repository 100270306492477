import styled from "styled-components";

const ItemSummaryWrapper = styled.div`
  h4 {
    margin-bottom: 0;
  }
  .activity-status {
    position: absolute;
    top: 30px;
    left: 35px;
  }
 
`;

export default ItemSummaryWrapper;

import { takeEvery, call, put, all, fork } from 'redux-saga/effects';
import actions from 'AssetType/redux/actions';
import { push } from 'react-router-redux';
import Api from 'Common/api/Api';

const api = new Api();

export function* createAssetType() {

    yield takeEvery(actions.CREATE_ASSETTYPE_REQUEST, function* ({ data, context, resolve, reject }) {
        try {
            const response = yield call(api.post, 'dropdownOptions', data);
            yield call(resolve, response);
            if (context === 'save') {
                yield (put(push('/assettype')));
            }
        }
        catch (error) {
            yield call(reject, error);
        }
    });
}

export function* fetchAssetTypeList() {
    yield takeEvery(actions.FETCH_ASSETTYPE_LIST_REQUEST, function* ({ resolve, reject }) {
        try {
            const response = yield call(api.get, "dropdownOptions", {});
            if (resolve) {
                yield call(resolve, response);
            } else {
                yield put({
                    type: actions.FETCH_ASSETTYPE_LIST_SUCCESS,
                    response: response.data.results
                });
            }
        } catch (error) {
            if (reject) {
                yield call(reject, error);
            } else {
                yield put({
                    type: actions.FETCH_AssetType_LIST_FAIL,
                    response: error
                });
            }
        }
    });
}

export function* retrieveAssetType() {
    yield takeEvery(actions.RETRIEVE_ASSETTYPE_REQUEST, function* ({ id, resolve, reject }) {
        try {
            const response = yield call(api.get, 'dropdownOptions/' + id, {});
            yield call(resolve, response);
        } catch (error) {
            yield call(reject, error);
        }
    });
}

export function* updateAssetType() {
    yield takeEvery(actions.UPDATE_ASSETTYPE_REQUEST, function* ({ data, id, resolve, reject }) {
        try {
            const response = yield call(api.update, 'dropdownOptions/' + id, data);
            yield call(resolve, response);
            yield put(push('/assettype'));
        } catch (error) {
            yield call(reject, error);
        }
    });
}

export function* deleteAssetType() {
    yield takeEvery(actions.DELETE_ASSETTYPE_REQUEST, function* ({ id, resolve, reject }) {
        try {
            const response = yield call(api.delete, 'dropdownOptions/' + id, {})
            if (resolve) {
                yield call(resolve, response);
                yield put(push('assettype'));
            }
        } catch (error) {
            yield call(reject, error);
        }
    });
}

export function* deleteInspectionItem() {
    yield takeEvery(actions.DELETE_INSPECTION_ITEM_REQUEST, function* ({ id, resolve, reject }) {
        try {
            const response = yield call(api.delete, 'inspectionitem/' + id, {})
            if (resolve) {
                yield call(resolve, response);
                yield put(push('edit'));   
            }
        } catch (error) {
            yield call(reject, error);
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(createAssetType),
        fork(fetchAssetTypeList),
        fork(retrieveAssetType),
        fork(updateAssetType),
        fork(deleteAssetType),
        fork(deleteInspectionItem),
    ]);
}



const actions = {

    UPDATE_SELECTED_BOOKING: "UPDATE_SELECTED_BOOKING",
    
    UPDATE_SELECTED_ALLOCATION: "UPDATE_SELECTED_ALLOCATION",
     UNSELECT_ALLOCATION: "UNSELECT_ALLOCATION",

    LIST_SCHEDULED_BOOKINGS_REQUEST: "LIST_SCHEDULED_BOOKINGS_REQUEST",
    LIST_SCHEDULED_BOOKINGS_SUCCESS: "LIST_SCHEDULED_BOOKINGS_SUCCESS",



    FETCH_ALLOCATION_TRACE_REQUEST: "FETCH_ALLOCATION_TRACE_REQUEST",
    FETCH_ALLOCATION_TRACE_SUCCESS: "FETCH_ALLOCATION_TRACE_SUCCESS",
    
    FETCH_ALLOCATION_GEO_FENCE_SUCCESS: "FETCH_ALLOCATION_GEO_FENCE_SUCCESS",
    FETCH_ALLOCATION_GEO_FENCE_REQUEST : "FETCH_ALLOCATION_GEO_FENCE_REQUEST",


    // fetch bulk trace requests 
    FETCH_TRACE_BULK_REQUEST: "FETCH_TRACE_BULK_REQUEST",
    FETCH_TRACE_BULK_SUCCESS: "FETCH_TRACE_BULK_SUCCESS",
    FETCH_TRACE_BULK_ERROR: "FETCH_TRACE_BULK_ERROR",



    updateGeoFenceInfo: (allocationId) => ({

        type: actions.FETCH_ALLOCATION_GEO_FENCE_REQUEST,
        mock: true,
        allocationId
    }),


    updatedSelectedBooking: (booking) => ({
        type: actions.UPDATE_SELECTED_BOOKING,
        selectedBooking: booking
    }),

    listScheduledBookings: (filters, resolve, reject) =>  ({
        type: actions.LIST_SCHEDULED_BOOKINGS_REQUEST,
        resolve,
        filters,
        reject
        }),

    fetchTraceByAllocation: (allocationId, resolve, reject) => ({
        type: actions.FETCH_ALLOCATION_TRACE_REQUEST,
        allocationId,
        resolve,
        reject
    }),


    fetchBulkTraces: (resolve, reject) => ({
        type: actions.FETCH_TRACE_BULK_REQUEST,
        mock: true,
        resolve,
        reject
    }),


    updateSelectedAllocation: (allocationId, bookingId, reject) => ({

        type: actions.UPDATE_SELECTED_ALLOCATION,
        mock: true,
        allocationId,
        bookingId,
        reject

    }),

    unselectAllocation: () => ({
        type: actions.UNSELECT_ALLOCATION
    }),

    // updateGeoFenceInfo(allocationId)









};


export default actions;
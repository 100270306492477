import React from "react";
import { Provider } from "react-redux";
import { store, history } from "Common/redux/store";
import Routes from "router";
import { ThemeProvider } from "styled-components";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import themes from "App/settings/themes";
import AppLocale from "Common/languageProvider";
import { themeConfig } from "App/settings";
import DashAppHolder from "dashAppStyle";
import Boot from "Common/redux/boot";

const currentAppLocale =
  AppLocale["en"];
  
const DashApp = () => (
  <LocaleProvider locale={currentAppLocale.antd}>
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <DashAppHolder>
          <Provider store={store}>
            <Routes history={history} />
          </Provider>
        </DashAppHolder>
      </ThemeProvider>
    </IntlProvider>
  </LocaleProvider>
);
Boot()
  .then(() => DashApp())
  .catch(error => console.error(error));

export default DashApp;
export { AppLocale };

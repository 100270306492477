const actions = {
  CREATE_INCIDENT_REQUEST:"CREATE_INCIDENT_REQUEST",
  CREATE_INCIDENT_SUCCESS:"CREATE_INCIDENT_SUCCESS",
  CREATE_INCIDENT_FAIL:"CREATE_INCIDENT_FAIL",

  UPDATE_INCIDENT_REQUEST:"UPDATE_INCIDENT_REQUEST",
  UPDATE_INCIDENT_SUCCESS:"UPDATE_INCIDENT_SUCCESS",
  UPDATE_INCIDENT_FAIL:"UPDATE_INCIDENT_FAIL",

  FETCH_INCIDENT_LIST_REQUEST:"FETCH_INCIDENT_LIST_REQUEST",
  FETCH_INCIDENT_LIST_SUCCESS:"FETCH_INCIDENT_LIST_SUCCESS",
  FETCH_INCIDENT_LIST_FAIL:"FETCH_INCIDENT_LIST_FAIL",

  FETCH_INCIDENT_DETAIL_REQUEST:"FETCH_INCIDENT_DETAIL_REQUEST",
  FETCH_INCIDENT_DETAIL_SUCCESS:"FETCH_INCIDENT_DETAIL_SUCCESS",
  FETCH_INCIDENT_DETAIL_FAIL:"FETCH_INCIDENT_DETAIL_FAIL",

  DELETE_INCIDENT_DETAIL_REQUEST:"DELETE_INCIDENT_DETAIL_REQUEST",
  DELETE_INCIDENT_DETAIL_SUCCESS:"DELETE_INCIDENT_DETAIL_SUCCESS",
  DELETE_INCIDENT_DETAIL_FAIL:"DELETE_INCIDENT_DETAIL_FAIL",

  // UPLOAD_IMAGE_REQUEST:"UPLOAD_IMAGE_REQUEST",
  // UPLOAD_IMAGE_SUCCESS:"UPLOAD_IMAGE_SUCCESS",
  // UPLOAD_IMAGE_FAIL:"UPLOAD_IMAGE_FAIL",

  // DELETE_IMAGE_REQUEST:"DELETE_IMAGE_REQUEST",
  // DELETE_IMAGE_SUCCESS:"DELETE_IMAGE_SUCCESS",
  // DELETE_IMAGE_FAIL:"DELETE_IMAGE_FAIL",

  createIncident(data, context, resolve, reject){
    return {
      type:actions.CREATE_INCIDENT_REQUEST,
      data,
      context,
      resolve,
      reject
    }
  },

  updateIncident(data, id, resolve, reject){
    return {
      type:actions.UPDATE_INCIDENT_REQUEST,
      data,
      id,
      resolve,
      reject
    }
  },

  fetchIncidentDetail(id, resolve, reject){
    return {
      type:actions.FETCH_INCIDENT_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }
  },

  deleteIncidentDetail(id, resolve, reject){
    return {
      type:actions.DELETE_INCIDENT_DETAIL_REQUEST,
      id,
      resolve,
      reject
    }
  },

  // uploadImage(formData, resolve, reject){
  //   return {
  //     type:actions.UPLOAD_IMAGE_REQUEST,
  //     formData,
  //     resolve,
  //     reject
  //   }
  // },
  //
  // deleteImage(id, resolve, reject){
  //   return {
  //     type:actions.DELETE_IMAGE_REQUEST,
  //     id,
  //     resolve,
  //     reject
  //   }
  // }

};
export default actions;

import React from 'react';
import { Icon, Tooltip } from 'antd';
import Auth0 from 'Common/helpers/auth0-custom/index';
import Protected from "Common/components/authorization/Protected";
import StopPropagationWrapper from 'Common/components/StopPropagationWrapper';

const auth0 = new Auth0();


const ActionItems = ({onEditClicked, onConfirm, id, row, context}) => (
  <span className="userlist-actionwrapper">
    <Tooltip title="Edit">
      <Icon type="edit" style={{ fontSize: 16 }} onClick={onEditClicked.bind(this, id)}/>
    </Tooltip>
   {onConfirm && 
    <Icon type="delete" style={{ fontSize: 16 }} onClick={onConfirm.bind(this, row)}/>}
  </span>
)

const authorizeColumn = (onEditClicked, onConfirm, context) => ({
   title: 'Action',
   key: 'action',
   render: (text, row, index) => {
     if(context === "users"){
       return (
        <Protected scope={"write:users:"+row.role}>
           <StopPropagationWrapper><ActionItems onEditClicked={onEditClicked} onConfirm={onConfirm} id={row.id} row={row} context={context} /></StopPropagationWrapper>
        </Protected>
       )
     } else {
         return <StopPropagationWrapper><ActionItems onEditClicked={onEditClicked} onConfirm={onConfirm} id={row.id} row={row} context={context} /></StopPropagationWrapper>
     }
   }
 })

export default (getDefaultColumns,onEditClicked, onConfirm, context) => {
    if(auth0.userHasScope("write:"+context)){
      const column = getDefaultColumns().concat(authorizeColumn(onEditClicked, onConfirm,context))
      return column;
    }
    return getDefaultColumns();
}

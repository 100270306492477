import styled from "styled-components";
import { palette } from "styled-theme";
import { transition, borderRadius } from "App/settings/style-util";
import WithDirection from "App/settings/withDirection";

const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 3;
    background: rgb(18, 130, 57);
    width: 280px;
    flex: 0 0 280px;
    height: 100vh; /* Full height */
    .scrollarea {
      height: calc(100vh - 70px);
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      background: rgb(18, 130, 57);
      background: linear-gradient(90deg, rgba(18, 130, 57, 1) 53%, rgba(15, 128, 53, 1) 73%, rgba(15, 127, 54, 1) 100%, rgba(12, 125, 52, 1) 100%);
      margin-bottom: 20px;
      padding: 0 10px;
      overflow: hidden;
      ${borderRadius()};
      a {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        font-family: "Manrope";
        span {
          color: #cccfd4;
          font-size: 12px;
          font-family: "Manrope";
        }
      }
      img {
        padding: 20px 5px 0;
      }
      h3 {
        a {
          color: #1f5959;
          font-size: 25px;
          font-weight: 400;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding-top: 0;
      padding-bottom: 35px;
      background: rgb(18, 130, 57);
      background: linear-gradient(90deg, rgba(18, 130, 57, 1) 53%, rgba(15, 128, 53, 1) 73%, rgba(15, 127, 54, 1) 100%, rgba(12, 125, 52, 1) 100%);
      a[href="/title"] {
        cursor: not-allowed !important;
        pointer-events: none;
        margin-top: 20px;
        span {
          color: #b4b7be;
          font-size: 12px;
          font-weight: 600;
        }
        i {
          display: none;
        }
      }

      a {
        color: #ffffff;
        text-decoration: none;
        font-weight: 400;
      }
      // a:hover{
      //   color:#174D4D !important;
      // }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        i {
          font-size: 19px;
          color: inherit;
          margin: ${(props) => (props["data-rtl"] === "rtl" ? "0 0 0 30px" : "0 12px 0 0")};
          width: 18px;
          ${transition()};
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 16px;
        color: inherit;
        font-weight: 400;
        ${transition()};
        font-family: "Manrope";
      }

      .ant-menu-item-selected {
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 65%,
          rgba(162, 205, 175, 1) 84%,
          rgba(115, 180, 135, 1) 100%,
          rgba(12, 126, 46, 1) 100%
        );
        .anticon {
          color: #fff;
        }
        i {
          color: #0c7e33;
        }

        .nav-text {
          color: #0c7e33;
        }
        &:hover .nav-text,
        &:hover i {
          color: #0c7e33;
        }
      }

    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette("secondary", 5)};
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 24px;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${(props) => (props["data-rtl"] === "rtl" ? "25px" : "auto")};
          right: ${(props) => (props["data-rtl"] === "rtl" ? "auto" : "25px")};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${
            "" /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */
          };
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          // &:hover {
          //   a {
          //     color: #ffffff !important;
          //   }
          // }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
  .new-badge {
    background: #ffff;
    color: rgb(18, 130, 57);
    font-size: 11px;
    font-weight: bold;
    padding: 4px 8px;
    margin-left: 40px;
    border-radius: 4px;
    }

  .inner-section:nth-child(4) {
    background-color: #000;
  }
  .ant-menu-dark .ant-menu-submenu span {
    color: #ffffff;
  }
  .isoDashboardMenu .ant-menu-submenu .ant-menu-sub li {
    background: rgb(18, 130, 57);
    background: linear-gradient(90deg, rgba(18, 130, 57, 1) 53%, rgba(15, 128, 53, 1) 73%, rgba(15, 127, 54, 1) 100%, rgba(12, 125, 52, 1) 100%);
  }
  .isoDashboardMenu .ant-menu-submenu .ant-menu-sub li:last-child {
    border-bottom: none;
  }
  .isoDashboardMenu .ant-menu-submenu .ant-menu-sub li span {
    color: #fff;
  }
  .isoDashboardMenu .ant-menu-submenu .ant-menu-sub li a {
    color: #ffffff;
  }
  .isoDashboardMenu .ant-menu-submenu li.ant-menu-item-selected a {
    color: #0c7e33;
  }
  // .ant-menu-submenu-inline .ant-menu-inline > li:not(.ant-menu-item-group):hover a{
  //       color:#0c7e33;
  //   }
  .isoDashboardMenu .ant-menu-submenu .ant-menu-sub li.ant-menu-item-selected {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 65%,
      rgba(162, 205, 175, 1) 84%,
      rgba(115, 180, 135, 1) 100%,
      rgba(12, 126, 46, 1) 100%
    );
    span {
      color: #308136;
    }
  }
  .isoDashboardMenu .ant-menu-item.ant-menu-item-selected:nth-child(5),
  .isoDashboardMenu .ant-menu-item.ant-menu-item-selected:nth-child(9),
  .isoDashboardMenu .ant-menu-item.ant-menu-item-selected:nth-child(15),
  .isoDashboardMenu .ant-menu-item.ant-menu-item-selected:nth-child(20),
  .isoDashboardMenu .ant-menu-item.ant-menu-item-selected:nth-child(27),
  .isoDashboardMenu .ant-menu-item.ant-menu-item-selected:nth-child(34){
    background: rgb(18, 130, 57);
    background: linear-gradient(90deg, rgba(18, 130, 57, 1) 53%, rgba(15, 128, 53, 1) 73%, rgba(15, 127, 54, 1) 100%, rgba(12, 125, 52, 1) 100%);
  }
  .ant-menu-submenu-open i.ant-menu-submenu-arrow {
    transform: rotate(180deg) !important;
  }
`;

export default WithDirection(SidebarWrapper);

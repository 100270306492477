import { takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from 'Allocation/redux/actions';
import Api from 'Common/api/Api';
const api = new Api();

export function* CreateAllocation() {

  yield takeEvery(actions.CREATE_ALLOCATION_REQUEST, function* ({ data, context, resolve, reject }) {
    try {
      console.log("Allocation Data", data.allocations);
      const response = yield call(api.post, `jobs/${data.bookingId}/allocations`, data.allocations);
      yield call(resolve, response);
    } catch (error) {
      yield call(reject, error);
    }
  });

}

export function* updateAllocation() {
  yield takeEvery(actions.UPDATE_ALLOCATION_REQUEST, function*({id,data,resolve,reject}) {
    try{
      const response = yield call(api.update,`jobs/${data.bookingId}/allocations/${id}`, data);
      yield call(resolve,response);
    }
    catch(error) {
      yield call(reject, error);
    }
  });
 }

export function* listVehiclesCalendarEvents() {

  yield takeEvery(actions.LIST_VEHICLES_CALENDAR_EVENTS_REQUEST, function* ({ period, resolve, reject }) {


    try {
      const response = yield call(api.get, 'vehicles/calendar-events', period);
      yield put({
        type: actions.LIST_VEHICLES_CALENDAR_EVENTS_SUCCESS,
        response: response.data
      });

    } catch (error) {

    }
  });
}

export function* listDriversCalendarEvents() {
  
  yield takeEvery(actions.LIST_DRIVERS_CALENDAR_EVENTS_REQUEST, function* ({ period, resolve, reject }) {

    yield put({
      type: actions.LIST_EVENTS_REQUEST
    });

    try {
      const response = yield call(api.get, 'drivers/calendar-events', period);

      yield put({
        type: actions.LIST_DRIVERS_CALENDAR_EVENTS_SUCCESS,
        response: response.data
      });

    } catch (error) {

    }
  });
}

export default function* rootSaga() {

  yield ([
    fork(CreateAllocation),
    fork(listVehiclesCalendarEvents),
    fork(listDriversCalendarEvents),
    fork(updateAllocation),
  ]);
}





import Auth0 from 'Common/helpers/auth0-custom/index';

const auth0 = new Auth0();

export default ({scope, children}) => {
    // Check if the user is authenticated
    if (auth0.isAuthenticated()) {
        // If the user is authenticated, check if they have the required scope
        if(auth0.userHasScope(scope)){
            return children;
        }
    }
    // If the user is not authenticated or doesn't have the required scope, return null
    return null;
}
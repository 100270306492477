import userroutes from "User/settings/userroutes";
import dashboardroutes from "Dashboard/settings/dashboardroutes";
import locationroutes from "Location/settings/locationroutes";
import driverroutes from "Driver/settings/driverroutes";
import vehicleroutes from "Vehicle/settings/vehicleroutes";
import bookingroutes from "Booking/settings/bookingroutes";
import allocationRoutes from "Allocation/settings/allocationRoutes";
import contactroutes from "Contact/settings/contactroutes";
import incidentroutes from "Incident/settings/incidentroutes";
import feedbackroutes from "Feedback/settings/feedbackroutes";
import routeroutes from "Route/settings/routeroutes";
import fatigueroutes from "Fatigue/settings/fatigueroutes";
import faultroutes from "Fault/settings/faultroutes";
import documentroutes from "Document/settings/documentroutes";
import ncrroutes from "NCR/settings/ncrroutes";
import fuelroutes from "Fuel/settings/fuelroutes";
import supplierroutes from "Supplier/settings/supplierroutes";
import maintenanceroutes from "Maintenance/settings/maintenanceroutes";
import reportroutes from "Report/settings/reportroutes";
import assetsroutes from "Assets/settings/assetsroutes";
import assettyperoutes from "AssetType/settings/assettyperoutes";
import workdiaryroutes from "WorkDiary/settings/workdiaryroutes";
import toolboxroutes from "Toolbox/settings/toolboxroutes";
import shiftRoutes from "Shifts/settings/shiftRoutes";
import newsRoutes from "NewsTraining/settings/newsRoutes";
import noticeRoutes from "NoticeBoard/settings/noticeRoutes";

import driverSignuproutes from "DriverSignup/settings/driverSignuproutes";
import notificationroutes from "Notification/settings/notificationroutes";
import ewdRoutes from "EWD/settings/ewdRoutes";

const routes = [
  ...dashboardroutes,
  ...userroutes,
  ...driverroutes,
  ...vehicleroutes,
  ...bookingroutes,
  ...allocationRoutes,
  ...contactroutes,
  // ...passengerroutes,
  ...incidentroutes,
  ...feedbackroutes,
  ...routeroutes,
  ...fatigueroutes,
  ...documentroutes,
  // ...runroutes,
  ...faultroutes,
  ...reportroutes,
  ...locationroutes,
  ...ncrroutes,
  ...fuelroutes,
  ...supplierroutes,
  ...maintenanceroutes,
  ...reportroutes,
  ...assetsroutes,
  ...assettyperoutes,
  ...workdiaryroutes,
  ...driverSignuproutes,
  ...toolboxroutes,
  ...shiftRoutes,
  ...shiftRoutes,
  ...notificationroutes,
  ...shiftRoutes,
  ...newsRoutes,
  ...noticeRoutes,
  ...ewdRoutes,
];

export default routes;

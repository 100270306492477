import actions from 'Location/redux/actions';

const initState = { 
  locations: [],
  error: null,
 };

export default function locationReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_LOCATION_LIST_SUCCESS:
      return { ...state, locations: action.response };

    default:
      return state;
  }
}
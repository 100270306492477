import styled from 'styled-components';

const SelectFieldWrapper = styled.div`
  .ant-select-lg{
    width: 100% !important;
    min-width: 20px;
  }
  .ant-select{
    width: 100%;
  }
`;

export default SelectFieldWrapper;

import React, { Component } from "react";
import { Field } from "redux-form";

import { Row, Col, Form } from "antd";

import IntlMessages from "Common/components/intlMessages";
import Section from "Common/components/section";
import TextAreaField from "Common/components/textAreaField";
import { DriverDropdownSelectWithFormSection as DriverDropdownSelect } from "Driver/components/form/DriverDropdown";
import { VehicleDropdownSelectWithFormSection as VehicleDropdownSelect } from "Vehicle/components/form/VehicleDropdown";
import { AssetDropdownSelectWithFormSection as AssetDropdownSelect } from "Vehicle/components/form/AssetsDropdown";

const FormItem = Form.Item;

class Operations extends Component {
  render() {
    return (
      <>
        <div
          style={{
            backgroundColor: "#F3F5FC",
            borderRadius: "10px",
            padding: "10px",
            overflow: "hidden",
            marginBottom: "30px",
          }}
        >
          <Section
            label={
              <h2>
                {" "}
                <IntlMessages id="sectiontitle.operations" />
              </h2>
            }
          >
            <Row gutter={48}>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.vehicle" />
                  </label>
                  <VehicleDropdownSelect name="vehicle" />
                </FormItem>
              </Col>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.driver" />
                  </label>
                  <DriverDropdownSelect name="driver" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={48}>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.driverInstruction" />
                  </label>
                  <Field name="driverInstruction" component={TextAreaField} />
                </FormItem>
              </Col>
            </Row>
          </Section>
        </div>

        <div
          style={{
            backgroundColor: "#F3F5FC",
            borderRadius: "10px",
            padding: "10px",
            overflow: "hidden",
            marginBottom: "30px",
          }}
        >
          <Section
            label={
              <h2>
                {" "}
                <IntlMessages id="forms.label.assets" />
              </h2>
            }
          >
            <Row gutter={48}>
              <Col lg={12} md={12}>
                <FormItem>
                  <label>
                    <IntlMessages id="forms.label.assets" />
                  </label>
                  <Field name="vehicleAssets" component={AssetDropdownSelect} />
                </FormItem>
              </Col>
            </Row>
          </Section>
        </div>
      </>
    );
  }
}
export default Operations;

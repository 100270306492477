import React from 'react';
import {Textarea} from 'Common/components/input';
import { Alert } from 'antd';

const TextAreaField = ({ placeholder,reset,input, meta: { touched, error }, ...custom }) => {
  const hasError = touched && error !== undefined;
  return (
      <div>
        <Textarea
          className={"inputField ant-input ant-input-md"}
          placeholder={placeholder}
          {...input}
          value={input.value || []}
          {...custom} 
        />
        { 
          hasError &&
          <Alert
          className="alert"
          type="error"
          message={error}
          banner />
        }
      </div>
  )
}

export default TextAreaField;
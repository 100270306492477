import asyncComponent from "Common/helpers/AsyncFunc";

const fuelroutes = [
    {
        path: 'fuels',
        component: asyncComponent(() => import ('Fuel/containers/ListFuel')),
        scope: 'read:users'
    }
]

export default fuelroutes;

const actions = {
    RETRIEVE_NCR_REQUEST:"RETRIEVE_NCR_REQUEST",
    DELETE_NCR_REQUEST:"DELETE_NCR_REQUEST",
    CREATE_NCR_REQUEST:'CREATE_NCR_REQUEST',
    CREATE_CAR_REQUEST:'CREATE_CAR_REQUEST',
    UPDATE_NCR_REQUEST:"UPDATE_NCR_REQUEST",
    UPDATE_CAR_REQUEST:"UPDATE_CAR_REQUEST",
    RETRIEVE_SINGLE_CAR_REQUEST: 'RETRIEVE_SINGLE_CAR_REQUEST',
    CREATE_PAR_REQUEST: 'CREATE_PAR_REQUEST',

    retrieveNcr: (id,resolve,reject) => {
        return{
            type: actions.RETRIEVE_NCR_REQUEST,
            id,
            resolve,
            reject
        }},
    deleteNcr: (id,resolve,reject) =>
    {
        return {type: actions.DELETE_NCR_REQUEST,
        id,
        resolve,
        reject}
    },
    createNcr:(data,context,resolve,reject) => ({
        type:actions.CREATE_NCR_REQUEST,
        data,
        context,
        resolve,
        reject
    }),
    updateNcr: (data, id, resolve,reject) => ({
        type: actions.UPDATE_NCR_REQUEST,
        data,
        id,
        resolve,
        reject
    }),
    createCar:(data,context,resolve,reject) => ({
        type:actions.CREATE_CAR_REQUEST,
        data,
        context,
        resolve,
        reject
    }),
    updateCar: (data, id, resolve,reject) => ({
        type: actions.UPDATE_CAR_REQUEST,
        data,
        id,
        resolve,
        reject
    }),
    retrieveSingleCar: (id, resolve, reject) => ({
        type: actions.RETRIEVE_SINGLE_CAR_REQUEST,
        id,
        resolve,
        reject
    }),
    createPar:(data,id,resolve,reject) => ({
        type:actions.CREATE_PAR_REQUEST,
        data,
        id,
        resolve,
        reject
    }),

};
export default actions;
const vehicleoptions = [
  {
    key: "fleet/status/all",
    label: "sidebar.vehicles",
    leftIcon: "ri-truck-line",
    scope: "read:vehicles",
    },
];

export default vehicleoptions;

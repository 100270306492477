import React from 'react';
import { Link } from 'react-router-dom';
import smallimage from '../image/sm-logo.png';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div style={{textAlign:"center"}}>
          <Link to="/dashboard"><img style={{padding:"20px 0"}}src={smallimage}/></Link>
          {/*<h3>*/}
          {/*  <Link to="/dashboard">*/}
          {/*    <i className={siteConfig.siteIcon} />*/}
          {/*  </Link>*/}
          {/*</h3>*/}
        </div>
      ) : (
        <div>
          <Link to="/dashboard"><img src={smallimage} style={{float:"left"}}/>
            <div style={{float:"left",marginTop:"20px",lineHeight:"17px"}}> Fleet Compliance<br/><span>Operator Companion</span> </div></Link>
        </div>
      )}
    </div>
  );
};

import { call, put, takeEvery, fork, all, takeLatest, select } from "redux-saga/effects";
import actions from "Tracking/redux/actions";
import Api from 'Common/api/Api';

import { getTracingArrayFromBookingAllocations, mapTracesToTrackingId } from "../utility";
const api = new Api();


export function* fetchAllocationGeoFences() {


    yield takeLatest(actions.FETCH_ALLOCATION_GEO_FENCE_REQUEST, function* ({ mock, allocationId, filters }) {

        try {



            const selectedAllocation = yield select(state => state.Tracking.selectedAllocation);

            let response = yield call(api.get, `bookings/${selectedAllocation.booking}/allocations/${selectedAllocation.allocation}/geofence-events`, { params: filters });


            yield put({
                type: actions.FETCH_ALLOCATION_GEO_FENCE_SUCCESS,
                response: response.data
            });
        } catch (error) {

        }

    });
}


export function* fetchScheduledBookings() {


    yield takeLatest(actions.LIST_SCHEDULED_BOOKINGS_REQUEST, function* ({ mock, filters, resolve, reject }) {


        try {

            let response = yield call(api.get, `bookings?pageSize=-1`, filters);
            const trackingIdArray = getTracingArrayFromBookingAllocations(response.data.results);

            if (trackingIdArray.length > 0) {

                yield put({
                    type: actions.FETCH_TRACE_BULK_REQUEST,
                    trackingIdArray,
                    mock: true
                });
            }


            yield put({
                type: actions.LIST_SCHEDULED_BOOKINGS_SUCCESS,
                response: response.data.results
            });


            if (resolve) {
                yield call(resolve, null);
            }

        } catch (error) {

            if (reject) {
                yield call(reject, error);
            }
        }
    })


}

/**
 * Gets an array of tracking ids as params
 */

export function* fetchBulkTraces() {

    yield takeEvery(actions.FETCH_TRACE_BULK_REQUEST, function* ({ mock, trackingIdArray }) {

        try {

            let tracesRequestArray = trackingIdArray.map(trace => ({
                method: "get",
                url: `traces`,
                config: {
                    params: {
                        trackingId: trace,
                        last: 1
                    }
                }
            }));

            let response = yield call(api.parallel, tracesRequestArray);

            let tracesByTracingId = mapTracesToTrackingId(response, trackingIdArray);

            yield put({
                type: actions.FETCH_TRACE_BULK_SUCCESS,
                response: tracesByTracingId
            });
        }
        catch (error) {
            yield put({
                type: actions.FETCH_TRACE_BULK_ERROR
            });
        }
    })

};

export function* fetchTracesByAllocation() {

    yield takeEvery(actions.FETCH_ALLOCATION_TRACE_REQUEST, function* ({ allocationId, resolve, reject }) {



        try {
            const response = yield call(api.get, `allocation/${allocationId}/traces`, {});

            if (resolve) {
                yield call(resolve, null);
            }
            yield put({
                type: actions.FETCH_ALLOCATION_TRACE_SUCCESS,
                response: response.data
            });

        } catch (error) {

            if (reject) {
                yield call(reject, error);
            }
        }
    });

}






export default function* rootSaga() {
    yield all([

        fork(fetchScheduledBookings),
        fork(fetchAllocationGeoFences),
        fork(fetchTracesByAllocation),
        fork(fetchBulkTraces)


    ]);
}


import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Alert } from "antd";
import Select, { SelectOption } from "Common/components/select";
import { Field, FormSection } from "redux-form";

import SelectFieldWrapper from "Common/styles/selectfield.style";

import actions from "Dropdown/redux/actions";
const { listDropdown } = actions;

class DropdownSelect extends Component {
  componentWillMount = () => {
    if (!this.props.data.length && !this.props.loading) {
      this.props.listDropdown();
    }
  };

  getDropdownList = (type) => {
    return this.props.data.filter((dropdown) => dropdown.dropdown === type);
  };

  ucFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const {
      defaultValue,
      input,
      meta: { touched, error },
      type,
      loading,
      showDescription,
      ...custom
    } = this.props;
    const hasError = touched && error !== undefined;
    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={loading}>
          <Select
            {...input}
            {...custom}
            className="inputField ant-select-lg"
            value={input.value || defaultValue || ""}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <SelectOption value="">Select</SelectOption>
            {this.getDropdownList(type).map((dropdown, index) => (
              <SelectOption key={index} value={dropdown.id}>
                {this.ucFirst(
                  !showDescription ? dropdown.title : dropdown.description
                )}
              </SelectOption>
            ))}
          </Select>
          {hasError && <Alert type="error" message={error} banner />}
        </Spin>
      </SelectFieldWrapper>
    );
  }
}

const DropdownSelectConnect = connect(
  (state) => ({
    loading: state.Dropdown.loading,
    data: state.Dropdown.data,
  }),
  { listDropdown }
)(DropdownSelect);

export default DropdownSelectConnect;

export const DropdownSelectWithFormSection = ({ name, type, ...custom }) => (
  <FormSection name={name}>
    <Field
      name="id"
      type={type}
      component={DropdownSelectConnect}
      {...custom}
    />
  </FormSection>
);

import React from 'react';
import Input from 'Common/components/input';
import { Alert } from 'antd';
import InputFieldWrapper from 'Common/styles/inputfield.style';

const InputField = ({isDisabled, type,defaultValue,placeholder,reset,input, meta: { touched, error }, ...custom }) => {
  const hasError = touched && error !== undefined;
  return (
    <InputFieldWrapper className={hasError ? "has-error" : ""}>
      <Input
        type={type} 
        disabled={isDisabled}
        className={"inputField ant-input ant-input-md"}
        placeholder={placeholder}
        {...input}
        value={input.value || defaultValue || ''}
        {...custom} />

      { 
        hasError &&
        <Alert
        className="alert"
        type="error"
        message={error}
        banner />
      }
    </InputFieldWrapper>
  );
}

export default InputField;
import actions from 'Tracking/redux/actions';

const initialState = {
    bookings: [],
    geoFenceInfo: [],
    loadingBookings: false, // unwanted
    isBookingSelected: false, // unwamted
    selectedAllocation: null,
    loadingTraces: true, // unwanted
    traces: {}

}

export default function (state = initialState, action) {



    switch (action.type) {

        case actions.LIST_SCHEDULED_BOOKINGS_REQUEST:
            return { ...state, loadingBookings: true }

        case actions.LIST_SCHEDULED_BOOKINGS_SUCCESS:
            let selectedAllocation = state.selectedAllocation;
            let bookings = action.response;
            if (selectedAllocation) {
                let filteredBookingArray = bookings.filter(booking => booking.id === selectedAllocation.booking);
                if (filteredBookingArray.length !== 1)
                    selectedAllocation = null
            }
            return {
                ...state, bookings, loadingBookings: false, selectedAllocation
            }

        case actions.UPDATE_SELECTED_ALLOCATION:
            return { ...state, selectedAllocation: { booking: action.bookingId, allocation: action.allocationId } }

        case actions.UNSELECT_ALLOCATION:
            return { ...state, selectedAllocation: null }



        case actions.FETCH_ALLOCATION_TRACE_REQUEST:
            return {
                ...state,
                traces: {
                    ...state.traces, [action.allocationId]: { loading: true }
                }
            }

        case actions.FETCH_ALLOCATION_TRACE_SUCCESS:
            return {
                ...state,
                traces: {
                    ...state.traces, [action.allocationId]: action.traces
                }
            }


        case actions.FETCH_ALLOCATION_GEO_FENCE_SUCCESS:
            return {
                ...state,
                geoFenceInfo: action.response
            }

        case actions.FETCH_TRACE_BULK_REQUEST:
            return {
                ...state, loadingTraces: true
            }

        case actions.FETCH_TRACE_BULK_SUCCESS:

            return { ...state, loadingTraces: false, traces: action.response };
        case actions.FETCH_TRACE_BULK_ERROR:
            return { ...state, loadingTraces: false, traces: {} }

        default:
            return state;
    }
}
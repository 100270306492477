import React, { Component } from 'react';
import { connect } from 'react-redux';
import userActions from 'User/redux/actions';
import {Spin, Alert} from 'antd';
import Select,{SelectOption} from 'Common/components/select';
import { Field, FormSection} from 'redux-form';

import SelectFieldWrapper from 'Common/styles/selectfield.style';

const { fetchUserList } = userActions;

class UserDropdown extends Component {

  componentWillMount(){
    if(!this.props.users.length && !this.props.loading ){
      this.props.fetchUserList();
    }
  }

  getActiveUsers = () => {
    const { users } = this.props;
    const activeUsers = users.filter((user)=>(
      user.status==="active"
    ));
    return activeUsers
  }

  render(){
    const {defaultValue, input, meta: { touched, error }, type, loading, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={this.props.loading}>
          <Select
            {...input}
            {...custom}
            className="inputField ant-select-lg"
            value={input.value || defaultValue || ""}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <SelectOption value="">Select</SelectOption>
            {this.getActiveUsers().map((dropdown,index)=>(
              <SelectOption
                key={index}
                value={dropdown.id}>
                 {dropdown.firstName + " " + dropdown.lastName}
                </SelectOption>
            ))}
          </Select>
          { hasError &&
            <Alert
            type="error"
            message={error}
            banner />
          }
        </Spin>
      </SelectFieldWrapper>
    )
  }
}
const UserDropdownConnect =  connect(
  state => ({
    users: state.User.users,
    loading:state.User.loading
  }),
  { fetchUserList }
)(UserDropdown);

export default UserDropdownConnect;

export const  UserDropdownSelectWithFormSection = ({name, type, ...custom}) => (
  <FormSection name={name}>
    <Field name="id" type={type} component={UserDropdownConnect} {...custom}/>
  </FormSection>
);

import React, {Component} from 'react';
import { Modal } from 'antd';

class ModalWrapper extends Component {
  render(){
    const { title } = this.props
    return (
      <Modal
        title={title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer= {null}
        width='90vw'
        style={{ top: 20 }}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default ModalWrapper;

// import React, { Component } from 'react';
import { Component } from 'react';
import { LocationMarkupDepot,LocationMarkupStop }  from 'Common/components/HereMap/settings/MakerSvg';

class MarkerLocation extends Component {
  componentWillUpdate() {
    this.resetMarkerCircleLayer();
  }
  
  componentWillUnmount() {
    this.resetMarkerCircleLayer();
  }
  
  resetMarkerCircleLayer = () => {
    const {map,geofenceRadius,lat,lng } = this.props;
    lat && lng ? map.removeObject(this.startMarker) : null;
    lat && lng && geofenceRadius ?  map.removeObject(this.circleLayer) : null;
  }

  render(){
    const {map,H,lat,lng,zoom,geofenceRadius,type,ui,locationName} = this.props;
    if(lat && lng) {  
      const geofenceInfo = `<div style="padding: 3px 6px; text-align: left; font-size: 11px"> ${locationName} </div>`;
      const startIcon = new H.map.Icon(
        (type == 'stop') ? LocationMarkupStop : LocationMarkupDepot)
        this.startMarker = new H.map.Marker({lat: lat, lng: lng },
          {icon: startIcon,data: geofenceInfo});
     
      locationName && this.startMarker.addEventListener('tap', function (evt) {
        const bubble =  new H.ui.InfoBubble(evt.target.getPosition(), {
          content: evt.target.getData()
        });
        ui.addBubble(bubble);
      }, false);
      map.setCenter({lat: lat, lng: lng});
      map.setZoom(zoom);
      if(geofenceRadius){
        this.circleLayer = new H.map.Circle(
          {  lat: lat, lng: lng  },
          geofenceRadius ? geofenceRadius : 200,
          {
            style: {
              strokeColor: 'rgba(246,71,68, 1)', 
              lineWidth: 0.5,
              fillColor: 'rgba(246,71,68, 0.4)' 
            }
          }
        )
        map.addObject(this.circleLayer)
      }
      map.addObject(this.startMarker);
    }
    return null;
  }
}

export default MarkerLocation;
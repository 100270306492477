import actions from 'Booking/redux/actions';
import moment from "moment";

const initState = {
  error: null,
  bookings: [],
  runData: [],
  loading: false,
  selectedRange: {
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  storedPowerSearchValue:'',
  pagination :{}
}



export default function bookingReducer(state = initState, action) {
  switch (action.type) {
    case action.LIST_BOOKING_REQUEST:
      return {
        ...state,
        loading:true
      };
    
    case actions.LIST_BOOKING_SUCCESS:
      return { ...state,
        bookings: action.response.results,
        pagination:{total:action.response.total},
        loading: false,
      };
    
    case actions.LIST_BOOKING_FAILED:
      return { ...state,
        error: action.error, 
        loading:false
      };
      case actions.LIST_DROPDOWN_RUN_SUCCESS: 
      return { ...state, runData: action.response, loading: false};
  
      case actions.LIST_DROPDOWN_RUN_REQUEST:
        return {...state, loading: true};
  
      case actions.LIST_DROPDOWN_RUN_FAILURE:
      return { ...state, loading: false };
      
      case actions.STORE_POWERSEARCH_VALUE:
        return {...state, storedPowerSearchValue:action.value}
    
    case actions.STORE_DATERANGE_BOOKING:
      return { ...state, selectedRange: {start: action.startdate, end:action.enddate} }
    
    default:
      return state;
  }
}

import React from 'react';
import Select from 'Common/components/select';
import { Alert } from 'antd';
import SelectFieldWrapper from 'Common/styles/selectfield.style';

const SelectField = ({defaultValue, input, meta: { touched, error },...custom }) => {
  const hasError = touched && error !== undefined;
  return (
    <SelectFieldWrapper className={hasError ? "has-error" : ""}>
      <Select 
        defaultValue={defaultValue}
        {...input} 
        {...custom}
        className="inputField ant-select-lg"
        value={input.value || defaultValue} />
      { hasError &&
        <Alert
         type="error"
         message={error}
         banner />
      }
    </SelectFieldWrapper>
  );
}

export default SelectField;
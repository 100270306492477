import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from 'Vehicle/redux/actions';
import { Spin, Alert } from 'antd';
import Select, { SelectOption } from 'Common/components/select';
import { Field, FormSection } from 'redux-form';
import SelectFieldWrapper from 'Common/styles/selectfield.style';

const { fetchAssetList } = actions;

class VehicleDropdown extends Component {
  componentDidMount() {
    this.props.fetchAssetList();
  }

  getDropdownList = () => {
    const { assets } = this.props;
    return assets || [];
  };

  handleSelectChange = (selectedIds) => {
    const { assets, input } = this.props;
    const selectedAssets = assets.filter(asset => selectedIds.includes(asset.id));
    input.onChange(selectedAssets);
  };

  render() {
    const { defaultValue, input, meta: { touched, error }, loading, ...custom } = this.props;
    const hasError = touched && error !== undefined;
    return (
      <SelectFieldWrapper className={hasError ? "has-error" : ""}>
        <Spin spinning={loading}>
          <Select
            {...custom}
            className="inputField ant-select-lg"
            mode="multiple"
            placeholder="Select more assets" 
            value={(input.value || defaultValue || []).map(asset => asset.id)} 
            showSearch
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={this.handleSelectChange} 
          >
            {this.getDropdownList().map((dropdown, index) => (
              <SelectOption key={index} value={dropdown.id}>
                {dropdown.registrationNumber}
              </SelectOption>
            ))}
          </Select>
          {hasError && <Alert type="error" message={error} banner />}
        </Spin>
      </SelectFieldWrapper>
    );
  }
}

const assetDropdownConnect = connect(
  (state) => ({
    assets: state.Vehicle.assets,
    loading: state.Vehicle.loading,
  }),
  { fetchAssetList }
)(VehicleDropdown);

export default assetDropdownConnect;

export const AssetDropdownSelectWithFormSection = ({ name, type, ...custom }) => (
  <FormSection name={name}>
    <Field name="id" type={type} component={assetDropdownConnect} {...custom} />
  </FormSection>
);

import asyncComponent from "Common/helpers/AsyncFunc";

const shiftRoutes = [
  {
    path: "shifts",
    component: asyncComponent(() => import("Shifts/containers/shifts")),
    scope: "read:users",
  },
  {
    path: "shift/:shiftId/:driverId",
    component: asyncComponent(() => import("Shifts/containers/shiftDetails")),
    scope: "read:users",
  },
];

export default shiftRoutes;

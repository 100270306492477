import actions from 'Run/redux/actions';

const initState = {
  error: null,
  runs: [],
  loading:false,
  runsLocations: []
 };

export default function runReducer(state = initState, action) {
  switch (action.type) {
      case actions.LIST_RUNS_SUCCESS: 
      return { ...state, runs: action.response, loading: false};
  
      case actions.LIST_RUNS_REQUEST:
        return {...state, loading: true};
  
      case actions.LIST_RUNS_FAILURE:
        return {...state, loading: false}; 

      case actions.LIST_RUNS_LOCATION_SUCCESS: 
      return { ...state, runsLocations: action.response, loading: false};
  
      case actions.LIST_RUNS_LOCATION_REQUEST:
        return {...state, loading: true};
  
      case actions.LIST_RUNS_LOCATION_FAILURE:
        return {...state, loading: false}; 
    default:
      return state;
  }
}

import { takeEvery, call, put, fork , all} from "redux-saga/effects";
import actions from 'Incident/redux/actions';
import Api from 'Common/api/Api';
import { push } from 'react-router-redux';

const api = new Api();

export function* createIncident(){
  yield takeEvery(actions.CREATE_INCIDENT_REQUEST, function*({data, context, resolve, reject}){
    try{
      const response = yield call( api.post, 'incidents', data);
      yield call(resolve,response);
      if(context === "save"){
        yield put(push("/incidents/"+response.data.id))
      }
    } catch(error){
      yield call(reject,error);
    }
  });
}

export function* updateIncident(){
  yield takeEvery(actions.UPDATE_INCIDENT_REQUEST, function*({data, id, resolve, reject}){
    try{
      const response = yield call(api.update, 'incidents/'+id, data);
      yield call(resolve,response);
      yield put(push("/incidents/"+id));
    }catch(error){
      yield call(reject,error);
    }
  });
}

export function* fetchIncidentDetail(){
  yield takeEvery(actions.FETCH_INCIDENT_DETAIL_REQUEST, function*({id, resolve, reject }){
    try{
      const response = yield call(api.get, "incidents/" + id, {});
      if(resolve){
        yield call(resolve, response)
      }else {
        yield put({
          type:actions.FETCH_INCIDENT_DETAIL_SUCCESS,
          response:response.data
        })
      }
    } catch(error){
       if(reject){
         yield call(reject, error)
       }else {
         yield put({
           type:actions.FETCH_INCIDENT_DETAIL_FAIL,
           error
         });
       }
    }
  });
}

export function* deleteIncidentDetail(){
  yield takeEvery(actions.DELETE_INCIDENT_DETAIL_REQUEST, function*({id, resolve, reject}){
    try{
     const response = yield call(api.delete, "incidents/" + id, {});
     if(resolve){
       yield call(resolve, response);
       yield put(push("/incidents/type/all"));
     }else{
       yield put({
         type:actions.DELETE_INCIDENT_DETAIL_SUCCESS,
         response
       })
     }
    } catch(error){
      if(reject){
         yield call(reject, error);
      }else{
        yield put({
          type:actions.DELETE_INCIDENT_DETAIL_FAIL,
          error
        })
      }
    }
  })
}

// export function* uploadImage(){
//   yield takeEvery(actions.UPLOAD_IMAGE_REQUEST, function*({formData, resolve, reject}){
//     try{
//       const response = yield call( api.postForm, 'medias', formData);
//       yield call(resolve,response);
//     } catch(error){
//       yield call(reject,error);
//     }
//   });
// }

// export function* deleteImage(){
//   yield takeEvery(actions.DELETE_IMAGE_REQUEST, function*({id, resolve, reject}){
//     try{
//      const response = yield call(api.deleteForm, "medias/"+id, {});
//      if(resolve){
//        yield call(resolve, response);
//      }else{
//        yield put({
//          type:actions.DELETE_IMAGE_SUCCESS,
//          response
//        })
//      }
//     }catch(error){
//       if(reject){
//          yield call(reject, error);
//       }else{
//         yield put({
//           type:actions.DELETE_IMAGE_FAIL,
//           error
//         })
//       }
//     }
//   });
// }

export default function* rootSaga(){
  yield all([
    fork(createIncident),
    fork(updateIncident),
    fork(fetchIncidentDetail),
    fork(deleteIncidentDetail),
    // fork(uploadImage),
    // fork(deleteImage),
  ])
}

import styled from "styled-components";

const HereMapWrapper = styled.div`
  width: 100%;
  height: 500px;
  background: #ffffff;
  border-radius: 10px;

  .H_ib_body {
    background: #ffffff !important;
    border-radius: 10px;
    width: 300px;
  }

  .H_ib_close {
    background: #ffffff !important;
    color: #000000;
  }
  .H_ib_close:after {

    content: "x";
  }

`;

export { HereMapWrapper };
import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import Auth from "Public/redux/reducer";
import AssetType from "AssetType/redux/reducer";
import App from "App/redux/reducer";
import User from "User/redux/reducer";
import Location from "Location/redux/reducer";
import DriverSignup from "DriverSignup/redux/reducer";
import Vehicle from "Vehicle/redux/reducer";
import Booking from "Booking/redux/reducer";
import Tracking from "Tracking/redux/reducer";
import Allocation from "Allocation/redux/reducer";
import Calendar from "Common/components/Calendar/redux/reducer";
import Dropdown from "Dropdown/redux/reducer";
import Contact from "Contact/redux/reducer";
import Route from "Route/redux/reducer";
import Run from "Run/redux/reducer";
import Driver from "Driver/redux/reducer";
import Document from "Document/redux/reducer";
import Shifts from "Shifts/redux/reducer";
import EWD from "EWD/redux/reducer";
import Fatigue from "Fatigue/redux/reducer";

const appReducer = combineReducers({
  DriverSignup,
  AssetType,
  Auth,
  App,
  User,
  Location,
  Driver,
  Vehicle,
  Booking,
  Tracking,
  form: reduxFormReducer,
  Allocation,
  Calendar,
  Dropdown,
  Contact,
  Route,
  Run,
  Document,
  Shifts,
  EWD,
  Fatigue,
  router: routerReducer,
});

export default (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

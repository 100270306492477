import styled from "styled-components";

const PageHeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  
  .titles{
    position:absolute;
    z-index:100;
    @media only screen and (max-width: 480px) { 
          position:unset;
          margin-bottom:10px;
    }
    h1 {
      font-size:32px;
      margin-bottom:0;
      color:#1C273C;
      font-weight:600;
      @media only screen and (max-width: 600px) { 
                font-size:24px;
      }
    }
    .breadcrumbs{
      font-size:12px;
    }
  }
  .buttons{
    padding: 9px 0!important;
    text-align: right;
    button:not(:first-child){
      margin-left: 8px;
    }
  }
  .ant-breadcrumb a{
    color:#9A9FA8;
  }
  .ant-breadcrumb > span:last-child{
    color:#128239;
  }
  .ant-breadcrumb a:hover{
    color: #9A9FA8;
  }
`;
export default PageHeaderWrapper;
const bookingoptions = [
  {
    key: "jobs/status/all",
    label: "sidebar.booking",
    leftIcon: "ri-briefcase-3-line",
    scope:"read:bookings",
    // children:[{
    //         key: "jobs/status/all",
    //         label: "jobs.all",
    //     },
    //     {
    //         key: "jobs/status/active",
    // 	    label: "jobs.active",
    // 	},
    // 	{
    //         key: "jobs/status/scheduled",
    // 	    label: "jobs.scheduled",
    // 	},
    // 	{
    //         key: "jobs/status/commenced",
    // 	    label: "jobs.commenced",
    // 	},
    // 	{
    //         key: "jobs/status/completed",
    // 	    label: "jobs.completed",
    // 	},
    // 	{
    //         key: "jobs/status/cancelled",
    // 	    label: "jobs.cancelled",
    // 	}]
  }
];
export default bookingoptions;

import React from 'react';
import {Field} from 'redux-form';

import Protected from "Common/components/authorization/Protected";
import IntlMessages from 'Common/components/intlMessages';
import InputField from 'Common/components/InputField';
import SelectField from 'Common/components/SelectField';
import {SelectOption} from 'Common/components/select';
import {ContactSelectWFS as ContactSelect } from 'Contact/components/form/ContactSelect';

const UserAdvancedFilterForm = () => (
  <div>
    <div className="form-group">
      <label><IntlMessages id="forms.label.firstName" /> </label>
      <Field  name="firstName" component={InputField} />
    </div>
    <div className="form-group">
      <label><IntlMessages id="forms.label.lastName" /> </label>
      <Field  name="lastName" component={InputField} />
    </div>
    <div className="form-group">
      <label><IntlMessages id="forms.label.status" /> </label>
      <Field defaultValue="" name="status" component={SelectField}>
        <SelectOption value="">Select Status</SelectOption>
        <SelectOption value="active">Active</SelectOption>
        <SelectOption value="inactive">Inactive</SelectOption>
      </Field>
    </div>
    <Protected scope="read:contacts">
      <div className="form-group">
        <label><IntlMessages id="forms.label.contact" /> </label>
        <ContactSelect name="contact" labelInValue={true} defaultValue={{key:""}}/>
      </div>
    </Protected>
  </div>
)
export default UserAdvancedFilterForm;

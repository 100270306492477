import asyncComponent from "Common/helpers/AsyncFunc";

const ewdRoutes = [
  {
    path: "workdiary",
    component: asyncComponent(() => import("EWD/containers/ewd")),
    scope: "read:users",
  },
];

export default ewdRoutes;
